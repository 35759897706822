import React, { useEffect, useState } from "react";
import { getFromAPI } from "../utils/utils";
import Calendar from "./Calendar";
import Event from "./Event.module.css";
import { useAuth, useUserContext } from "../../Context/context";
import { postToAPI } from "../utils/utils";
import axios from "axios";
import { Toast } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


import { Link } from "../utils/link";
function Events() {
  const [eventsData, seteventsData] = useState({});
  const [eventmodal, setEventModal] = useState(false);
  const {isAdmin}=useAuth()
  const [formData, setFormData] = useState({
    eventName: "",
    startDate: "",
    endDate: "",
    color: "red", // Default color
  });
  const openEventModal = () => {
    setEventModal(true);
  };
  const resetFormData = () => {
    setFormData({
      eventName: "",
      startDate: "",
      endDate: "",
      color: "red", // Reset to default color
    });
  };

  

  const handleSubmit = async (e) => {
    e.preventDefault();
     // Prevent the default form submission behavior
     const trimmedevent = formData.eventName.trim();
     const trimsdate = formData.startDate.trim()
     const trimedate = formData.endDate.trim()
     if (trimmedevent === "") {
      toast.error('Event field cannot be empty.');
      return;
    }
    if(trimsdate == "") {
      toast.error('Start date cannot be empty')
    }
    if(trimedate == "") {
      toast.error('end date cannot be empty')
    }
    try {
      const form = new FormData();
      form.append("event-title", trimmedevent );
      form.append("start-date", trimsdate);
      form.append("end-date", trimedate);
      form.append("event-colo", formData.color);

      const res = await axios.post(Link + "/admin/events/create_json", form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      //toast.success("Event Added Successfully!");
      setEventModal(false);

      resetFormData();
      toast.success("Event added Successfully!")
      //console.log(res.data);
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong, Please Try Again");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const closeEventModal = () => {
    setEventModal(false);
    resetFormData();
  };

  //const { user } = useUserContext();
  const userRole = isAdmin ? "true" : "false";

  return (
    <>
      <div className={Event.Calendar}>
        <div className={Event.flex}>
          <h2>Events</h2>
          {userRole === "true" && (
            <button className={Event.button} onClick={openEventModal}>
              + Add Event
            </button>
          )}
        </div>
        <div className={Event.Card}></div>
        <div className={Event.CalCard}>
          <Calendar />
        </div>
      </div>

      <div className={eventmodal ? "modal open" : "modal"}>
        <div className={Event.modalContent}>
          <div className={Event.card}>
            <h2>Add Event</h2>
            <form onSubmit={handleSubmit}>
              <h6>Add Event</h6>
              <input
                type="text"
                id="eventName"
                name="eventName" // Change from "text" to "eventName"
                // value={formData.eventName}
                onChange={handleChange}
                placeholder=" Enter Event"
                
              />
              <h6>Start Date</h6>
              <input
                type="date"
                id="startDate"
                name="startDate"
                // value={formData.startDate}
                onChange={handleChange}
              />
              <h6>End Date</h6>
              <input
                type="date"
                id="endDate"
                name="endDate"
                // value={formData.endDate}
                onChange={handleChange}
                
              />
              <h6>Select Color</h6>
              <select
                id="color"
                name="color"
                // value={formData.color}
                onChange={handleChange}
              >
                <option value="red">Red</option>
                <option value="blue">Blue</option>
                <option value="green">Green</option>
                <option value="yellow">Yellow</option>
                <option value="purple">Purple</option>
              </select>
              <div className="button-container">
                <button
                  type="submit" // Change to type="submit"
                  style={{ backgroundColor: "var(--primary)", color: "white" }}
                >
                  Submit
                </button>
                <button
                  onClick={closeEventModal}
                  style={{ backgroundColor: "var(--cancel)", color: "white" }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
          <ToastContainer />
        </div>
      </div>
    </>
  );
}

export default Events;
