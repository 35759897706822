 import axios from 'axios';
 import React, { createContext, useContext, useEffect, useState } from 'react';
 import { Link } from '../components/utils/link';

// const UserContext = createContext();

// export const useUserContext = () => useContext(UserContext);

// export const UserProvider = ({ children }) => {
//   const [user, setUser] = useState("");

 
//   // Function to check if user is an admin
//   const isAdmin = () => {
//     return user && user.role === 'admin';
//   };

//   return (
//     <UserContext.Provider value={{ user, setUser, isAdmin }}>
//       {children}
//     </UserContext.Provider>
//   );
// };
//import React, { createContext, useContext, useState } from 'react';

// Create a context to manage the admin and user states
const AuthContext = createContext();

// Custom hook to consume the AuthContext
export const useAuth = () => useContext(AuthContext);

// AuthProvider component to wrap your application and provide the context
export const AuthProvider = ({ children }) => {
  // State variables for admin and user
  const [isAdmin, setIsAdmin] = useState(false);
  const [isUser, setIsUser] = useState(false);
  useEffect(()=>{
    fetchUserData()
    },[])
    const fetchUserData=async()=>{
     const res = await axios.get(Link+"/auth/refetch/json",{withCredentials:true})
     console.log(res.data)
     if(res.data.isAdmin){
       setAdmin(true)
       setUser(false)
     }
     if(res.data.isUser){
       setAdmin(false)
       setUser(true)
     }
     // setUser(res.data)
    }
  // Function to set admin state
  const setAdmin = (isAdmin) => {
    setIsAdmin(isAdmin);
  };

  // Function to set user state
  const setUser = (isUser) => {
    setIsUser(isUser);
  };

  // Value object containing admin state and setter function
  const authValues = {
    isAdmin,
    isUser,
    setAdmin,
    setUser,
  };

  // Provide the authValues to the children components
  return (
    <AuthContext.Provider value={authValues}>
      {children}
    </AuthContext.Provider>
  );
};