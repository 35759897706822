import React, { useEffect, useState } from "react";
// import { getFromAPI } from "../utils/utils";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./Holidays.module.css";
import { useAuth } from "../../Context/context";
import axios from "axios";
import { FaRegTrashAlt } from "react-icons/fa";
import { FaRegEdit } from "react-icons/fa";
import { Link } from "../utils/link";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AdminHolidays() {
  //const [data, setData] = useState({});
  const [showFormModal, setShowFormModal] = useState(false);
  const [showEditModal, setShowEditForm] = useState(false);
  const [editingHolidayId, setEditingHolidayId] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [holidayData, setHolidayData] = useState([]);
  const [head,setHeading] = useState("")
  const [tag,setTagline] = useState("")
  const [inst1, setIns1] = useState("")
  const [ints2 ,setIns2] = useState("")
  const [day, setDay] = useState("")
  const [Form,setForm] = useState("")
  const { isAdmin } = useAuth();
    const [holidayForm, setHolidayForm] = useState({
    name: "",
    instruction1: "",
    instruction2: "",
    instruction3: "",
    date: "",
  });
  
  const [formData,setFormData] = useState({
    name: "",
    instruction1: "",
    instruction2: "",
    instruction3: "",
    date: "",
    holiday_id:""
  })

  console.log(holidayData)

  const handleEditt = async () => {
    console.log("handleEditt function called");
    try {
      // Assuming holidayData is an array of holiday objects
      const holidayId = holidayData.map(holiday => holiday.id);
      console.log(holidayId);
        if (!holidayId) {
        console.error("Holiday not found for ID:", formData.holiday_id);
        return;
      }
  
      const form = new FormData();
      form.append("name", formData.name);
      form.append("instruction1", formData.instruction1);
      form.append("instruction2", formData.instruction2);
      form.append("instruction3", formData.instruction3);
      form.append("date", formData.date);
      form.append("holiday_id",holidayId)
      // form.append("holiday_id", holidayId);
      console.log(formData)
  
      const res = await axios.put(
        `${Link}/admin/holidays/edit/api?id=${holidayId}`,
        form,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Holiday Updated successfully", res.data);
    } catch (error) {
      console.error("Error updating holiday:", error);
    }}
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setHolidayForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Fetch data from project

  const showForm = () => {
    setShowFormModal(true);
  };

  const showEditform = () => {
    
    setShowEditForm(true);
   
  };
  

  const handleCloseFormModal = () => {
    setShowFormModal(false);
    setHolidayForm({
      name: "",
      instruction1: "",
      instruction2: "",
      instruction3: "",
      date: "",
    });
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

 
const handleSubmit = async (e) => {
  e.preventDefault();
  

  // if(holidayForm.name.trim() == ""){
  //   toast.error("Name required")
  // }
  try {
    const form = new FormData();
    form.append("name", holidayForm.name);
    form.append("instruction1", holidayForm.instruction1);
    form.append("instruction2", holidayForm.instruction2);
    form.append("instruction3", holidayForm.instruction3);
    form.append("date", holidayForm.date);

    
      const response = await axios.post(
        Link + "/admin/holidays/create",
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Holiday created successfully:", response.data);
    
    setShowFormModal(false);
    fetchData();
  } catch (error) {
    console.error("Error creating/updating holiday:", error);
  }
};

  const handleDelete = async (holiday_id) => {
    try {
      // Send request to delete holiday with id holidayId
      const res = await axios.delete(
        Link + `/admin/holidays/delete/api?id=${holiday_id}` ,{withCredentials:true}
      );
      // Update holidayData state to reflect the deletion
 
      console.log("Holiday deleted successfully",res.data);
      toast.success("Deleted Successfully!")
      fetchData();
    } catch (error) {
      console.error("Error deleting holiday:", error);
    }
  };

  const fetchData = async ()=>{
    try {
      const response = await axios.get(Link+'/admin/holidays/json',
      {
        withCredentials: true,
      } 
      );
      setHolidayData(response.data);
      console.log(response.data +"thithtihtihtihti")
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);


  const handleEditClick = (hId , hName ,hTagline ,hIns2 ,hIns3) => {
    showEditform()
    setIns1(hIns2)
    setEditingHolidayId(hId)
    setHeading(hName)
    setIns2(hIns3)
    setTagline(hTagline)
  };
  


  return (
    <>
      <div className={styles.holiday}>
        <div className={styles.Container}>
          <div className={styles.flex}>
            <h2>Holidays</h2>
       
              <button onClick={showForm} className={styles.button}>
                + Create Holiday
              </button>
       
          </div>
    {/* Create Holiday */}
          <Modal
            show={showFormModal}
            // onHide={handleCloseFormModal}
            dialogClassName={styles.customModal}
            style={{ opacity: 1, zIndex: 9999 }}
          >
            {/* <Modal.Header >
              <Modal.Title> </Modal.Title>
            </Modal.Header> */}
            <Modal.Body>
              <form>
                {/* Full name and last name column */}
                <div className={styles.nameColumn}>
                  <h2>Create a New Holiday</h2>
                  <div className={styles.nameContainer}>
                    <h6>Heading</h6>
                    <input type="text" name="name" onChange={handleChange} required />
                  </div>
                  <div className={styles.nameContainer}>
                    <h6>Tagline</h6>
                    <input
                      type="text"
                      name="instruction1"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                {/*  date column */}
                <div className={styles.nameColumn}>
                  <div className={styles.dateContainer}>
                    <h6>Date</h6>
                    <input type="date" name="date" onChange={handleChange} required />
                  </div>
                </div>

                {/* Instruction 1 & 2 column */}
                <div className={styles.nameColumn}>
                  <div className={styles.nameContainer}>
                    <h6>Instruction 1</h6>
                    <textarea
                      id="description"
                      name="instruction2"
                      rows="2"
                      cols="50"
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>
                  <div className={styles.nameContainer}>
                    <h6>Instruction 2</h6>
                    <textarea
                      id="description"
                      name="instruction3"
                      rows="2"
                      cols="50"
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>
                </div>

                <div className="button-container">
            <button
              type="submit"
              onClick={handleSubmit}
              style={{ backgroundColor: "var(--primary)", color: "white" }}
            >
              Submit
            </button>
            <button
              onClick={handleCloseFormModal}
              style={{ backgroundColor: "var(--cancel)", color: "white" }}
            >
              Cancel
            </button>
          </div>
              </form>
              
            </Modal.Body>
          </Modal>

    {/* Edit form */}

          {showEditModal && (
 <Modal
 show={showEditform}
 // onHide={handleCloseFormModal}
 dialogClassName={styles.customModal}
 style={{ opacity: 1, zIndex: 9999 }}
>
 <Modal.Body>
   <form>
     {/* Full name and last name column */}
     <div className={styles.nameColumn}>
       <h2>Edit Holiday</h2>
       <div className={styles.nameContainer}>
         <h6>Heading</h6>
         <input type="text" name="name"  value={head}   onChange={(e) => setHeading(e.target.value)} />
       </div>
       <div className={styles.nameContainer}>
         <h6>Tagline</h6>
         <input
           type="text"
           name="instruction1"
           value={tag} 
           onChange={(e) => setTagline(e.target.value)  }   
         />
       </div>
     </div>

     {/*  date column */}
     <div className={styles.nameColumn}>
       <div className={styles.dateContainer}>
         <h6>Date</h6>
         <input type="date"   onChange={(e) => setFormData({ ...formData, date: e.target.value })} value={formData.date} name="date" />
       </div>
     </div>

     {/* Instruction 1 & 2 column */}
     <div className={styles.nameColumn}>
       <div className={styles.nameContainer}>
         <h6>Instruction 1</h6>
         <textarea
           id="description"
           name="instruction2"
           rows="2"
           cols="50"
           value={inst1}
           onChange={(e) => setIns1(e.target.value)}     
                 
         ></textarea>
       </div>
       <div className={styles.nameContainer}>
         <h6>Instruction 2</h6>
         <textarea
           id="description"
           name="instruction3"
           rows="2"
           cols="50"
           value={ints2}
           onChange={(e) => setIns2(e.target.value)}  
         ></textarea>
       </div>
     </div>

     <div className="button-container">
 <button
  //  type="submit"
   onClick={() => handleEditt()}
 >
   Submit
 </button>
 <button
  
   onClick={handleCloseFormModal}
   style={{ backgroundColor: "var(--cancel)", color: "white" }}
 >
   Cancel
 </button>
</div>
   </form>
   
 </Modal.Body>
</Modal>
)}

          
          <div className={`row ${styles.customRow}`}>
            {holidayData?.map((holiday, index) => (
              <div key={index} className={`col ${styles.col}`}>
                <div
                  className={`card border-light shadow-sm p-3 mb-2 bg-body rounded${styles.fullWidth}`}
                >
                  <div className="card-body">
                    <h5 className="card-title">{holiday.name}</h5>
                    {/* <h6 className="card-subtitle mb-2 border-light shadow mb-2 mt-2">
                      {holiday.ins1}
                    </h6> */}
                    <p className="card-text" style={{marginBottom:"0px"}}> {holiday.ins1}</p>
                    <p className="card-text" style={{marginBottom:"0px"}}> {holiday.ins2}</p>
                    <p className="card-text" style={{marginBottom:"0px"}}> {holiday.ins3}</p>
                    <span className={`badge ${styles.dangerBadge}`}>
                      {holiday.day} {holiday.month}
                    </span>
                    <div className={styles.icons}>
              
                        <button
                          className={styles.editIcon}
                          // onClick={() => handleEditt(holiday.id)}
                          onClick={() => handleEditClick( holiday.id,holiday.name,holiday.ins1,holiday.ins2 ,holiday.ins3 )}
                          // onClick={showEditform}
                        >
                          <FaRegEdit  size={17} />
                        </button>
               
                        <button
                          className={styles.removeIcon}
                          onClick={() => handleDelete(holiday.id)}
                        >
                          <FaRegTrashAlt  size={15} />
                        </button>
                    
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
          <ToastContainer/>
      </div>
    </>
  );
}
export default AdminHolidays;
