import React from "react";
import styles from "./login.module.css";
import { useAuth } from "../../Context/context";
import { useState } from "react";
import axios from "axios";
import { Link } from "../utils/link";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Login() {
  //context variable which sets the value globally 
//  const { setUser } = useUserContext();
 const {setAdmin ,setUser,isAdmin ,isUser} =useAuth()
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  
  //console.log(password);

  //this navigates and direct to the page after login
  const navigate = useNavigate();

  //for future forgotpassword code
  //const [forgotPassword, setForgotPassword] = useState(false);
  //const [email, setEmail] = useState('');
  //  const [resetMessage, setResetMessage] = useState('');

  //this handle username input change event
  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  //this handle password input change event
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  //on submiting the login form this event occurs
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
     
      //checks the input field
      if (!username) {
        toast.error("Please enter username");
      }  
      if (!password) {
        toast.error("Please enter password");
      }

      // API to post the login input 
      const res = await axios.post(`${Link}/auth/login/json`, {
        username,
        password,
      },{withCredentials:true});
      console.log("fetching done"+new Date())
      //logic which checks user and password are correct 
      if (username && password) {
            //logic which checks valid username 
        if (res.data.username) {
                //logic which checks is user active or not 
          if (res.data.isUserActive) {
             //logic which checks valid password
            if (res.data.pwd) {
              //console.log("success"+  new Date());

              //logic to check is admin or not
              if(res.data.isAdmin){
                setAdmin(true)
                setUser(false)
                navigate("/admindashboard")
              }
                //console.log("admin page display time "+new Date())
              if(res.data.isUser){
                setUser(true);
                setAdmin(false)
                navigate("/dashboard")
              }
              console.log("admin is"+isAdmin )
              console.log("user is"+isUser )
                //console.log("navigation done at"+new Date())
              }else {
                toast.error("Invalid Password");
              }
  
              //context storing data call
             // setUser(res.data);
            } else {
              toast.error("Not an Active user");
          } 
          } else {
            toast.error("Invaid username");
          }
        }
      
      // Check if the response status is not OK
      if (res.status !== 200) {
        toast.error("Failed to authenticate");
      }
    } catch (error) {
        console.log(error)
  };
  }

  const handleForgotPasswordClick = () => {
    navigate("/email");
  }
  return (
    
    <div className={styles.login}>
      <div className={styles.container}>
        <div className={styles["screen-1"]}>
          {/* header section  */}
          <div className={styles.logo}>
            <span>WorkSpace</span>
          </div>
          {/* input fields section  */}
          <form onSubmit={handleSubmit} >
            <div className={styles.email}>
            <label htmlFor="text">Username</label>
            <div className={styles["sec-2"]}>
              <i className="bi bi-envelope"></i>
              <input
                type="text"
                name="text"
                placeholder="Enter User Name"
                value={username}
                onChange={handleUsernameChange}
              />
            </div>
          </div>
          <div className={styles.password}>
            <label htmlFor="password">Password</label>
            <div className={styles["sec-2"]}>
              <i className="bi bi-lock-fill"></i>
              <input
                type="password"
                name="password"
                placeholder="Enter Password"
                value={password}
                onChange={handlePasswordChange}
              />
              <i className="bi bi-eye-fill show-hide"></i>
            </div>
          </div>

            <button type="submit" className={styles.submit}>
              Login
            </button>
          </form>
          <div className={styles.footer}>
            {/* <span>Sign up</span> */}
            <span onClick={handleForgotPasswordClick} style={{ cursor: 'pointer' }}>
              Forgot Password?
            </span>
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
