import React, { useEffect, useState ,useMemo} from 'react'
import styles from "./AdminLeave.module.css";
import * as FaIcons from "react-icons/fa";
import axios from 'axios';
import { Link } from '../utils/link';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LeaveConfiguration from './LeaveConfiguration';
import LeaveAnalysis from './LeaveAnalysis';
import LeaveApproval from './LeaveApproval';

const Button = ({ label, onClick, isSelected }) => {
  return (
    <button className={`btn ${isSelected ? 'btn-secondary active' : 'btn-light'}`} onClick={onClick}>
      {label}
    </button>
  );
};

const Page1 = () => <LeaveApproval/>;
const Page2 = () => <LeaveAnalysis/>;
const Page3 = () => <LeaveConfiguration/>

function LeaveRequest() {


const [selectedButton, setSelectedButton] = useState('page1');

const handleButtonClick = (buttonId) => {
  setSelectedButton(buttonId);
};

const renderPage = useMemo(() => {
  switch (selectedButton) {
    case 'page1':
      return <LeaveApproval/>;
    case 'page2':
      return <LeaveAnalysis/>;
    case 'page3':
      return <LeaveConfiguration/>;
    default:
      return null;
  }
},[selectedButton])

  return (
   
    <>
    <div className={styles.leaveButton}>      
 
      <div className ={`mb-3 ${styles.buttonContainer}`}  >
        {/* Button components */}
        <Button label="Leave Approval" onClick={() => handleButtonClick('page1')} isSelected={selectedButton === 'page1'} />
        {/* <Button label="Leave Analysis" onClick={() => handleButtonClick('page2')} isSelected={selectedButton === 'page2'} /> */}
        <Button label="Leave Configuration" onClick={() => handleButtonClick('page3') } isSelected={selectedButton === 'page3'} />
      </div>
      {/* Render the selected page component */}
      {renderPage}
  


    </div>
    
 
    
    </>
    
    
  )
}

export default LeaveRequest