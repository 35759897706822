import React from 'react';
import Styles from './Footer.module.css'; 
function Footer () {
return (
<div className={Styles.footer}>
    <p>© 2024 AlgoBrain AI . All Rights Reserve</p>

</div>
);
}
export default Footer;