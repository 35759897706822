import React, { useEffect, useState } from 'react';
import styles from "./leaveRequest.module.css";
import * as FaIcons from "react-icons/fa";
import axios from 'axios';
import { Link } from '../utils/link';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

function LeaveApproval() {

  const [selectedDate, setSelectedDate] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(moment().month());
  const [currentYear, setCurrentYear] = useState(moment().year());
  const [leaveData, setLeaveData] = useState([]);
  const [leaveId, setLeaveId] = useState(null);
  const [status, setStatus] = useState(null);
  const [employeeEmail, setEmployeeEmail] = useState("");
  const [applyDate, setApplyDate] = useState("");
  const [msg, setMsg] = useState("");

  const handleDateChange = (e) => {
    const selectedDate = moment(e.target.value);
    setSelectedMonth(selectedDate.month());
    setCurrentYear(selectedDate.year());
  };


  useEffect(() => {
    handleSubmit();
  }, []);

  const handleSubmit = async () => {
    console.log(selectedMonth);
    console.log(currentYear);
    try {
      const res = await axios.get(`${Link}/admin/leave/api?month=${selectedMonth+1}&year=${currentYear}`, { withCredentials: true });
      console.log(res.data);
      setLeaveData(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAdminResponse = async (s, email, applyDate, message, id) => {
    s = s ? 1 : 0;
    setEmployeeEmail(email);
    setApplyDate(applyDate);
    setMsg(message);
    setLeaveId(id);

    if (s === 1) {
      toast.success("Leave Approved");
    } else {
      toast.success("Leave Declined");
    }

    try {
      const res = await axios.post(`${Link}/admin/leave/update_leave_status`, {
        status: s,
        employee_email: email,
        apply_date: applyDate,
        leaveID: id,
        msg: message,
      }, { withCredentials: true });
      console.log(res.data);
      handleSubmit();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className={styles.leave}>
        <div className={styles.Container}>
          <div className={styles.customRow}>
            <h2>Leave Approval:</h2>
            <div className={styles.headerInput}>
              <input type="month" id="start" name="start" onChange={handleDateChange} />
              <button className={styles.dateSubmit} onClick={handleSubmit}>Submit</button>
            </div>
          </div>
          <div className={`row ${styles.customRow2}`}>
            {leaveData.leaves?.map((leaveRequest) => (
              <div className={`card ${styles.leaveCard}`} key={leaveRequest._id}>
                <h5 className={`card-header d-flex justify-content-between ${styles.header}`}>
                  <span style={{fontSize: "17px"}}>{leaveRequest.leave_type} Leave</span>
                  <h5 className={`card-title ${styles.headerDate}`}>
                    Applied Date: <span>{leaveRequest.applied_on}</span>
                  </h5>
                </h5>
                <div className={styles.cardbody}>
                  <span className={styles.Icon}>
                    <FaIcons.FaRegUserCircle />
                  </span>
                  <div className={styles.infoHeader}>
                    <span className={`card-title ${styles.text} ${styles.dates}`}>Name: {leaveRequest.emp_name}</span>
                    <span className={`card-title ${styles.text} ${styles.dates}`}>Email: {leaveRequest.emp_email}</span>
                    <span className={`card-title text-success ${styles.dates}`}>Start Date: <span className={styles.dateText}>{leaveRequest.start_date}</span></span>
                    <span className={`card-title text-success ${styles.dates}`}>End Date: <span className={styles.dateText}>{leaveRequest.end_date}</span></span>
                    {leaveRequest.message && (
                      <span className={`card-title text-success ${styles.dates}`}>Message: <span className={styles.dateText}>{leaveRequest.message}</span></span>
                    )}
                  </div>
                  <h5 className={`card-title ${styles.statusText}`}>
                    Status: <span>{leaveRequest.status === 0 ? "Declined" : leaveRequest.status === 1 ? "Approved" : "Pending"}</span>
                  </h5>
                  {leaveRequest.status === -1 && (
                    <div className={styles.container}>
                      <textarea id="description" name="description" rows="1" cols="50" placeholder="Enter your announcement here..."></textarea>
                      <div className={styles.buttonContainer}>
                        <button
                          onClick={() => handleAdminResponse(true, leaveRequest.emp_email, leaveRequest.applied_on, leaveRequest.message, leaveRequest._id)}
                          style={{ backgroundColor: "var(--primary)", color: "white" }}
                        >
                          Approve
                        </button>
                        <button
                          onClick={() => handleAdminResponse(false, leaveRequest.emp_email, leaveRequest.applied_on, leaveRequest.message, leaveRequest._id)}
                          style={{ backgroundColor: "var(--cancel)", color: "white" }}
                        >
                          Decline
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
}

export default LeaveApproval;
