import React from 'react'
import { useAuth } from "../../Context/context.js";
import Holidays from './Holidays.jsx';
import AdminHolidays from './AdminHolidays.jsx';
//import UserHoliday from './UserHoliday.jsx'; 
const Holi = () => {
    const {isAdmin }=useAuth()
  return (
    <div>
      {isAdmin? <AdminHolidays/>:<Holidays/>}
    </div>
  )
}

export default Holi
