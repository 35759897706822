import React, { useEffect, useState } from "react";
import { getFromAPI } from "../utils/utils";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./Holidays.module.css";
import { useAuth } from "../../Context/context";
import axios from "axios";
import { FaRegTrashAlt } from "react-icons/fa";
import { FaRegEdit } from "react-icons/fa";
import { Link } from "../utils/link";

function Holidays() {
  //const [data, setData] = useState({});
  const [showFormModal, setShowFormModal] = useState(false);
  const [editingHolidayId, setEditingHolidayId] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [holidayData, setHolidayData] = useState([]);
  const [holidayForm, setHolidayForm] = useState({
    name: "",
    instruction1: "",
    instruction2: "",
    instruction3: "",
    date: "",
  });



  // Fetch data from project


  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  async function fetchData() {
    try {
      const response = await axios.get(Link+"/holidays/json" ,{withCredentials:true});
  
      setHolidayData(response.data.holidays);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
   
    fetchData();
  }, []);
  console.log("this is actual"+holidayData);
  //const { user } = useUserContext();

  return (
    <>
      <div className={styles.holiday}>
        {/* <div className={styles.Container}> */}
          <div className={styles.flex}>
            <h2>Holidays</h2>
         
          </div>

        
          <div className={`row ${styles.customRow}`}>
            {holidayData?.map((holiday, index) => (
              <div key={index} className={`col ${styles.col}`}>
                <div
                  className={`card border-light shadow-sm p-3 mb-2 bg-body rounded${styles.fullWidth}`}
                >
                  <div className="card-body">
                    <h5 className="card-title" >{holiday.name}</h5>
                    {/* <h6 className="card-subtitle mb-2 border-light shadow mb-2 mt-2">
                      {holiday.ins1}
                    </h6> */}
                     <p className="card-text" style={{marginBottom:"5px"}}> {holiday.ins1}</p>
                    <p className="card-text" style={{marginBottom:"5px"}}> {holiday.ins2}</p>
                    <p className="card-text" style={{marginBottom:"5px"}}> {holiday.ins3}</p>
                    <span className={`badge ${styles.dangerBadge}`}>
                      {holiday.day} {holiday.month}
                    </span>
               
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      {/* </div> */}
    </>
  );
}
export default Holidays;
