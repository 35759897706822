import React from "react";
import styles from "./Email.module.css";
import { useAuth } from "../../Context/context";
import { useState } from "react";
import { Link } from "../utils/link";
import { useNavigate  } from "react-router-dom";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Email() {
  //context variable which sets the value globally
  //  const { setUser } = useUserContext();
  const { setAdmin, setUser, isAdmin, isUser } = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [emailData, setEmailData] = useState("");
  const [error, setError] = useState("");
  //console.log(password);

  //this navigates and direct to the page after login
  const navigate = useNavigate();

  //this handle username input change event
  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    console.log("Username:", e.target.value);
  };

  //this handle password input change event
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  //on submiting the login form this event occurs
  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  const verifyEmail = async () => {
    console.log("Verifying email...");
    try {
      const res = await axios.post(
        Link + `/auth/otp_verification/check_email`,
        { email: username }, // Use the username state as the email value
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Response:", res);

      if (res.data.email) { 
        // Check if response contains the username (or any data indicating success)
        console.log("Response data:", res.data);
        setEmailData(res.data);
        navigate('/otp', { state: { emailData: res.data } });
        toast.success("Email verified")
      } else {
        toast.error("Email doesn't exist")
        console.error("Email does not exist");
       
      }
      
    } catch (error) {
      console.error("Failed to verify email:", error);
      
    }
  };
  
  
  
  return (
    <div className={styles.email}>
      <div className={styles.container}>
        <div className={styles["screen-1"]}>
          {/* header section  */}
          <div className={styles.emailTexthead}>Email Verification</div>
          {/* input fields section  */}
          <label className={styles.emailInfo}>
            Enter your Email Address to reset your password
          </label>
        
            <div className={styles.emailInput}>
             
              <input
                type="email"
                name="username"
                placeholder="Enter Email"
                value={username}
                onChange={handleUsernameChange}
              />
            </div>
     
          <button onClick={verifyEmail} className={styles.buttonEmail} >
          <label className={styles.label1}>Submit</label> 
          </button>
        </div>
        <ToastContainer />

      </div>
    </div>
  );
}

export default Email;
