import React, { useRef } from 'react';
import styles from "./Otp.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from 'react';
import axios from 'axios';
import { Link } from '../utils/link';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Otp() {
  const [otp , setOtp] = useState(['', '', '', '', '', '']);
  const location = useLocation();
  const emailData = location.state?.emailData || {};
  const navigate = useNavigate();
  const inputRefs = useRef([]); // Refs for each input field

  const handleChange = (index, value) => {
    const updatedOtp = [...otp];
    updatedOtp[index] = value;
    setOtp(updatedOtp);

    // Move to the next input field
    if (value && index < otp.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handlePaste = (e) => {
    const pastedData = e.clipboardData.getData('text');
    if (pastedData.length === 6 && /^\d+$/.test(pastedData)) {
      setOtp(pastedData.split(''));
    }
  };

  const verifyOtp = async () => {
    try {
      const res = await axios.post(
        Link + "/auth/otp_verification/check_otp",
        { otp: otp.join('') },
        { withCredentials:true, headers: { "Content-Type" : "application/json" } }
      );
      if(res.data.isValid == true){
        console.log("Response data:", res.data);
        navigate('/password');
      } else {
        toast.error("Incorrect Otp")
        console.log("error");
      }
    } catch (error) {
      console.error("Failed to verify OTP:", error);
    }
  };

  return (
    <div className={styles.login}>
      <div className={styles.container}>
        <div className={styles["screen-1"]}>
          <div className={styles.email}>
            <label htmlFor="email">OTP Verification</label>
          </div>
          <div className={styles.title}>
            <span>Your Email to reset Password:</span>
          </div>
          <div className='card'>
            <div className={styles.card}>
              <p className={styles.mail}>{emailData.email}</p>
            </div>
          </div>
          <div>
            <p>Enter OTP</p>
          </div>
          <div className="d-flex flex-row gap-1">
            {otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                className="form-control"
                value={digit}
                onChange={(e) => handleChange(index, e.target.value)}
                onPaste={handlePaste}
                maxLength={1}
                ref={(ref) => inputRefs.current[index] = ref} // Assign ref to the input field
              />
            ))}
          </div>
          <button className={styles.button1} onClick={verifyOtp}>
            <label>Submit</label>
          </button>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}
