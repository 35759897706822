import React, { useState, useEffect } from 'react';
import ColorTabs from './Buttongrp';
import ProjectDashboard from './ProjectDashboard';
import Epic from '../Epic/Epic';
import Kanban from './Kanban';
import Logs from './Logs';
import AdminProjectDashboard from '../project/AdminProjectDashboard'
import AdminEpic from '../Epic/AdminEpic'
import { useParams } from 'react-router';
import { useAuth } from '../../Context/context';
import AdminKanban from '../project/AdminKanban'
import UserNotification from '../Notification/userNotification';

const ProjectPage = () => {
    const { id } = useParams();
    const { isAdmin } = useAuth();
    const [selectedTab, setSelectedTab] = useState(0);

    useEffect(() => {
        // Retrieve the stored value from localStorage
        const storedValue = localStorage.getItem('selectedTab');
        if (storedValue) {
            setSelectedTab(Number(storedValue));
        }
    }, []);

    const handleTabChange = (newValue) => {
        setSelectedTab(newValue);
        localStorage.setItem('selectedTab', newValue); // Save the selected value to localStorage
    };

    return (
        <div>
            <ColorTabs value={selectedTab} onChange={handleTabChange} />
            {/* Use ternary operator to conditionally render components based on selectedTab */}
            {selectedTab === 0 ? (!isAdmin ? <ProjectDashboard id={id} /> : <AdminProjectDashboard id={id} />) : null}
            {selectedTab === 1 ? (!isAdmin ? <Epic id={id} /> : <AdminEpic id={id} />) : null}
            {selectedTab === 2 ? (!isAdmin ? <Kanban id={id} /> : <AdminKanban id={id} />) : null}
            {selectedTab === 3 ? (!isAdmin ? <Logs id={id}/> : <Logs id={id}/>) : null}
        </div>
    );
};

export default ProjectPage;
