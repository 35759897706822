import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";
import styles from "./kanban.module.css";
import ColorTabs from "./Buttongrp"; // Assuming you use this component somewhere
import { Link } from "../utils/link"; // Ensure this is correctly imported

const Kanban = ({ id }) => {
  const [selectedEpicId, setSelectedEpicId]=useState("")
  const [fetchData, setFetchData] = useState(null);
  console.log(fetchData);

  const statusMapping = {
    backlog: 0,
    doing: 1,
    review: 2,
    done: 3,
  };
  const priorityMapping = {
    0: "Medium",
    1: "Urgent",
    "-1": "Low",
  };
  const priorityClassMapping = {
    0: styles.badgeMedium,
    1: styles.badgeUrgent,
    "-1": styles.badgeLow,
  };

  const onDragEnd = async (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) return;

    // Prevent regular users from moving task-4 or moving tasks to 'Done' column
    if (fetchData.isProjLead !== true) {
      if ( destination.droppableId === "done") {
        return;
      }
    }
    if (source.droppableId === "done" && destination.droppableId !== "done") {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = fetchData.epicTasks[source.droppableId];
    const finish = fetchData.epicTasks[destination.droppableId];

    if (start === finish) {
      const newTaskIds = Array.from(start);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, start[source.index]);

      const newState = {
        ...fetchData,
        epicTasks: {
          ...fetchData.epicTasks,
          [source.droppableId]: newTaskIds,
        },
      };

      setFetchData(newState);
      return;
    }

    // Moving between columns
    const startTaskIds = Array.from(start);
    const finishTaskIds = Array.from(finish);
    const [movedTask] = startTaskIds.splice(source.index, 1);
    finishTaskIds.splice(destination.index, 0, movedTask);

    const newState = {
      ...fetchData,
      epicTasks: {
        ...fetchData.epicTasks,
        [source.droppableId]: startTaskIds,
        [destination.droppableId]: finishTaskIds,
      },
    };

    setFetchData(newState);
    const newStatus = statusMapping[destination.droppableId];

    try {
      console.log("this" + [destination.droppableId]);

      const res = await axios.post(
        Link + "/projects/details/task/update_progress",
        { taskId: draggableId, newStatus: newStatus, emp_id: fetchData.emp_id ,epic_id:fetchData.epicID[0] },
        { withCredentials: true }
      );
      console.log(res.data + "this is res");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleFetch();
  }, [id]);

  
const handleEpicId =(e)=>{
  setSelectedEpicId(e.target.value)

}
useEffect(() => {
  if (selectedEpicId) {
    handleFetch();
  }
}, [selectedEpicId]);



  const handleFetch = async () => {
    try {
      const res = await axios.get(
        `${Link}/projects/details/kanban/json?id=${id}&epic=${selectedEpicId}`,
        { withCredentials: true }
      );
      setFetchData(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  if (!fetchData) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.Mainpage}>
      <div className={styles.KanbanTitle}>
        <h3>{fetchData.proj_name}</h3>
        <select className={styles.customSelect} onChange={(e)=>handleEpicId(e)}>
          <option>Select Epic</option>
          {fetchData.epic_data?.map((EpicIndex)=>{
              return (
          <option value={EpicIndex.id} key={EpicIndex.id}>{EpicIndex.name}</option>  )
              })}
         </select>
      </div>

      <div className={styles.DragContent}>
        <DragDropContext onDragEnd={onDragEnd}>
          {Object.keys(fetchData.epicTasks).map((columnId) => {
            const tasks = fetchData.epicTasks[columnId];

            return (
              <Droppable key={columnId} droppableId={columnId}>
                {(provided) => (
                  <div
                    className={styles.droppable}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    <h3 className={styles.listTitle}>
                      {columnId}{" "}
                      <span className={styles.CountTask}>{tasks.length}</span>
                    </h3>
                    {tasks.map((task, index) => (
                      <Draggable
                        key={task.task_id}
                        draggableId={task.task_id}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            className={styles.draggable}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div className="text-dark .fs-4 mb-1">{task.task_name}</div>

                            <div>
                              <span
                                className={`${styles.badge} ${
                                  priorityClassMapping[task.priority]
                                }`}
                              >
                                {priorityMapping[task.priority]}
                              </span>
                              <span
                                className={`${styles.badge} ${styles.badgeDate}`}
                              >
                                {task.edate}
                              </span>
                            </div>
                            <div className="text-secondary">
                              <span
                                className={`${styles.badge} ${styles.badgeEmp}`}
                              >
                                {task.emp_name}
                              </span>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            );
          })}
        </DragDropContext>
      </div>
    </div>
  );
};

export default Kanban;
