import Indexes from './components/routes/Index';
import './App.css';
import { AuthProvider } from './Context/context';
//import { UserProvider } from './Context/context';

function App() {
  return (
    //      <UserProvider>
    <AuthProvider>
      <Indexes />
    </AuthProvider>
    //    </UserProvider>
  );
}

export default App;
