import React, { Component, useEffect } from "react";
import styles from "./Projectdash.module.css";
import { useState } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import ColorTabs from "./Buttongrp";
import { MdAddTask } from "react-icons/md";
import {
  FaTasks,
  FaUsers,
} from "react-icons/fa"; // Import icons
import { MdOutlineWatchLater } from "react-icons/md";

import "bootstrap/dist/css/bootstrap.min.css";
import "chart.js/auto";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import f1 from "../images/f1.jpg";
import f2 from "../images/f2.jpg";
import f3 from "../images/f3.jpg";
import m1 from "../images/m1.jpg";
import m2 from "../images/m2.jpg";
import m3 from "../images/m3.jpg";
import m4 from "../images/m4.jpg";
import m5 from "../images/m5.jpg";

import CircularProgressBar from "./CircularProgressBar"; // Import CircularProgressBar component
import PriorityPieChart from "./PriorityPieChart";
import MultiLineChart from "./MultiLineGraph";
import StackedBarGraph from "./StackedBarGraph";
import { FaCircle } from "react-icons/fa6";
import { Link } from "../utils/link";

function ProjectDashboard({ id }) {
  const [data, setData] = useState([]);
  const [fetcData, setFetchData] = useState([]);
  const [adminGraphData, setAdminGraphData] = useState([]);
  const [progress, setProgress] = useState(0);
  const projectId = id; // Project Id

  console.log("dashboard:" + id);

  //dashboard data
  // Fetch dashboard data
  useEffect(() => {
    const FetchDashData = async () => {
      try {
        const response = await fetch(
          Link + `/admin/projects/details/json?id=${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include", // corrected the placement of credentials
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log("Response data:", data);
        setData(data);
        setProgress(data.progress); // Assuming progress is part of the response
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    FetchDashData();
  }, [id]);
  console.log("data:", data);

  //team member
  const teamMembers = data.proj_contris?.map((contributor, index) => ({
    id: contributor.id,
    name: contributor.name,
    img: [f1, f2, f3, m1, m2, m3][index % 6], // Assign images in a round-robin fashion
    role: "Contributor" // Assuming all contributors have the same role
  })) || [];


  return (
    <>
      <div className={styles.projdash}>
        <h2>{data.proj_name}</h2>
        <div className={`row  ${styles.summary}`}>
          {/* Summary Card */}
          <div className="col-sm-9 position-relative">
            <div className={`card mb-2 mt-1 ${styles.gradientbg}`}>
              <div className="card-body text-secondary overflow-auto">
                <h5 className="card-title mb-1  text-dark fw-bold fs-4 mb-2">
                  <div className={styles.head}>Summary</div>
                </h5>
                <div className="row">
                  {/* Card 1 */}
                  <div className="col-sm-3">
                    <div className={`card mb-2 mt-1 ${styles.cardPink}`}>
                      <div className="card-body text-secondary overflow-auto">
                        <MdAddTask size={30} className="text-danger" />
                        <h5 className="card-title mt-3 text-dark fw-medium fs-4 mb-1">
                        {data.no_epics || 0}
                        </h5>
                        <p>Total Epic</p>
                      </div>
                    </div>
                  </div>
                  {/* Card 2 */}
                  <div className="col-sm-3">
                    <div className={`card mb-1 mt-2 ${styles.cardYellow}`}>
                      <div className="card-body text-secondary overflow-auto">
                        <FaTasks size={30} className="text-warning" />
                        <h5 className="card-title mt-3 text-dark fw-medium fs-4 mb-1">
                        {data.no_total_tasks || 0}
                        </h5>
                        <p>Total Task</p>
                      </div>
                    </div>
                  </div>
                  {/* Card 3 */}
                  <div className="col-sm-3">
                    <div className={`card mb-2 mt-2 ${styles.cardGreen}`}>
                      <div className="card-body text-secondary overflow-auto">
                        <MdOutlineWatchLater size={30} className="text-success" />
                        <h5 className="card-title mt-3 text-dark fw-medium fs-4 mb-1">
                        {data.projDeadline || "N/A"}
                        </h5>
                        <p>Deadline Days</p>
                        
                      </div>
                    </div>
                  </div>
                  {/* Card 4 */}
                  <div className="col-sm-3">
                    <div className={`card mb-2 mt-2 ${styles.cardPurple}`}>
                      <div className="card-body text-secondary overflow-auto">
                        <FaUsers size={30} className="text-primary" />
                        <h5 className="card-title mt-3 text-dark fw-medium fs-4 mb-1">
                        {data.proj_contris?.length || 0}
                        </h5>
                        <p>Team Member</p>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Team Card */}
          <div className={`col-sm-3  position-relative ${styles.team} `}>
            <div class={`card mb-2 mt-2 ${styles.gradientbg}`}>
              <div class="card-body text-secondary overflow-auto">
                <h5 className="card-title mb-4 mt- text-dark fw-bold fs-4 mb-2">
                  <div className={styles.head}>Team Member</div>
                </h5>
                <ul className="list-unstyled">
                  {teamMembers.map((member, index) => (
                    <li key={index} className="d-flex align-items-center mb-2">
                      <img
                        src={member.img}
                        alt={member.name}
                        className="rounded-circle"
                        style={{
                          width: "25px",
                          height: "25px",
                          marginRight: "10px",
                        }}
                      />
                      <span className="text-dark">
                        {member.name} - {member.role}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Priority */}
        <div className={`row ${styles.priorityGraph}`}>
          <div className="col-sm-5 mt-2 position-relative">
            <div class={`card mb-2 mt-2 ${styles.gradientbg2}`}>
              <div class="card-body text-secondary overflow-auto">
                <h5 className="card-title text-dark fw-bold fs-4 mb-1 ">
                  <div className={styles.head}>Priority</div>
                </h5>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="chart-container">
                    <PriorityPieChart projectId={projectId} />
                  </div>
                  <ul className={styles.priorityList}>
                    <li className={styles.urgent}>Urgent</li>
                    <li className={styles.medium}>Medium</li>
                    <li className={styles.low}>Low</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* Task Graph */}
          <div
            className={`col-sm-7 mt-2 w-2 position-relative h-4 ${styles.task} `}
          >
            <div className={`card mb-2 mt-2 ${styles.gradientbg2}`}>
              <div className={`card-body text-secondary overflow-auto   `}>
                <h5 className="card-title mb-2 mt-1 text-dark fw-bold fs-4 ">
                  <div className={styles.head}>Epics</div>
                </h5>
                <StackedBarGraph projectId={projectId} />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          {/* Timeline bar */}
          <div className="col-sm-8 position-relative">
            <div class={`card mb-2 mt-2 ${styles.gradientbg2}`}>
              <div class="card-body text-secondary overflow-auto">
                <h5 className="card-title mb-4 mt-2 text-dark fw-bold fs-4 mb-2">
                  <div className={styles.head}>Epic Status</div>
                </h5>
                <MultiLineChart projectId={projectId} />
              </div>
            </div>
          </div>
          {/* Progress bar */}
          <div className="col-sm-4 position-relative">
            <div class={`card mb-1 mt-2 ${styles.gradientbg2}`}>
              <div class="card-body text-secondary overflow-auto">
                <h5 className="card-title  mt-2 text-dark fw-bold fs-4 mb-1">
                  <div className={styles.head}>Progress</div>
                </h5>
                <CircularProgressBar projectId={projectId} />
                <p className={styles.bar}>
                  <FaCircle size={9} /> Performance
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ProjectDashboard;
