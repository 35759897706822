import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import ViewKanbanOutlinedIcon from "@mui/icons-material/ViewKanbanOutlined";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  overrides: {
    MuiTabs: {
      root: {
        justifyContent: 'space-around',
        width: '100%',
      },
    },
  },
});

export default function ColorTabs({ value, onChange }) {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ justifyContent: 'space-around', width: '100%', marginBottom: '20px', mt: 10 }}>
        <Tabs
          value={value}
          onChange={(event, newValue) => onChange(newValue)}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="icon label secondary tabs example"
          centered
        >
          <Tab icon={<GridViewOutlinedIcon />} label="DASHBOARD" />
          <Tab icon={<AssignmentOutlinedIcon />} label="EPICS" />
          <Tab icon={<ViewKanbanOutlinedIcon />} label="KANBAN" />
          <Tab icon={<WorkHistoryOutlinedIcon />} label="LOGS" />
        </Tabs>
      </Box>
    </ThemeProvider>
  );
}
