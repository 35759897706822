import React, { useEffect, useState } from "react";
import styles from "./dailyTasks.module.css";
import f1 from "../images/f1.jpg";
import f2 from "../images/f2.jpg";
import f3 from "../images/f3.jpg";
import m1 from "../images/m1.jpg";
import m2 from "../images/m2.jpg";
import m3 from "../images/m3.jpg";
import m4 from "../images/m4.jpg";
import m5 from "../images/m5.jpg";
import * as FaIcons from "react-icons/fa";
import axios from "axios";
import { Link } from "../utils/link";

function DailyTasks() {

  //this is state which selects the date and updates it
  const [selectedDate, setSelectedDate] = useState("");
  //this is state which stores the tasks list
  const [tasks, setTasks] = useState([]);
  

// const path ="f1"
//   const imgmaping=(path) ={
//     "f1":f1,
//     "f2":f2,
//     "f3":f3,
//     "m1":m1,
//     "m2":m2,
//     "m3":m3,
//     "m4":m4,
    
//   }
// console.log(imgmaping)
  //this renders the  fetch task list continuously
  useEffect(() => {
    fetchTasks();
  }, []);

  //this handles the date change function 
  const handleDateChange = (e) => {
    
    setSelectedDate(e.target.value)

   };
   const handleNewDateTasks =()=>{
    fetchTasks()
   }

   
  //this fetch api which fetchs all task 
  const fetchTasks = async () => {
    try {
      const res = await axios.get(
        Link + `/admin/tasks/json?date=` + selectedDate
      ,{withCredentials:true});
      setTasks(res.data);
      console.log(res.data);
      console.log("succes");
    } catch (error) {
      console.log(error);
    }
  };


 
  const imgMapping = (path) => {
    const variableMap = {
      "f1": f1,
      "f2": f2,
      "f3": f3,
      "m1": m1,
      "m2": m2,
      "m3": m3,
      "m4": m4,
      // Add more mappings as needed
    };
  
    // Check if the path exists in the variable map
    if (variableMap.hasOwnProperty(path)) {
      // Return the variable assigned to the path
      return variableMap[path];
    } else {
      // If the path doesn't exist, return null or handle accordingly
      return null;
    }
  };
  

  

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          {/* header section  */}
          <h2>Daily Tasks</h2>
          <div className={styles.headerDate}>

          <input
            type="date"
            className={styles.dateInput}
            value={selectedDate}
            onChange={handleDateChange}
            placeholder="Date"
            />
          <button className={styles.dateSubmit} onClick={handleNewDateTasks}>Submit</button>
            </div>
        </div>

        {/* card list which shows the taks  */}
        <div className={styles.list}>
          <div className={styles.card}>
            {tasks.map((cards) => (
              <div className={styles.content} key={cards.id}>
                <div className={styles.column1}>
                  <img
                    src={imgMapping(cards.emp_avatarID)}
                    width="50"
                    alt="profile"
                    className="img-icon"
                    style={{ marginRight: "10px", borderRadius: "50%" }}
                  />
                  <div className={styles.columnText}>
                    <h5>{cards.emp_name}</h5>
                    <h6>{cards.emp_email}</h6>
                  </div>
                </div>
                <div className={styles.column2}>
                  <h5>{cards.project_name} </h5>
                  <span> | {cards.task_type}</span>
                </div>
                <div className={styles.column4}>
  <span style={{"display":"flex"}}>
    <div className="text-dark">Task Done :</div>
    {cards.task_detail}
  </span>

</div>

                <div className={styles.column3}>
                  <span>Reporting to : </span> <h6>{cards.project_leader} </h6>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default DailyTasks;
