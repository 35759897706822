import React, { useEffect ,useState} from "react";
import styles from "./Projectcard.module.css";
import { useAuth, useUserContext } from "../../Context/context";
import axios from "axios";
import Select from "react-select";
import { Link } from "../utils/link";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import f1 from "../images/f1.jpg";
import f2 from "../images/f2.jpg";
import f3 from "../images/f3.jpg";
import m1 from "../images/m1.jpg";
import m2 from "../images/m2.jpg";
import m3 from "../images/m3.jpg";
import m4 from "../images/m4.jpg";
import m5 from "../images/m5.jpg";
import Loader from "../Loader/Loader";

const contributorImages = {
  f1,
  f2,
  f3,
  m1,
  m2,
  m3,
  m4,
  m5
};

function ProjectCard() {
  const [selectedId ,setSelectedId]=useState([])
  const [project, setProject] = useState([]);
  const navigate =useNavigate()
  const [dropdownData, setDropdownData] = useState({});
  const [firstDropdown, setFirstDropdown] = useState([]);
  const [secondDropdown, setSecondDropdown] = useState([]);
  const [selectedFirst, setSelectedFirst] = useState(null);
  const [selectedSecond, setSelectedSecond] = useState(null);
  const [loading, setLoading] = useState(false); // Add loading state
  
  //fetch data from api
  useEffect(() => {
    setLoading(true);
    const getProject = async () => {
      try {
        const res = await axios.get(Link+"/projects/json", {
          withCredentials: true,
        });

        console.log("project:", res.data); // Log res.data instead of res
        setProject(res.data);
      } catch (error) {
        console.log("failed");
      } finally {
        setLoading(false);
      }
    };
    getProject();
  }, []);

  // // Fetch dropdown data
  // useEffect(() => {
  //   const getDropdownData = async () => {
  //     try {
  //       const res = await axios.get("http://localhost:5000/admin/projects/create/json", {
  //         withCredentials: true,
  //       });
  //       console.log("dropdownData:", res.data);
  //       setDropdownData(res.data);
  //       setFirstDropdown([
  //         { value: 'interns', label: 'Interns' },
  //         { value: 'managers', label: 'Managers' },
  //         { value: 'others', label: 'Others' },
  //       ]);
  //     } catch (error) {
  //       console.log("failed to fetch dropdown data");
  //     }
  //   };
  //   getDropdownData();
  // }, []);
  
const onClicking=(id)=>{
     navigate('/projectdashboard/'+id)
     console.log(id)
 
}

  return (
    <>
      <div className={styles.project}>
        <div className={styles.flex}>
          <h2>Projects</h2>
        </div>
        {/* Project Card */}
        <div className="row">
          {loading ? ( <Loader/>) : (project.map((projectItem) => (
            <div
              className="col me-1 ms-1 position-relative"
              key={projectItem._id}
              onClick={()=>{onClicking(projectItem._id)}}
              style={{cursor:"pointer"}}
            >
     
                <div className={`card mb-2 mt-2 ${styles.gradientbg}`}>
                  <div className="card-body text-secondary overflow-auto">
                    <span
                      className={`badge ${
                        projectItem.is_leader ? "bg-success" : "bg-primary"
                      } mt-3 mb-4`}
                    >
                      {projectItem.is_leader ? "Leader" : "Contributor"}
                    </span>
                    <div className={styles.imageWrapper}>
                    <div className={styles.imageContainer}>
                      {projectItem.contributors.slice(0, 3).map((contributor, index) => (
                        <img
                          key={index}
                          src={contributorImages[contributor]}
                          alt="Avatar"
                          className={styles.profileImage}
                        />
                      ))}
                      {projectItem.contributors.length > 3 && (
                        <div className={styles.extraImages} height="25px">
                          +{projectItem.contributors.length - 3}
                        </div>
                      )}
                    </div>
                    </div>
                    <h5 className="card-title text-dark fw-bold fs-3 mb-2">
                      {projectItem.name}
                    </h5>
                    <div className="card-text mb-2">
                      <h5 className={`card-title text-success ${styles.dates}`}>
                        Start Date:
                        <span className={styles.dateText}>
                          {projectItem.start_date}
                        </span>
                      </h5>
                      <h5
                        className={`card-title text-success mb-2 ${styles.dates}`}
                      >
                        End Date:
                        <span className={styles.dateText}>
                          {projectItem.end_date}
                        </span>
                      </h5>
                      <h5
                        className={`card-title text-secondary fs-6 mb-2 ${styles.dates}`}
                      >
                        Project Lead:
                        {projectItem.project_lead}
                      </h5>
                      <h5
                        className={`card-title text-secondary fs-6 mb-5 ${styles.dates}`}
                      >
                        Team Lead:
                        {projectItem.tech_lead}
                      </h5>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="text-start text-secondary">{projectItem.progress}%</div>
                      <div className="text-end text-secondary">
                        {projectItem.date_diff} Days Left
                      </div>
                    </div>

                    <div className="progress mb-3">
                      <div
                        className={`progress-bar ${
                          projectItem.is_leader ? "bg-success" : "bg-primary"
                        }`}
                        role="progressbar"
                        style={{ width: `${projectItem.progress}%` }}
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
           
            </div>
          )))}
        </div>
      </div>
    </>
  );
}
export default ProjectCard;
