// ReminderModal.js
import React, { useState, useEffect } from "react";
import "./SetReminderModal.css";
import axios from "axios";
import { Link } from "../utils/link";
import { useAuth} from "../../Context/context";

function ReminderModal({ isOpen, onClose }) {
  const [selectedDays, setSelectedDays] = useState([]);
  const [accData, setAccData] = useState(null); // Initialize as null
  const { isAdmin } = useAuth();

  // to get full name
  const getFullDayName = (abbr) => {
    switch (abbr) {
      case "M":
        return "monday";
      case "Tu":
        return "tuesday";
      case "W":
        return "wednesday";
      case "Th":
        return "thursday";
      case "F":
        return "friday";
      case "Sa":
        return "saturday";
      case "Su":
        return "sunday";
      default:
        return "";
    }
  };
  const handleDayClick = (day) => {
    setSelectedDays((prevSelectedDays) => {
      if (prevSelectedDays.includes(day)) {
        return prevSelectedDays.filter((d) => d !== day);
      } else {
        return [...prevSelectedDays, day];
      }
    });
  };
  useEffect(() => {
    async function fetchData() {
      try {
        const url = isAdmin
        ? "/account/json/api"
        : "/account/json";
      const response = await axios.get(Link + url, {
        withCredentials: true,
      });
        setAccData(response.data); // Set state with response data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);
  const handleSubmit = async () => {
    if (!selectedDays.length) {
      console.error("No days selected");
      return;
    }

    const fullDays = selectedDays.map((day) => getFullDayName(day));

    try {
      const response = await axios.post(
        Link + "/account/set_reminder",
        {
          id: accData.empID,
          days: fullDays,
        },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        console.log("Reminder set successfully");
        onClose(); // Close the modal
      } else {
        console.error("Failed to set reminder");
      }
    } catch (error) {
      console.error("Error setting reminder:", error);
    }
  };

  return (
    <div className={isOpen ? "modal open" : "modal"}>
      <div className="modal-content">
        <h4>Set Reminder</h4>
        <div className="days-container">
          {["M", "Tu", "W", "Th", "F", "Sa","Su"].map((day, index) => (
            <div
              key={index}
              className={selectedDays.includes(day) ? "day selected" : "day"}
              onClick={() => handleDayClick(day)}
            >
              {day}
            </div>
          ))}
        </div>
        <div className="button-container">
          <button
            onClick={handleSubmit}
            style={{ backgroundColor: "var(--primary)", color: "white" }}
          >
            Submit
          </button>
          <button
            onClick={onClose}
            style={{ backgroundColor: "var(--cancel)", color: "white" }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default ReminderModal;
