import React, { useState, useRef, useEffect } from "react";
import styles from "./AdminDashboard.module.css";
import * as fa6 from "react-icons/fa6";
import Chart from "chart.js/auto";
import * as fa from "react-icons/fa";
import { getFromAPI } from "../utils/utils";
import axios from "axios";
import { Link } from "../utils/link";
import { useAuth, useUserContext } from "../../Context/context.js";
function AdminDashboard() {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const activeUsersRef = useRef(null);
  //const { user } = useUserContext();
  const {isAdmin ,isUser} =useAuth()
  const inactiveUsersRef = useRef(null);
  const absentUsersRef = useRef(null);
  const [adminData, setadminData] = useState([]);
  const [adminGraphData,setAdminGraphData]=useState([]);
  const [project,setProject] =  useState([]);
console.log("admin is"+isAdmin)
console.log("user is+"+isUser)
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(Link+"/admin/dashboard/json",{withCredentials:true});
        // const response = await axios.get(`${Link}/admin/get_json`);
        //console.log(response);
       // console.log(user)
        //console.log("dfddffdsfds")
        setadminData(response.data);
       // console.log("sucess admin")
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
    fetchGraphData();
  }, []);
 
    async function fetchGraphData() {
      try {
        const res = await axios.get(Link+"/admin/cal_working_hour/json",{withCredentials:true});
        console.log(res);
        setAdminGraphData(res.data)
   
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
   
  
  //console.log(adminData);
  //console.log(adminData.active_users_no)

  const handleScrollToActiveUsers = () => {
    activeUsersRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const handleScrollToInActiveUsers = () => {
    inactiveUsersRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const handleScrollToAbsentUsers = () => {
    absentUsersRef.current.scrollIntoView({ behavior: "smooth" });
  };
 

    useEffect(() => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    
      const ctx = chartRef.current.getContext("2d");
    
      // Extracting data from AdminData
      const labels = adminGraphData?.map(data => data.name);
      const totalHoursData = adminGraphData?.map(data => data.break_hrs + data.working_hrs);
      const workingHoursData = adminGraphData?.map(data => data.working_hrs);
    
      chartInstance.current = new Chart(ctx, {
        type: "bar",
        data: {
          labels: labels,
          datasets: [
            {
              label: "Total Hours",
              backgroundColor: "#59a6ff88",
              borderColor: "#59a6ff",
              borderWidth: 1,
              data: totalHoursData,
            },
            {
              label: "Working Hours",
              backgroundColor: "#ff6d8580",
              borderColor: "#ff6d85",
              borderWidth: 1,
              data: workingHoursData,
            },
          ],
        },
        options: {
          scales: {
            y: {
              min: 0,
              max: 12,
              stepSize: 1,
            },
          },
        },
      });
    
      return () => {
        if (chartInstance.current) {
          chartInstance.current.destroy();
        }
      };
    }, []); // Make sure to include AdminData in the dependencies array

      //fetch project data from api
  useEffect(() => {
    const getProject = async () => {
      try {
        const res = await axios.get(
          Link + "/admin/projects/view/json",
          {
            withCredentials: true,
          }
        );

        console.log("project:", res.data); // Log res.data instead of res
        setProject(res.data);
      } catch (error) {
        console.log("failed");
      }
    };
    getProject();
  }, []);
  return (
    <>
 
      <div className={styles.dashboard}>
        <h2>Admin Dashboard</h2>

        {/* users summary  */}
        <div class="row">
          <div class="col-sm-3">
            <div onClick={handleScrollToActiveUsers}>
              <div class={`card mb-2 mt-2 border-light shadow-sm  ${styles.gradientb}`}>
                <div class="card-body overflow-auto overflow-auto">
                  <h5 class={styles.head}>Active Employee</h5>
                  <div className="row">
                    <div className="col-sm-3 mt-2 mb-2">
                      <div
                        className={styles.icon}
                        style={{ backgroundColor: "var(--greencard)" }}
                      >
                        <fa6.FaUserLarge />
                      </div>
                    </div>
                    <div className="col-sm-9 mt-2 mb-2">
                      <h6>{adminData.active_users_no} Active Employees</h6>
                      <p class="card-text">{(((adminData.active_users_no)/(adminData.total_emp_no))*100).toFixed(2)}%</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div onClick={handleScrollToInActiveUsers}>
              <div class={`card mb-2 mt-2 border-light shadow-sm  ${styles.gradientb}`}>
                <div class="card-body overflow-auto overflow-auto">
                  <h5 class={styles.head}>Inactive Employee</h5>
                  <div className="row">
                    <div className="col-sm-3 mt-2 mb-2">
                      <div
                        className={styles.icon}
                        style={{ backgroundColor: "var(--redcard)" }}
                      >
                        <fa6.FaUserSlash />
                      </div>
                    </div>
                    <div className="col-sm-9 mt-2 mb-2">
                      <h6>{adminData.inactive_users_no} Inactive Employees</h6>
                      <p class="card-text">{(((adminData.inactive_users_no)/(adminData.total_emp_no))*100).toFixed(2)}%</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class={`card mb-2 mt-2 border-light shadow-sm  ${styles.gradientb}`}>
              <div class="card-body overflow-auto">
                <h5 class={styles.head}>Outside Office</h5>
                <div className="row">
                  <div className="col-sm-3 mt-2 mb-2">
                    <div
                      className={styles.icon}
                      style={{ backgroundColor: "var(--bluecard)" }}
                    >
                      <fa6.FaBuildingUser />
                    </div>
                  </div>
                  <div className="col-sm-9 mt-2 mb-2">
                    <h6>{adminData.outside_working_no} Outside Office</h6>
                    <p class="card-text">{((adminData.outside_working_no/(adminData.total_emp_no))*100).toFixed(2)}%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div onClick={handleScrollToAbsentUsers}>
              <div class={`card mb-2 mt-2 border-light shadow-sm  ${styles.gradientb}`}>
                <div class="card-body overflow-auto">
                  <h5 class={styles.head}>Absent Employees</h5>
                  <div className="row">
                    <div className="col-sm-3 mt-2 mb-2">
                      <div
                        className={styles.icon}
                        style={{ backgroundColor: "var(--yellowcard)" }}
                      >
                        <fa6.FaUserXmark />
                      </div>
                    </div>
                    <div className="col-sm-9 mt-2 mb-2">
                      <h6>{adminData.absent_users_no} Absent Employees</h6>
                      <p class="card-text">{(((adminData.absent_users_no)/(adminData.total_emp_no))*100).toFixed(2)}%</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {/* working hrs & on going project  */}
        <div className="row">
          <div className="col-sm-9">
            <div className={`card mb-2 mt-2  ${styles.gradientbg}`}>
              <div className="card-body overflow-auto">
                <div className={styles.head}>Working Hours</div>
                <canvas ref={chartRef} />
              </div>
            </div>
          </div>
          <div className="col-sm-3 ">
            <div className={`card mb-2 mt-2  ${styles.gradientbg}`} style={{ overflowY: "auto" }}>
              <div className="card-body overflow-auto">
                <div className={styles.head}>Ongoing Project</div>
                {project?.map((items,index)=>(
                <div className={styles.row} key={index}>
                  <div className="row">
                    <div className="col-sm-2 mt-2 mb-2" style={{flexDirection: "row",display: "flex" ,width: "100%"}}>
                      <div className={styles.project}> {index+1}</div>
                      <div
                        className={styles.status}
                        style={{ color: "var(--bluecard)" }}
                      >
                        {items.name} 
                      </div>
                    </div>
                   
                  </div>
                </div>
                 ))
                }
              </div>
            </div>
          </div>
        </div>

        {/* active user table  */}
        <div className="row">
          <div className="col-sm-12 ">
            <div ref={activeUsersRef}>
              <div
                class="card mb-2 mt-2 border-light shadow mb-2 mt-2"
                style={{ overflowX: "auto" }}
              >
                <div class="card-body overflow-auto">
                  <div className={styles.head}>Active User</div>

                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Check-in</th>
                        <th>Check-out</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* Placeholder rows */}
                      {adminData.active_users_data?.map((items,index)=>(

                      <tr key={index}>
                        <td>
                          <fa.FaWifi />
                        </td>
                        <td>{items.name}</td>
                        <td>{items.email}</td>
                        <td>{items.in} </td>
                        <td>05:00 PM</td>
                        <td>
                          <span
                            style={{
                              backgroundColor: "var(--greenbtn)",
                              borderRadius: "5%",
                              paddingTop: "2%",
                              paddingBottom: "2%",
                              paddingLeft: "5%",
                              paddingRight: "5%",
                              color: "white",
                            }}
                          >
                            Ontime
                          </span>
                        </td>
                      </tr>
                      ))
                    }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
           {/* present user table  */}
           <div className="row">
          <div className="col-sm-12 ">
            <div ref={activeUsersRef}>
              <div
                class="card mb-2 mt-2 border-light shadow mb-2 mt-2"
                style={{ overflowX: "auto" }}
              >
                <div class="card-body overflow-auto">
                  <div className={styles.head}>Present User</div>

                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Check-in</th>
                        <th>Check-out</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* Placeholder rows */}
                      {adminData.present_users?.map((items,index)=>(

                      <tr key={index}>
                        <td>
                          <fa.FaWifi />
                        </td>
                        <td>{items.name}</td>
                        <td>{items.email}</td>
                        <td>{items.in} </td>
                        <td>{items.breakTime}</td>
                        <td>
                          <span
                            style={{
                              backgroundColor: "var(--greenbtn)",
                              borderRadius: "5%",
                              paddingTop: "2%",
                              paddingBottom: "2%",
                              paddingLeft: "5%",
                              paddingRight: "5%",
                              color: "white",
                            }}
                          >
                            Ontime
                          </span>
                        </td>
                      </tr>
                      ))
                    }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* inactive & absent user table  */}
        <div className="row">
          <div className="col-sm-6">
          <div ref={inactiveUsersRef}>
            <div
              className={`card mb-2 mt-2  ${styles.gradientbg2}`}
              style={{ overflowX: "auto" }}
            >
              <div className="card-body overflow-auto">
                <div className={styles.head}>Inactive User</div>
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {adminData.inactive_users_data?.map((index,items)=>(

                      <tr key={index}>
                      <td>{index.fname}</td>
                      <td>{index.email}</td>
                    </tr>
                      ))}
                  
                  </tbody>
                </table>
              </div>
            </div>
            </div>
          </div>
          <div className="col-sm-6">
          <div ref={absentUsersRef}>
            <div
              className={`card mb-2 mt-2  ${styles.gradientbg2}`}
             
            >
              <div className="card-body overflow-auto">
                <div className={styles.head}>Absent User</div>
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {adminData.absent_users_data?.map((index)=>(


                    <tr key={index}>
                      <td>{index.fname} {index.lname}</td>
                      <td>{index.email}</td>
                    </tr>
                    ))
                                        }
                  
                  </tbody>
                </table>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    
    </>
  );
}
export default AdminDashboard;
