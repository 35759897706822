import React, {  useEffect, useState } from "react";
import styles from "./Notification.module.css";
import * as FaIcons from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Link } from "../utils/link";
import { useAuth, useUserContext } from "../../Context/context.js";

const AdminNotification = () => {
  const [seenPopout, setSeenPopOut] = useState(false);
  //calls the usercontext 
 // const { user } = useUserContext();
  //console.log(user)
  const {isAdmin ,isUser}=useAuth()
  //   state which handle the open and show of add form on screen
  const [addForm, setaddForm] = useState(false);
  //state which handle notification list shown 
  const [noti, setNoti] = useState([]);
  //state which store id on selecting particular notification
  const [selectedId, setSelectedId] = useState([]);
// state which handle the form input data 
  const [formData, setFormData] = useState({
    subject: "",
    content: "",
  });
  console.log(noti,"thihihi")
  //console.log("this is notification data"+noti)
 // console.log(isAdmin)
  // console.log(formData);

  //this renders the notification list which is shown on the screen
  useEffect(() => {
    getNotifications();
    // console.log({selectedId})
  }, []);

  // this handlees the pop out msg on clicking on the notification
  const showSeenPopOut = (id,dateTime,notification,subject) => {
    setSeenPopOut(!seenPopout);
    setSelectedId([id,dateTime,notification,subject]);

 
  };

  //this handle on changing the input field and storing them in state assign 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // this fetchs the api of all notification list 
  const getNotifications = async () => {
    try {
      const res = await axios.get(Link + "/admin/notifications/json",{withCredentials:true});

      console.log("this is get data:"+res.data);
      setNoti(res.data);
     // console.log("this is selected data"+selectedId)
     // console.log("success");
    } catch (error) {
      console.log("failed");
    }
  };

  //this post the formdata on the post api and add notification on clicking submit
  const addNotification = async (e) => {
    e.preventDefault();
    try {
      const form = new FormData();
      form.append("subject", formData.subject);
      form.append("content", formData.content);
      const res = await axios.post(
        Link + "/admin/notifications/create/endpoint",
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setaddForm(!addForm)
      getNotifications()
      toast.success("Notification Added Successfully!");
      
      //console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

const handleDeleteNotification =async(id)=>{
  //console.log(id)
const res =  await axios.get(`${Link}/admin/notifications/delete/api?id=`+id)
//console.log(res.data)
//console.log(selectedId)
//window.location.reload()
getNotifications()
toast.success("Notification Deleted Successfully!");
}

  //this handle of showing the add form on clicking the button
  const showAddForm = () => {
    setaddForm(!addForm);
  };

  return (
   
    <div className={styles.container}>
      <div className={styles.flex}>
        {/* header section  */}
        <h2>Notifications</h2>
        <button className={styles.button} onClick={showAddForm}>
             + Add Notification
           </button>
      
      </div>

   
   {/* for add notification form  */}
      <div className={addForm ? "modal open" : "modal"}>
         <div className={styles.modalcontent}>
           <h4>Add Notification</h4>
           <form>
             <h6>Subject</h6>
             <input
              type="text"
              name="subject"
              placeholder=" Enter Subject"
              onChange={handleChange}
              required
            />
            <h6>Announcement</h6>
            <textarea
              id="description"
              name="content"
              rows="5"
              cols="50"
              onChange={handleChange}
              placeholder="Enter your announcement here..."
              required
            ></textarea>

            <div className="button-container">
              <button type="button"
                onClick={addNotification}
                style={{ backgroundColor: "var(--primary)", color: "white" }}
              >
                Submit
              </button>
              <button
              type="button"
                onClick={showAddForm}
                style={{ backgroundColor: "var(--cancel)", color: "white" }}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
       
      

    

        {/* this is code which maps all the notification on card */}
      <div className={styles.notificationList}>
        {noti?.map((items, index) => (
          <div
            key={items.id}
            className={styles.notification}
            
          >
            <div onClick={() => showSeenPopOut(items.id,items.dateTime,items.notification,items.subject)}>

            <span className="avatar">
              <FaIcons.FaRegUserCircle />
            </span>

            <div className={styles.senderInfo}>
              <div className={styles.senderName}>Admin</div>
            </div>
            <div className={styles.notificationHeader}>{items.subject}</div>
            <div className={styles.notificationHeader}>{items.notification}</div>
            <div className={styles.notificationContent}></div>
            <div className={styles.notificationTime}>{items.dateTime}</div>
            </div>
            <div className={styles.notificationAdminDeleteContainer}>
                     <div className={styles.seenBy}>Seen By :
               
                      {items.readBy} 
                     
                     </div>
                    <span className={styles.notificationDeleteIcon} onClick={()=>handleDeleteNotification(items.id)}>
                <FaIcons.FaRegTrashAlt />
                </span>
                  </div>
       
          </div>
          
        ))}
     
      </div>
      <ToastContainer />
    </div>
  );
};

export default AdminNotification;
