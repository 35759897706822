import React, { useRef, useEffect, useState } from "react";
import Chart from "chart.js/auto";
import axios from "axios";
import { Link } from "../utils/link";

const MultiLineChart = ({ projectId }) => {
  const [adminGraphData, setAdminGraphData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAdminGraphData = async () => {
      try {
        const res = await axios.post(
          Link + "/projects/details/dash/epic_progress",
          { project_id: projectId },
          { withCredentials: true }
        );
        console.log("Full Response:", res.data);

        const { dates, progress } = res.data;
        setAdminGraphData({ dates, progress });
      } catch (error) {
        console.error("Failed to fetch admin graph data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAdminGraphData();
  }, [projectId]);

  const chartRef = useRef(null);
  const chartInstance = useRef();

  useEffect(() => {
    if (!adminGraphData) {
      return; // Data is not ready yet, do nothing
    }

    const ctx = chartRef.current.getContext("2d");

    const labels = adminGraphData.dates; // Dates array
    const dataObject = adminGraphData.progress; // Progress object

    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const datasets = Object.keys(dataObject).map((key, index) => {
      const color = `hsl(${index * 50}, 70%, 50%)`; // Generate different colors
      return {
        label: key,
        borderColor: color,
        backgroundColor: color,
        data: dataObject[key],
        fill: false,
      };
    });

    chartInstance.current = new Chart(ctx, {
      type: "line",
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: {
        plugins: {
          legend: {
            position: 'right',
          }
        }
      }
    });

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [adminGraphData]);

  if (loading) {
    return <div>Loading...</div>; // Display a loading indicator while fetching data
  }

  if (!adminGraphData || !adminGraphData.dates || !adminGraphData.progress) {
    return <div>No data available</div>; // Handle no data case
  }

  return <canvas ref={chartRef} />;
};

export default MultiLineChart;
