import React, { useState } from "react";
import styles from "./password.module.css";
import { useAuth } from "../../Context/context";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "../utils/link";

function Password() {
  const { setAdmin, setUser, isAdmin, isUser } = useAuth();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  
  
  //this navigates and direct to the page after login

  //this navigates and direct to the page after login
  const navigate = useNavigate();

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  // on submitting the form, this event occurs
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if the passwords match
    if (newPassword !== confirmPassword) {
        setError("Passwords do not match");
        toast.error("Passwords do not match");
        return;
    }

    try {
        const formData = new FormData();
        formData.append("conf_new_pwd", newPassword);

        // Send the POST request to the API
        const response = await axios.post(
            Link+"/auth/reset_password/json",
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                withCredentials: true,
            }
        );
         console.log(response.status)
        // Handle success response
        if (response.status == 200) {
            toast.success("Password reset successfully!");
            navigate("/"); // Redirect to the home page or login page
        } else {
            toast.error("Error Resetting password");
        }
    } catch (error) {
        setError("Failed to reset password");
        toast.error("Failed to reset password");
    }
};


  return (
    <div className={styles.login}>
      <div className={styles.container}>
        <div className={styles["screen-1"]}>
          <div className={styles.texthead}>Password Reset</div>
          <form onSubmit={handleSubmit}>
            <div className={styles.password}>
              <label htmlFor="newPassword">New Password</label>
              <div className={styles.email}>
                <div className={styles["sec-2"]}>
                  <input
                    type="password"
                    id="newPassword"
                    placeholder="Enter new password"
                    value={newPassword}
                    onChange={handleNewPasswordChange}
                  />
                </div>
              </div>
            </div>
            <div className={styles.password}>
              <label htmlFor="confirmPassword">Confirm New Password</label>
              <div className={styles.email}>
                <div className={styles["sec-2"]}>
                  <input
                    type="password"
                    id="confirmPassword"
                    placeholder="Re-enter new password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                  />
                </div>
              </div>
            </div>
            <button type="submit" className={styles.login}>
              Submit
            </button>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Password;
