import React, { useState, useEffect } from "react";
import styles from "./Epic.module.css";
import { IoIosAddCircle, IoMdMenu } from "react-icons/io";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "../utils/link";
import Loader from "../Loader/Loader";
import { FaRegTrashAlt } from "react-icons/fa";
import { MdOutlineModeEdit } from "react-icons/md";

function Epic({ id }) {
  const [editEpic, setEditEpic] = useState(false);
  const [addEpic , setAddEpic]=useState(false)
  const [reassignModal, setReassignModal] = useState(false);
  const [addForm, setAddForm] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [fetchData, setFetchData] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [selectedProjId, setSelectedProjId] = useState("");
  const [selectedEpicId, setSelectedEpicId] = useState("");
  const [selectedTaskId, setSelecteedTaskId] = useState("");
  const [reassignedTo, setReassignedTo] = useState("");
  const [assignedAs, setAssignedAs] = useState("");
  const [loading, setLoading] = useState(false); // Add loading state
  const [indexEpic ,setIndexEpic] =  useState(0)
  const [editEpicTitle, setEditEpicTitle] = useState("");
  const [editEpicDescription, setEditEpicDescription] = useState("");
  const [editEpicStartDate, setEditEpicStartDate] = useState("");
  const [editEpicEndDate, setEditEpicEndDate] = useState("");
  const [editTaskAssignedTo ,setEditTaskAssignedTo]=useState("")
  const [editTaskAssignedAs ,setEditTaskAssignedAs]=useState("")
  const [editTaskPriority ,setEditTaskPriority]=useState("")
 const [editTaskTitle ,setEditTaskTitle]=useState("")
 const [editTaskStartDate ,setEditTaskStartDate]=useState("")
 const [editTaskEndDate ,setEditTaskEndDate]=useState("")
 const [editFormModal ,setEditFormModal] = useState("")
 const [initialTeam, setInitialTeam] = useState([]);
 const [editEpicContris, setEditEpicContris] = useState([]);
 const [deleteEpicModal, setDeleteEpicModal] = useState(false);
 const [initialAssigneeTO, setInitialAssigneeTO] = useState("");
 const [initialAssignedAs, setInitialAssignedAs] = useState("");
  const renderField = (field) =>
    field === "" || field == null ? "None" : field;
  const styleIfNone = (field) => (field === "None" ? { opacity: 0.5 } : {});
  const statusMapping = {
    0: "Medium",
    1: "Urgent",
    "-1": "Low",
  };

  const [storeData, setStoreData] = useState({
    epicID: "",
    taskName: "",
    startDate: "",
    endDate: "",
    assignedTo: "",
    assignedAs: "",
    assignedBy: "",
    projectId: "",
    priority: null,
  });

  const [epicForm, setEpicForm] = useState({
    project_id: '',
    title: '',
    description: '',
    start_date: '',
    end_date: '',
    contris: []
  });
  console.log(fetchData);

  //Add task api
  const handleAddTask = async (e) => {
    try {
      const formData = new FormData();
      formData.append("epic_id", selectedEpicId);
      formData.append("task_name", storeData.taskName);
      formData.append("assignedBy", selectedId);
      formData.append("assignee", storeData.assignedTo);
      formData.append("start_date", storeData.startDate);
      formData.append("end_date", storeData.endDate);
      formData.append("assignedAs", storeData.assignedAs);
      formData.append("priority", storeData.priority);

      console.log("Form data:", Object.fromEntries(formData.entries()));

      const res = await axios.post(
        Link + "/admin/projects/details/task/api",
        formData,
        { withCredentials: true }
      );
    
      setAddForm(!addForm);
      handleFetchData();
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong, Please Try Again");
    }
  };

  //epic changes storing state function
  const handleEpicChange = (e) => {
    const { name, value, type, checked } = e.target;
  
  
    if (type === 'checkbox') {
      console.log('Checkbox changed:', name, value, checked);
  
      if (checked) {
        console.log('Checkbox checked:', value);
        setEpicForm((prevState) => ({
          ...prevState,
          contris: [...prevState.contris, value],
        }));
      } else {
        console.log('Checkbox unchecked:', value);
        setEpicForm((prevState) => ({
          ...prevState,
          contris: prevState.contris.filter((id) => id !== value),
        }));
      }
    } else {
      setEpicForm((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  //delete epic controller
  const showDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  //delete epic modal controller
  const showDeleteEpic = (eId) => {
    setDeleteEpicModal(!deleteEpicModal);
    setSelectedEpicId(eId);
  };

  //add epic modal controller
  const showAddEpic =(projId)=>{
    setSelectedProjId(projId);
    setAddEpic(!addEpic)
   }
  
 //edit epic modal controller
 const showEditEpic = (
  index,
  projId,
  epicId,
  tName,
  tDescription,
  tStartDate,
  tEndDate,
  tContris,
  pContris
) => {
  setIndexEpic(index);
  setEditEpicTitle(tName);
  setEditEpicStartDate(tStartDate);
  setInitialTeam(pContris);
  setEditEpicDescription(tDescription);
  setEditEpicEndDate(tEndDate);
  setEditEpicContris(tContris);
  setSelectedProjId(projId);
  setSelectedEpicId(epicId);
  setEditEpic(!editEpic);
};

  //delete epic api
  const handleDeleteEpic = async () => {
    try {
      const res = await axios.post(
        Link + "/projects/details/epic/delete",
        { epic_id: selectedEpicId },
        { withCredentials: true }
      );
      console.log(res.data);
      setDeleteEpicModal(!deleteEpicModal);
      toast.success("Epic Deleted Successfully!");
      handleFetchData();
    } catch (error) {
      toast.error("Something Went Wrong, Please Try Again");
      console.log(error);
    }
  };

  //add modal controller
  const showAddForm = (id, projId, epicId) => {
    setSelectedId(id);
    setSelectedProjId(projId);
    setSelectedEpicId(epicId);
    setAddForm(!addForm);
  };


  // edit modal controller 
  const showEditForm =(eId , tName ,tStartDate ,tEndDate ,tPriority ,tAssignedAs ,tAssignee ,tIndex)=>{
    setEditTaskStartDate(tStartDate)
    setEditTaskEndDate(tEndDate)
    setIndexEpic(tIndex)
    setEditTaskAssignedAs(tAssignedAs)
    setEditTaskAssignedTo(tAssignee)
    setEditTaskPriority(tPriority)
    setEditTaskTitle(tName)
    setSelectedEpicId(eId)
    setEditForm(!editForm)

  }

  //reassigne modal controller
  const showReaasignModal = (iAssignee, iAssigneesAs) => {
    setInitialAssigneeTO(iAssignee);
    setInitialAssignedAs(iAssigneesAs);
    setReassignModal(!reassignModal);
  };

  //edit task api 
  const handleEditTask = async () => {
    try {
      const formData = new FormData();
      formData.append("epic_id", selectedEpicId);
      formData.append("task_name", editTaskTitle);
      formData.append("assignedBy", selectedId);
      formData.append("assignee", editTaskAssignedTo);
      formData.append("start_date", editTaskStartDate);
      formData.append("end_date", editTaskEndDate);
      formData.append("assignedAs", editTaskAssignedAs);
      formData.append("priority", editTaskPriority);

      console.log("Form data:", Object.fromEntries(formData.entries()));

      const res = await axios.post(
        Link + "/admin/projects/details/task/edit/api",
        formData,
        { withCredentials: true }
      );
      console.log(res.data);
      setEditForm(!editForm)
      handleFetchData()
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong, Please Try Again");
    }
  };

  // api for add epic 
  const handleAddEpic =async(e)=>{
  
    e.preventDefault();
  try {
    
    const formData = new FormData();
    formData.append('project_id',  selectedProjId);
    formData.append('title', epicForm.title);
    formData.append('description', epicForm.description);
    formData.append('start_date', epicForm.start_date);
    formData.append('end_date', epicForm.end_date);
    formData.append('team', epicForm.contris);

    console.log('Form data:', Object.fromEntries(formData.entries()));

    const res = await axios.post(Link+"/admin/projects/details/epic/api" ,formData ,{withCredentials:true})
  
    setAddEpic(!addEpic)
    toast.success("New Epic Created successfully!")
    handleFetchData()
  } catch (error) {
    console.log(error)
    toast.error("Something Went Wrong, Please Try Again");
  }
 }

  // Handle change for re-assignee select
  const handleReassignedToChange = (event) => {
    setReassignedTo(event.target.value);
    
  };

  //api for reassigning task
  const handleReassignTask = async () => {
    try {
      const res = await axios.post(
        Link + "/projects/details/task/reassign",
        {
          employee_id: selectedId,
          assign_as: assignedAs,
          assignee_id: reassignedTo,
          task_id: selectedTaskId,
        },
        { withCredentials: true }
      );
      setReassignModal(!reassignModal);
      toast.success("Task Has Been Reassigned!")
      handleFetchData();
    } catch (error) {
      console.log(error);
    }
  };


  // Handle change for assigned as select
  const handleAssignedAsChange = (event) => {
    setAssignedAs(event.target.value);
  };

  //set the change state
  const handleChange = (e) => {
    const { name, value } = e.target;
    setStoreData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  //menu bar modal controller
  const toggleMenu = (taskId, emp_id) => {
    setMenuVisible((prev) => ({
      ...prev,
      [taskId]: !prev[taskId],
    }));
    setSelectedId(emp_id);
    setSelecteedTaskId(taskId);
  };

  useEffect(() => {
    setLoading(true);
    handleFetchData();
  }, []);

  //api for edit epic 
  const handleEditEpic=async()=>{
    try {
      const formData = new FormData();
      formData.append('epic_id',  selectedEpicId);
      formData.append('project_id',  selectedProjId);
      formData.append('title', epicForm.title);
      formData.append('description', epicForm.description);
      formData.append('start_date', epicForm.start_date);
      formData.append('end_date', epicForm.end_date);
      formData.append('team', epicForm.contris);
  
      console.log('Form data:', Object.fromEntries(formData.entries()));
  
    const res = await axios.put(Link+"/admin/projects/details/epic/edit/api",formData ,{withCredentials:true})
    console.log(res.data)
      setEditEpic(!editEpic)
      toast.success("Epic Has Been Edited!")
      handleFetchData()
    } catch (error) {
      console.log(error)
      toast.error("Something Went Wrong, Please Try Again");
    }
  }

  //api for deleting task
  const handleDeleteTask = async () => {
    try {
      const res = await axios.post(
        Link + "/projects/details/task/delete",
        { employee_id: selectedId, task_id: selectedTaskId },
        { withCredentials: true }
      );
      setDeleteModal(!deleteModal);
      toast.success("Task Deleted!")
      handleFetchData();
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong, Please Try Again");
    }
  };


  //api for fetching data
  const handleFetchData = async () => {
    try {
      const res = await axios.get(
        Link + "/projects/details/epics/json?id=" + id,
        { withCredentials: true }
      );
      setFetchData(res.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={styles.Page}>
      { loading ? <Loader/> : "" }
        <div className={styles.Tabletitle}> <span className={styles.Prj_title}>
            {fetchData[0]?.proj_name}
            </span>
{fetchData[0]?.isProjLead? 
        <div className={styles.AdminBtn}>
            <button type="button" onClick={() => showAddEpic(fetchData[0]?.project_contris[0]?.project_id)}> 
             {/* <IoIosAddCircle
                            style={{

                              marginBottom: "3px",
                            }}/> */}
                            + Add Epic</button>
            
            </div>:""}
            
            </div>
        {fetchData.map((item, index) => (
          <div key={index} style={{ borderRadius: "12px" }}>
            {item.epic_data.map((epicItem, epicIndex) => (
              <div className={styles.mainCard} key={epicIndex}>
                <div className={styles.TableHeader}>
                  <span className={styles.ProjectTitle}>
                    {epicItem.epic.name}
                  </span>
                  <div className={styles.HeaderButtonContainer}>
                    <button
                      onClick={() =>
                        showAddForm(
                          item.emp_id,
                          item.project_contris[index].project_id,
                          item.epic_data[index].epic._id
                        )
                      }
                    >
                     
                     + Add task
                    </button>
                     {fetchData[0].isProjLead? 
                     <>
                    <button
                      style={{ marginRight: "15px" }}
                      onClick={() =>
                        showEditEpic(
                          epicIndex,
                          item.project_contris[index].project_id,
                          epicItem.epic._id,
                          epicItem.epic.name,
                          epicItem.epic.description,
                          epicItem.epic.start_date,
                          epicItem.epic.end_date,
                          fetchData[0].project_contris,
                          epicItem.epic.team_names
                        )
                      }
                    >
                      <MdOutlineModeEdit
                        style={{
                          marginRight: "2px",
                          marginBottom: "0px",
                        }}
                      />
                      Edit Epic
                    </button>
                    <button onClick={() => showDeleteEpic(epicItem.epic._id)} style={{marginRight:"5px"}}>
                      <FaRegTrashAlt
                        style={{
                          marginRight: "2px",
                          marginBottom: "0px",
                        }}
                      />
                      Delete Epic
                    </button>
                         </> : ""}
                  </div>
                </div>
                <div
                  className={styles.tableCard}
                  style={{ overflowX: "auto", overflowY: "auto" }}
                >
                  <table>
                    <thead>
                      <tr className={styles.TableHeaderNames}>
                        <td>SR NO:</td>
                        <td>TASK NAME</td>
                        <td>START DATE</td>
                        <td>END DATE</td>
                        <td>PRIORITY</td>
                        <td>ASSIGNED TO</td>
                        <td>ASSIGNED AS</td>
                        <td>REASSIGNED TO</td>
                        <td>REASSIGNED AS</td>
                        <td>REASSIGNED BY</td>
                        <td>TESTER COMMENT</td>
                        <td>ACTION</td>
                      </tr>
                    </thead>
                    <tbody className={styles.TableBody}>
                      {epicItem.tasks.map((task, taskIndex) => (
                        <tr 
                        key={taskIndex} 
                        style={fetchData[0].emp_id === task.reassign_to_id? {backgroundColor:'#efecec' }:   fetchData[0].emp_id === task.assignee_id ? { backgroundColor: '#efecec' } : {} }
                      >
                          <td>{taskIndex + 1}</td>
                         
                          <td>{task.task_name}</td>
                          <td>{task.task_start_date}</td>
                          <td>{task.task_end_date}</td>
                          <td>
                       
                            {statusMapping[task.priority]}
                          </td>
                          <td>
                            {task.assignee === "" ? "None" : task.assignee}
                          </td>

                          <td>{task.assigned_as}</td>
                          <td
                            style={styleIfNone(renderField(task.reassign_to))}
                          >
                            {renderField(task.reassign_to)}
                          </td>
                          <td
                            style={styleIfNone(renderField(task.reassign_as))}
                          >
                            {renderField(task.reassign_as)}
                          </td>
                          <td
                            style={styleIfNone(renderField(task.reassign_by))}
                          >
                            {renderField(task.reassign_by)}
                          </td>
                          <td style={styleIfNone(renderField(task.bugMsg))}>
                            {renderField(task.bugMsg)}
                          </td>

                          <td
                            style={{ fontSize: "10px", position: "relative" }}
                          >
                            <IoMdMenu
                              onClick={() =>
                                toggleMenu(task.taskID, item.emp_id)
                              }
                              style={{
                                cursor: "pointer",
                                color: "#333",
                                fontSize: "24px",
                              }}
                            />
                            {menuVisible[task.taskID] && (
                              <div
                                style={{
                                  // position: "absolute",
                                  top: "30px",
                                  right: "0",
                                  background: "#fff",
                                  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                                  borderRadius: "5px",
                                  minWidth: "150px",
                                  zIndex: 20, // Added z-index
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "stretch",
                                  }}
                                >
                                  {fetchData[0].isProjLead ||fetchData[0].emp_id ==
                                  task.assigned_by_id   ? (
                                    <>
                                      <button
                                        style={{
                                          padding: "10px",
                                          borderBottom: "1px solid #eee",
                                          textAlign: "center",
                                          background: "none",
                                          border: "none",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>showEditForm(epicItem.epic._id ,task.task_name ,task.task_start_date , task.task_end_date , task.priority, task.assigned_as , task.assignee, epicIndex )}
                                >
                                        Edit Task
                                      </button>

                                      <button
                                        style={{
                                          padding: "10px",
                                          textAlign: "center",
                                          background: "none",
                                          border: "none",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => showDeleteModal()}
                                      >
                                        Delete Task
                                      </button>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  { fetchData[0].isProjLead?   <button
                                    style={{
                                      padding: "10px",
                                      borderBottom: "1px solid #eee",
                                      textAlign: "center",
                                      background: "none",
                                      border: "none",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      showReaasignModal(
                                        task.reassign_to == ""
                                          ? task.assignee
                                          : task.reassign_to,
                                        task.reassign_as == ""
                                          ? task.assigned_as
                                          : task.reassign_as
                                      )
                                    }
                                  >
                                    Reassign Task
                                  </button>:  fetchData[0].emp_id=== task.reassign_to_id?    <button
                                    style={{
                                      padding: "10px",
                                      borderBottom: "1px solid #eee",
                                      textAlign: "center",
                                      background: "none",
                                      border: "none",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => showReaasignModal()}
                                  >
                                    Reassign Task
                                  </button>  : fetchData[0].emp_id===task.assignee_id?   <button
                                    style={{
                                      padding: "10px",
                                      borderBottom: "1px solid #eee",
                                      textAlign: "center",
                                      background: "none",
                                      border: "none",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => showReaasignModal()}
                                  >
                                    Reassign Task
                                  </button> : "" }
                               
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>


 {/* Create Epic Modal  */}
 {addEpic && (
        <div className="modal open">
          <div className="modal-content">
            <h4> Create Epic</h4>

            <form >
              <div className="form-group">
                <h6>Epic Title</h6>
                <input
                  type="text"
                  id="title"
                  name="title"
                  required
                  onChange={handleEpicChange}
                />
              </div>
              <div className="form-group">
                <h6>Description</h6>
                <textarea
                  type="text"
                  id="description"
                  name="description"
                  onChange={handleEpicChange}
                  required
                />
              </div>
              <div className="form-group">
                <h6>Start Date</h6>
                <input
                  type="date"
                  id="start_date"
                  name="start_date"
                  onChange={handleEpicChange}
                  required
                />
              </div>
              <div className="form-group">
                <h6>End Date</h6>
                <input
                  type="date"
                  id="end_date"
                  name="end_date"
                  onChange={handleEpicChange}
                  required
                />
              </div>
         
            <div className="form-group">
              <h6>Team :</h6>
              {fetchData[0]?.project_contris.map((addEpicIndex)=>{
                  return(
                    <div className="" key={addEpicIndex}  >
                    <input type="checkbox" id={addEpicIndex.id} name={addEpicIndex.name} value={addEpicIndex.id} required onChange={handleEpicChange}/>
                     <label htmlFor={addEpicIndex.id}>
                      {addEpicIndex.name}
                      </label>
                    </div>
                  )
                })
              }
            </div>
           
              <div className="button-container">
                <button
                  type="button"
                  style={{ backgroundColor: "var(--primary)", color: "white" }}
                   onClick={handleAddEpic}
                >
                  Submit
                </button>
                <button
                type="button"
                  onClick={showAddEpic}
                  style={{ backgroundColor: "var(--cancel)", color: "white" }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )} 

      {/* add task Modal  */}
      {addForm && (
        <div className="modal open">
          <div className="modal-content">
            <h4> Create Task</h4>

            <form>
              <div className="form-group">
                <h6>Task Name</h6>
                <input
                  type="text"
                  id="taskName"
                  name="taskName"
                  onChange={handleChange}
                  
                />
              </div>
              <div className="form-group">
                <h6>Start Date</h6>
                <input
                  type="date"
                  id="startDate"
                  name="startDate"
                  onChange={handleChange}
                  
                />
              </div>
              <div className="form-group">
                <h6>End Date</h6>
                <input
                  type="date"
                  id="endDate"
                  name="endDate"
                  onChange={handleChange}
                  
                />
              </div>
              <div className="form-group">
                <h6>Assigned To</h6>
                <select
                  id="assignedTo"
                  name="assignedTo"
                  onChange={handleChange}
                  
                >
                  {fetchData[0].project_contris?.map((index, items) => (
                    <option value={index.id} key={index}>
                      {index.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <h6>Assigned As</h6>
                <select
                  id="assignedAs"
                  name="assignedAs"
                  onChange={handleChange}
                  
                >
                  <option>Select Designation</option>
                  <option value="developer">Developer</option>
                  <option value="designer">Designer</option>
                  <option value="tester">Tester</option>
                </select>
              </div>
              <div className="form-group">
                <h6>Priority</h6>
                <select id="priority" name="priority" onChange={handleChange} required>
                  <option value={1}>Urgent</option>
                  <option value={0}>Medium</option>
                  <option value={-1}>Low</option>
                </select>
              </div>
              <div className="button-container">
                <button
                  type="button"
                  style={{ backgroundColor: "var(--primary)", color: "white" }}
                  onClick={() => handleAddTask()}
                >
                  Submit
                </button>
                <button
                  onClick={showAddForm}
                  style={{ backgroundColor: "var(--cancel)", color: "white" }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* edit task Modal  */}
      {editForm && (
        <div className="modal open">
          <div className="modal-content">
            <h4> Edit Task</h4>

            <form>
              <div className="form-group">
                <h6>Task Name</h6>
                <input
                  type="text"
                  id="taskName"
                  name="taskName"
                  onChange={handleChange}
                  value={editTaskTitle}
                />
              </div>
              <div className="form-group">
                <h6>Start Date</h6>
                <input
                  type="date"
                  id="startDate"
                  name="startDate"
                  onChange={handleChange}
                  value={editTaskStartDate}
                />
              </div>
              <div className="form-group">
                <h6>End Date</h6>
                <input
                  type="date"
                  id="endDate"
                  name="endDate"
                  onChange={handleChange}
                  value={editTaskEndDate}
                />
              </div>
              <div className="form-group">
                <h6>Assigned To</h6>
                <select
                  id="assignedTo"
                  name="assignedTo"
                  onChange={handleChange}
                  value={editTaskAssignedTo}
                >
                  {fetchData[0].project_contris?.map((index, items) => (
                    <option value={index.id} key={index}>
                      {index.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <h6>Assigned As</h6>
                <select
                  id="assignedAs"
                  name="assignedAs"
                  onChange={handleChange}
                  value={editTaskAssignedAs}
                >
                  <option>Select Designation</option>
                  <option value="developer">Developer</option>
                  <option value="designer">Designer</option>
                  <option value="tester">Tester</option>
                </select>
              </div>
              <div className="form-group">
                <h6>Priority</h6>
                <select id="priority" name="priority" required onChange={handleChange}>
                  <option value={1}>Urgent</option>
                  <option value={0}>Medium</option>
                  <option value={-1}>Low</option>
                </select>
              </div>
              <div className="button-container">
                <button
                  type="button"
                  style={{ backgroundColor: "var(--primary)", color: "white" }}
                  onClick={() => {handleEditTask()}}
                >
                  Submit
                </button>
                <button
                  onClick={showEditForm}
                  style={{ backgroundColor: "var(--cancel)", color: "white" }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* reasssign Modal  */}
      {reassignModal && (
        <div className="modal open">
          <div className="modal-content">
            <h4> Reassign Modal</h4>

            <form>
              <div className="form-group">
                <h6>Select Re-Assignee: </h6>
                <select
                  id="reassignedTo"
                  name="reassignedTo"
                  onChange={handleReassignedToChange}
                  value={initialAssigneeTO}
                >
                
                  {fetchData[0].project_contris?.map((index) => (
                    
                    <option value={index.id} key={index.id}>
                      {index.name}
                    </option>
                  ))}  
                </select>
              </div>

              <div className="form-group">
                <h6>Re-Assign as:</h6>
                <select
                  id="assignedAs"
                  name="assignedAs"
                  onChange={handleAssignedAsChange}
                  value={initialAssignedAs}
                >
                  <option>Select Designation</option>
                  <option value="developer">Developer</option>
                  <option value="designer">Designer</option>
                  <option value="tester">Tester</option>
                </select>
              </div>

              <div className="button-container">
                <button
                  type="button"
                  style={{ backgroundColor: "var(--primary)", color: "white" }}
                  onClick={() => handleReassignTask()}
                >
                  Submit
                </button>
                <button
                  type="button"
                  onClick={showReaasignModal}
                  style={{ backgroundColor: "var(--cancel)", color: "white" }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

        {/* Edit Epic Modal */}
        {editEpic && (
        <div className="modal open">
          <div className="modal-content">
            <h4> Edit Epic</h4>

            <form>
              <div className="form-group">
                <h6>Epic Title</h6>
                <input
                  type="text"
                  id="title"
                  name="title"
                  required
                  value={editEpicTitle}
                  onChange={(e) => {
                    setEditEpicTitle(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <h6>Description</h6>
                <textarea
                  type="text"
                  id="description"
                  name="description"
                  value={editEpicDescription}
                  required
                  onChange={(e) => {
                    setEditEpicDescription(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <h6>Start Date</h6>
                <input
                  type="date"
                  id="start_date"
                  name="start_date"
                  required
                  value={editEpicStartDate}
                  onChange={(e) => {
                    setEditEpicStartDate(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <h6>End Date</h6>
                <input
                  type="date"
                  id="end_date"
                  name="end_date"
                  value={editEpicEndDate}
                  required
                  onChange={(e) => {
                    setEditEpicEndDate(e.target.value);
                  }}
                />
              </div>

              <div className="form-group">
                <h6>Team :</h6>
                {fetchData[0]?.project_contris.map((addEpicIndex) => {
                  return (
                    <div className="" key={addEpicIndex}>
                      <input
                        type="checkbox"
                        id={addEpicIndex.id}
                        name={addEpicIndex.name}
                        onChange={(e) => setEditEpicContris(addEpicIndex.id)}
                      />
                      <label htmlFor={addEpicIndex.id}>
                        {addEpicIndex.name}
                      </label>
                    </div>
                  );
                })}
              </div>

              <div className="button-container">
                <button
                  type="button"
                  style={{ backgroundColor: "var(--primary)", color: "white" }}
                  onClick={handleEditEpic}
                >
                  Submit
                </button>
                <button
                  type="button"
                  onClick={showEditEpic}
                  style={{ backgroundColor: "var(--cancel)", color: "white" }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Delete Modal  */}
      {deleteModal && (
        <div className="modal open">
          <div className="modal-content">
            <form>
              <div className="form-group">
                <h6>Are you sure you want to delete this task : </h6>
              </div>
              <div className="button-container">
                <button
                  type="button"
                  style={{ backgroundColor: "var(--primary)", color: "white" }}
                  onClick={() => handleDeleteTask()}
                >
                  Delete
                </button>
                <button
                  type="button"
                  onClick={showDeleteModal}
                  style={{ backgroundColor: "var(--cancel)", color: "white" }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
         {/* Delete Epic Modal  */}
         {deleteEpicModal && (
        <div className="modal open">
          <div className="modal-content">
            <form>
              <div className="form-group">
                <h6>Are you sure you want to delete this Epic : </h6>
              </div>
              <div className="button-container">
                <button
                  type="button"
                  style={{ backgroundColor: "var(--primary)", color: "white" }}
                  onClick={() => handleDeleteEpic()}
                >
                  Delete
                </button>
                <button
                  type="button"
                  onClick={showDeleteEpic}
                  style={{ backgroundColor: "var(--cancel)", color: "white" }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

       <ToastContainer />
    </>
  );
}

export default Epic;
