import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { IoIosLogOut } from "react-icons/io";
import { BsFillPersonFill } from "react-icons/bs";
import f1 from "../images/f1.jpg";
import f2 from "../images/f2.jpg";
import f3 from "../images/f3.jpg";
import m1 from "../images/m1.jpg";
import m2 from "../images/m2.jpg";
import m3 from "../images/m3.jpg";
import m4 from "../images/m4.jpg";
import m5 from "../images/m5.jpg";
import { useAuth } from "../../Context/context";
import axios from "axios";
import { Link } from "../utils/link";
import "./navavatar.module.css";
import { Dropdown } from "react-bootstrap";

const avatarImages = {
  f1: f1,
  f2: f2,
  f3: f3,
  m1: m1,
  m2: m2,
  m3: m3,
  m4: m4,
  m5: m5,
};

function NavAvatar() {
  const [accData, setAccData] = useState(null);

  const { isAdmin, setAdmin, isUser, setUser } = useAuth();

  const handleLogout = async () => {
    try {
      const res = await axios.get(Link + "/auth/logout/json", {
        withCredentials: true,
      });
      window.location.href = "/";
      setUser(false);
      setAdmin(false);
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const url = isAdmin
          ? "/account/json/api"
          : "/account/json";
        const response = await axios.get(Link+ url, {
          withCredentials: true,
        });
        setAccData(response.data.account_details);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [isAdmin]); // Add isAdmin as a dependency

  return (
    <>
      <Dropdown>
        {accData && (
          <Dropdown.Toggle
            variant="link"
            id="dropdown-basic"
            className="nav-link nav-profile d-flex align-items-center pe-0 dropdown-toggle-white"
          >
            <img
              src={avatarImages[accData.avatarID]}
              width="25"
              alt="Profile"
              className="rounded-circle"
              style={{ marginRight: "10px", height: "25px", marginTop: "12px" }}
            />
          </Dropdown.Toggle>
        )}
        <Dropdown.Menu>
          <Dropdown.Item as={NavLink} to="/accounts">
            <BsFillPersonFill style={{ marginRight: "5px", color: "black" }} />
            Account
          </Dropdown.Item>
          <Dropdown.Item onClick={handleLogout}>
            <IoIosLogOut style={{ marginRight: "5px", color: "black" }} />
            Logout
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

export default NavAvatar;
