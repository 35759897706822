import React, { useRef, useEffect, useState } from "react";
import Chart from "chart.js/auto";
import axios from 'axios';
import { Link } from "../utils/link";

const StackedBarGraph = ({ projectId }) => {
  const [adminGraphData, setAdminGraphData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.post(Link + "/projects/details/dash/employee_tasks_data", {
          project_id: projectId,
        }, {
          withCredentials: true,
        });
        setAdminGraphData(res.data);
      } catch (error) {
        console.error("Failed to fetch priority data", error);
      }
    };

    fetchData();
  }, [projectId]);

  console.log("stack:", adminGraphData);
  const chartRef = useRef();
  const chartInstance = useRef();

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const ctx = chartRef.current.getContext("2d");

    // Extracting data from adminGraphData
    const labels = adminGraphData.assignee || [];
    const completedTasksData = adminGraphData.completed_tasks || [];
    const totalTasksData = adminGraphData.total_tasks || [];

    chartInstance.current = new Chart(ctx, {
      type: "bar",
      data: {
        labels: labels,
        datasets: [
          {
            label: "Completed Tasks",
            backgroundColor: "#fa5a7e",
            data: completedTasksData,
          },
          {
            label: "Total Tasks",
            backgroundColor: "#3CD757",
            data: totalTasksData,
          },
        ],
      },
      options: {
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      },
    });

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [adminGraphData]);

  return <canvas ref={chartRef} />;
};

export default StackedBarGraph;
