import React, {useEffect, useState } from 'react';
import axios from 'axios';
import './CircularProgressBar.css'; // Import the CSS file
import { Link } from "../utils/link";

const CircularProgressBar = ({ projectId }) => {
  const [percentage, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.post(Link + "/projects/details/dash/project_progress", {
          project_id: projectId,
        }, {
          withCredentials: true,
        });
        setData(res.data.data);
      } catch (error) {
        console.error("Failed to fetch priority data", error);
      }
    };

    fetchData();
  }, [projectId]);
  const radius = 50;
  const strokeWidth = 10;
  const normalizedRadius = radius - strokeWidth * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;


  return (
    <svg height={radius * 5} width={radius * 5} viewBox={`0 0 ${radius * 2} ${radius * 2}`}>
      <circle
        className="progress-bar-bg"
        stroke="#ecedf0"
        fill="transparent"
        strokeWidth={strokeWidth}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      <circle
        className="progress-bar"
        stroke="url(#gradient)" // Use gradient for the stroke color
        fill="transparent"
        strokeWidth={strokeWidth}
        strokeDasharray={`${circumference} ${circumference}`}
        style={{ strokeDashoffset }}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      <defs>
        <linearGradient id="gradient" gradientTransform="rotate(90)">
          <stop offset="0%" stopColor="#6a11cb" />
          <stop offset="100%" stopColor="#2575fc" />
        </linearGradient>
      </defs>
      <text x="50%" y="50%" textAnchor="middle" fill="#000" fontSize="12">
      {Number(percentage).toFixed(2)}%
      </text>
      </svg>

  );
};

export default CircularProgressBar;
