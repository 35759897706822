import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Holi from "../holidays/Holi";
import Login from "../login/Login";
import Dashboard from "../dashboard/Dashboard";
import Navigation from "../navigationbar/Navigationbar2";
import Leave from "../leave/Leave";
import Notification from "../Notification/Notification";
import Employee from "../employee/Employee";
import LeaveRequest from "../leaveRequest/leaveRequest";
import DailyTasks from "../daillyTasks/dailyTasks";
import Account2 from "../Account/Account2";
import Event from "../events/Event";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import Otpverificationemail from "../forgetpassword/Otp";
import Password from "../forgetpassword/Passwordverification";
import Email from "../forgetpassword/Email"
import Footer from "../navigationbar/Footer";
import ProjectCard from "../project/ProjectCard";
import ProjectDashboard from "../project/ProjectDashboard";
import Logs from "../project/Logs";
import Epic from "../Epic/Epic";
import Kanban from "../project/Kanban";
import AdminAttendance from "../attendance/Adminattendance";
import ProjectPage from "../project/ProjectPage";
import AdminProjectCard from "../project/AdminProjectCard";


export default function Indexes() {
  return (
    
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/otp" element={<><Otpverificationemail/></>} />
        <Route path="/password" element={<><Password/></>} />
        <Route path="/Email" element={<Email/>} />
        <Route path="/epics" element={<><Navigation /><Epic /><Footer/></>} />
        <Route path="/kanban" element={<><Navigation /><Kanban /><Footer/></>} />
        <Route path="/holidays" element={<><Navigation /><Holi/><Footer/></>} />
        <Route path="/logs" element={<><Navigation /><Logs /><Footer/></>} />
        <Route path="/projectdashboard/:id" element={<><Navigation /><ProjectPage /><Footer/></>} />
        <Route path="/projects" element={<><Navigation /><ProjectCard /><Footer/></>} />
        <Route path="/attendance" element={<><Navigation /><AdminAttendance /><Footer/></>} />
        <Route path="/dashboard" element={<><Navigation /><Dashboard /><Footer/></>} />
        <Route path="/leaves" element={<><Navigation /><Leave /><Footer/></>} />
        <Route path="/notifications" element={<><Navigation/><Notification/><Footer/></>} />
        <Route path="/accounts" element={<><Navigation/><Account2/><Footer/></>}/>
        <Route path="/events" element={<><Navigation/><Event/><Footer/></>}/>
        <Route path="/admindashboard" element={<><Navigation/><AdminDashboard/><Footer/></>}/>
        <Route path="/leaverequest" element={<><Navigation/><LeaveRequest/><Footer/></>} />
        <Route path="/dailytasks" element={<><Navigation/><DailyTasks/><Footer/></>} />
        <Route path="/adminprojectcard" element={<><Navigation/><AdminProjectCard/><Footer/></>} />
        <Route path="/employee" element={<><Navigation/><Employee/><Footer/></>} />
      </Routes>
    </Router>
  );
}
