import React from "react";

import { useAuth, useUserContext } from "../../Context/context.js";
import UserNotification from "./userNotification";
import AdminNotification from "./adminNotification";
const Notification = () => {

//  // const { user } = useUserContext();
const {isAdmin ,isUser}=useAuth()
  //console.log("admin is"+isAdmin)
 // console.log("user is "+isUser)

  return (<>
  {isAdmin?<AdminNotification/>:<UserNotification/>}
  </>
   
  );
};

export default Notification;
