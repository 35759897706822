import React, { useState, useEffect } from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as BellIcon from "react-icons/bs";
import { Link } from "react-router-dom";
import "./Navigationbar.css";
import { IconContext } from "react-icons";

import NavAvatar from "./NavAvatar";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../Context/context";

function Navigationbar() {
  const [sidebar, setSidebar] = useState(
    window.innerWidth <= 1024 ? false : true
  );
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const iconColor = !screenWidth <= 1024 ? 'var(--primary)' : 'white';

  // const { user } = useUserContext();
  const {isAdmin} =useAuth()
   const userRole = isAdmin ? "admin" : "user";
  //const userRole = "user";
  const showSidebar = () => {
    if (window.innerWidth <= 1024) {
      setSidebar(!sidebar);
    }
  };
  // Add event listener to update sidebar state when window width changes
  useEffect(() => {
    const handleResize = () => {
      setSidebar(window.innerWidth <= 1024 ? false : true);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // const showSidebar = () => setSidebar(true);

  const [headerInfo, setHeaderInfo] = useState({
    greeting: "Hello",
    name: "User",
    day: new Date().toLocaleDateString("en-US", { weekday: "long" }),
    date: new Date().toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    }),
    time: "10:30:22",
  });

  const [noEvent, setNoEvent] = useState(0);
  const [noNoti, setNoNoti] = useState(0);
  const [noHoli, setNoHoli] = useState(0);
  const [accData, setAccData] = useState({
    avatarID: "avatarID",
  });
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleSidebarToggle = () => {
    // Implement sidebar toggle logic
  };
  const SidebarData = [
    {
      title: "Dashboard",
      path: "/dashboard",
      icon: <AiIcons.AiFillHome />,
      cName: "nav-text",
      roles: ["user"], // Only users can access this
    },
    {
      title: "Dashboard",
      path: "/admindashboard",
      icon: <FaIcons.FaTasks />,
      cName: "nav-text",
      roles: ["admin"], // Only admins can access this
    },
    {
      title: "Attendance",
      path: "/attendance",
      icon: <FaIcons.FaUserCheck />,
      cName: "nav-text",
      roles: ["admin"],
    },
    {
      title: "Employees",
      path: "/employee",
      icon: <FaIcons.FaUsers />,
      cName: "nav-text",
      roles: ["admin"], // Only admins can access this
    },
    {
      title: "Leave Requests",
      path: "/leaverequest",
      icon: <IoIcons.IoIosMailOpen />,
      cName: "nav-text",
      roles: ["admin"], // Only admins can access this
    },
    {
      title: "Projects",
      path: "/projects",
      icon: <IoIcons.IoIosPaper />,
      cName: "nav-text",
      roles: ["user"], // Only users can access this
    },
    {
      title: "Projects",
      path: "/adminprojectcard",
      icon: <IoIcons.IoIosPaper />,
      cName: "nav-text",
      roles: ["admin"], // Only admins can access this
    },

    {
      title: "Daily Tasks",
      path: "/dailytasks",
      icon: <FaIcons.FaTasks />,
      cName: "nav-text",
      roles: ["admin"], // Only admins can access this
    },

    {
      title: "Events",
      path: "/events",
      icon: <BellIcon.BsBookmarkFill />,
      cName: "nav-text",
      roles: ["admin", "user"],
    },
    {
      title: "Notifications",
      path: "/notifications",
      icon: <BellIcon.BsBellFill />,
      cName: "nav-text",
      roles: ["admin", "user"],
    },
    {
      title: "Holidays",
      path: "/holidays",
      icon: <FaIcons.FaEnvelopeOpenText />,
      cName: "nav-text",
      roles: ["admin", "user"],
    },
    {
      title: "Leave",
      path: "/leaves",
      icon: <BellIcon.BsCalendarX />,
      cName: "nav-text",
      roles: ["user"], // Only users can access this
    },
    // {
    //   title: "Account",
    //   path: "/accounts",
    //   icon: <BellIcon.BsPersonFill />,
    //   cName: "nav-text",
    //   roles: ["user"], // Only users can access this
    // },
    // {
    //   title: "Logout",
    //   path: "/",
    //   icon: <IoIcons.IoIosLogOut />,
    //   cName: "nav-text"
    //   roles: ["admin", "user"],
    // },
  ];

  return (
    <>
      <IconContext.Provider value={{}}>
        <div className="navbar">
          <Link to="#" className="menu-bars">
            <FaIcons.FaBars onClick={showSidebar} style={{ color: iconColor ,marginBottom:"4px" }}  />
            <span>Workspace</span>
          </Link>
          {/* top Bar */}
          <header
            className="header fixed-top d-flex align-items-center "
            id="header"
          >
            {/* <div className="time-div">
              <button className="btn header_datetime">
                {currentTime.toLocaleTimeString()}
              </button>
              </div>
              <button className="btn header_datetime" id="datetime">
                {headerInfo.day}
              </button>
              <button className="btn header_datetime" id="datetime">
                {headerInfo.date}
              </button> */}
            <nav className="header-nv ms-auto" style={{marginRight: "4%", marginTop: "3%"}}>
              <ul className="d-flex align-items-center">
                <NavAvatar />
              </ul>
            </nav>
          </header>
        </div>
        <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
          <ul className="nav-menu-items">
            <li className="navbar-toggle">
              <Link to="#" className="menu-bars close-icon" onClick={showSidebar}>
                <AiIcons.AiOutlineClose style={{marginTop:"45px"}} />
              </Link>
            </li>
            {SidebarData.map((item, index) => {
              if (item.roles.includes(userRole)) {
                return (
                  <li key={index} className={item.cName}>
                    <NavLink to={item.path} activeclasscame="active">
                      {item.icon}
                      <span>{item.title}</span>
                    </NavLink>
                  </li>
                );
              } else {
                return null; // Don't render the item if user's role doesn't match
              }
            })}
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navigationbar;
