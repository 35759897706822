import React, { useState, useEffect, useRef } from "react";
import dash from "./Dashboard.module.css";
import * as fa from "react-icons/fa";
import axios from "axios";
import { Link } from "../utils/link";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Dashboard() {
  const [isCheckedIn, setIsCheckedIn] = useState(false);
  const [dashData, setDashData] = useState([]);
  const [time, setTime] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState("");
  const [project, setProject] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [activityType, setActivityType] = useState("");
  const [checkOutId , setCheckOutId] = useState("");
  const [projectDashData, setProjectDashData] = useState([]);
  const [recStart , setRecStart] = useState(false)
  
const videoRef = useRef(null);
let mediaRecorder;
let recordedChunks = [];
  
  const [selectedId, setSelectedId] = useState("");
  const [selectedDesignation, setSelectedDesignation] = useState(null);
const showFrs = false

  useEffect(() => {
    fetchDashboardData();
  }, []);
  //get dashboard data
  const fetchDashboardData = async () => {
    try {
      const res = await axios.get(Link + "/dashboard/json", {
        withCredentials: true,
      });
      console.log("Dash:",res.data);
      setCheckOutId(res.data.stamp_id)
      setDashData(res.data);
      setProjectDashData(res.data.projects);
    } catch (error) {
      console.log(error);
    }
  };
  const startStopwatch = () => {
    setTime(0); // Reset the time to 0 on each "Check In"
    const id = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 1000);
    setIntervalId(id);
  };

  const stopStopwatch = () => {
    clearInterval(intervalId);
    setIntervalId(null);
  };

  const toggleCheckInOut = () => {
    if (isCheckedIn) {
      // stopStopwatch();
      // setTime(0);
      setIsOpen(true); // Open modal on check out
      setIsCheckedIn(false);
      //setCheckOutId(null);
    } else {
      startStopwatch();
      setIsCheckedIn(true);
      setCheckOutId(Date.now()); // Set a unique checkout ID
    }
    //setIsCheckedIn((prev) => !prev);
  };

  useEffect(() => {
    if (dashData.checkin_time) {
      const checkinTime = new Date();
      const [hours, minutes, seconds] = dashData.checkin_time.split(':');
      checkinTime.setHours(hours);
      checkinTime.setMinutes(minutes);
      checkinTime.setSeconds(seconds);
      
      const updateTime = () => {
        const currentTime = new Date();
        const timeDifference = Math.floor((currentTime - checkinTime) / 1000);
        setTime(timeDifference);
      };

      const intervalId = setInterval(updateTime, 1000);
      return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }
  }, [dashData.checkin_time]);

 const formatTime = (seconds) => {
    const getSeconds = `0${seconds % 60}`.slice(-2);
    const minutes = Math.floor(seconds / 60);
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(seconds / 3600)}`.slice(-2);
    return `${getHours}:${getMinutes}:${getSeconds}`;
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
console.log("stamp:",checkOutId);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = {
        projectID: project,
        task_type: activityType,
        desc: taskDescription,
      };
      const res = await axios.post(Link + "/task_report", data, {
        withCredentials: true,
      });
      console.log("Task report submitted:", res.data);
      setCheckOutId(null);
      stopStopwatch();
      setTime(0); // Reset the timer to "00:00:00"
      setIsOpen(false); // Close the modal
    } catch (error) {
      console.error("Error submitting task report:", error);
    }
  };

const handleDataAvailable = (event) => {
  if (event.data.size > 0) {
    recordedChunks.push(event.data);
  }
};

const endRec = () => {
  if (mediaRecorder && mediaRecorder.state !== 'inactive') {
    mediaRecorder.stop();
    const tracks = videoRef.current.srcObject.getTracks();
    tracks.forEach(track => track.stop());
    videoRef.current.srcObject = null;
  }
};

const startRec = (stream, check) => {
  recordedChunks = []; // Clear recorded chunks before starting
  console.log(videoRef,"vodeo ref")
  if (!videoRef.current) {
    console.error("videoRef is null");
    return;
  }

  videoRef.current.srcObject = stream;
  videoRef.current.play();
  mediaRecorder = new MediaRecorder(stream);
  mediaRecorder.ondataavailable = handleDataAvailable;
  mediaRecorder.start();

  return new Promise((resolve, reject) => {
    mediaRecorder.onstop = async () => {
      console.log("Recording stopped, chunks: ", recordedChunks);

      const blob = new Blob(recordedChunks, { type: 'video/webm' });
      const formData = new FormData();
      formData.append('video', blob);
      try {
        const res = await axios.post(Link+"/frs", formData,{withCredentials:true})
        if(res.data.status_){
          checkIn()
       
            toggleCheckInOut();
         
        
        }
        console.log(res.data)
      } catch (error) {
        console.log(error)
      }

      console.log("FormData: ", formData.get('video'));
    }
})
     
};

const getLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      const timeoutVal = 10 * 1000 * 1000; // 10 seconds
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude.toFixed(5);
          const longitude = position.coords.longitude.toFixed(5);
          const matchFound = latitude.startsWith("21.14") && longitude.startsWith("72.77");
          resolve(matchFound);
        },
        (error) => {
          reject(error);
   
          alert("Please allow location access");
        },
        { enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0 }
      );
    } else {
      alert("Please allow location access");
      reject("Location access not available");
    }
  });
};

const checkIn = async () => {
 

  try {
    const matchFound = await getLocation();

    console.log("Match found:", matchFound);
   console.log("hahhhhhhh",JSON.stringify(matchFound))
    const response = await axios.post(Link+"/checkin", {
      "inoffice": JSON.stringify(matchFound) 
    },{withCredentials:true});
       
   
    // Handle the response to update the UI accordingly
  } catch (error) {
    console.error("Error occurred:", error);
  }
};

const checkOut = async () => {


  try {
  
 
 
    const response = await axios.post(Link+"/checkout",  {
    "id":checkOutId
    },{withCredentials:true});
    
   console.log(response.data)
    // Handle the response to update the UI accordingly
  } catch (error) {
    console.error("Error occurred:", error);
  }
};

const checkFrs = async (check) => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ video: true });
    console.log("Recording started...");
    setTimeout(endRec, 5000); // Call endRec function after 1 second
    await startRec(stream, check);
    console.log("Recording finished.");
  } catch (error) {
    console.error("Error:", error);
  
  }
};

const handleCheckIn = async () => {

  console.log("first")
  setTimeout(()=>{
    setRecStart(true)
    setTimeout(()=>{
      setRecStart(false)
    },5000)
  },1000)
  clearMediaRecorderStream();
  console.log("second")
  
  await checkFrs("in");
  console.log("third")
  setIsCheckedIn(true);
  setCheckOutId(null);
};

const clearMediaRecorderStream = () => {
  recordedChunks = [];
  if (mediaRecorder && mediaRecorder.stream) {
    mediaRecorder.stream.getTracks().forEach(track => track.stop());
    mediaRecorder.stream = null;
  }
};

  const handleDesignationChange = (selectedOption) => {
    setSelectedDesignation(selectedOption);
    //setSelectedEmployees([]);
  };
  return (
    <>
    <video ref={videoRef} style={{ display: 'none' }}></video>

      <div className={dash.dashboard}>
        <h2>Dashboard</h2>
        <div className="row">
          <div className="col-sm-4 ">
            <div className={`card mb-2 mt-2 ${dash.gradientb}`}>
              <div className="card-body overflow-auto text-center">
                {" "}
                {/* Added text-center class */}
                <div className="row">
                  <div className="col-sm-12 mt-1 mb-1">
                    {" "}
                    {/* Updated to full width */}
                    <h3>{formatTime(time)}</h3>
                  </div>
                  <div className="col-sm-12  mb-2">
                    {" "}
                    {/* Updated to full width */}
                    {isCheckedIn || checkOutId ? (
                      <button
                        onClick={() => {
                          toggleCheckInOut();
                          //handleRecordSequence();
                        
                        }}
                        className={`${dash.button}`}
                        style={{ backgroundColor: "var(--redbtn)" }}
                      >
                        Check Out
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          //handleRecordSequence();
                     
                          handleCheckIn()
                        }}
                        className={dash.button}
                      >
                        Check In
                      </button>
                    )}
                       {recStart &&(
                        <p>Face Recognizing......</p>
                       )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-8">
            <div class={`card mb-2 mt-2  ${dash.gradientb}`}>
              <div class="card-body overflow-auto">
                <h5 class={dash.head}>
                  <fa.FaQuoteLeft className="doublequote" />
                </h5>
                <h5>
                  <p>
                  <div style={{textAlign: "center"}}>{dashData.quote}</div></p>
                </h5>
              </div>
            </div>
          </div>
        </div>
        {/* working hrs & ongoing project */}
        <div className="row" >
          <div className="col-sm-9">
            <div className="row"style={{marginRight:"0px" , marginLeft:"0px"}}>
              <div class={`card mb-2 mt-2  ${dash.gradientbg}`}>
                <div class="card-body overflow-auto">
                  <div className={dash.head}>My Timesheet</div>
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Check-in</th>
                        <th>Check-out</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* Placeholder rows */}
                      {dashData.myTimesheet?.map((index) => (
                        <tr key={index.id}>
                          <td>
                            <fa.FaWifi />
                          </td>
                          <td>{index.name}</td>
                          <td>{index.email}</td>
                          <td>{index.in}</td>
                          <td>{index.out}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="row" style={{marginRight:"0px" , marginLeft:"0px"}}>
              <div class={`card mb-2 mt-2  ${dash.gradientbg}`}>
                <div class="card-body overflow-auto">
                  <div className={dash.head}>Other's Timesheet</div>
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Check-in</th>
                        <th>Check-out</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* Placeholder rows */}
                      {dashData.othersTimesheet?.map((index) => (
                        <tr key={index.id}>
                          <td>
                            <fa.FaWifi />
                          </td>
                          <td>{index.name}</td>
                          <td>{index.email}</td>
                          <td>{index.in}</td>
                          <td>{index.out}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-3 ">
            <div className={`card mb-2 mt-2  ${dash.gradientbg2}`}>
              <div className="card-body overflow-auto">
                <div className={dash.head}>Today's Project</div>
                {projectDashData.map((item,index)=>(
                    <div className="row" key={index}>
                    <div className="col-sm-2 mt-2 mb-2" style={{flexDirection: "row",display: "flex" ,width: "100%"}}>
                      <div className={dash.project}> {index + 1}</div>
                      <div
                        className={dash.status}
                        style={{ color: "var(--greencard)" ,fontStyle:"bold", marginLeft:"5%"}}
                      >
                       {item.name}
                      </div>
                    </div>
                  </div>
    ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="modal open">
          <div className="modal-content">
            <h4>Check Out</h4>
            <form onSubmit={handleSubmit}>
              <h6>Project</h6>
              <select
          onChange={(e) => setProject(e.target.value)}
          value={project}
          className={dash.drop}
          style={{ width: "100% " }}
          required
        >
          <option value="" disabled>Select a Project</option>
          {dashData.projects.map((project) => (
            <option key={project.id} value={project.id}>
              {project.name}
            </option>
          ))}
        </select>
              <h6>Task Description</h6>
              <textarea
                className="form-control"
                id="taskDescription"
                rows="3"
                value={taskDescription}
                onChange={(e) => setTaskDescription(e.target.value)}
              ></textarea>
              <h6>Activity Type</h6>
              <div>
                <label className={dash.label1}>
                  <input
                    type="radio"
                    name="activityType"
                    value="project"
                    checked={activityType === "project"}
                    onChange={(e) => setActivityType(e.target.value)}
                  />
                  Project
                </label>
                <br />
                <label className={dash.label1}>
                  <input
                    type="radio"
                    name="activityType"
                    value="learning"
                    checked={activityType === "learning"}
                    onChange={(e) => setActivityType(e.target.value)}
                  />
                  Learning
                </label>
                <br />
                <label className={dash.label1}>
                  <input
                    type="radio"
                    name="activityType"
                    value="testing"
                    checked={activityType === "testing"}
                    onChange={(e) => setActivityType(e.target.value)}
                  />
                  Testing
                </label>
              </div>
              <div className="button-container">
                <button
                  type="submit"
                  style={{ backgroundColor: "var(--button)", color: "white" }}
                  onClick={()=>{  checkOut();  toggleCheckInOut()}}
                >
                  Submit
                </button>
                <button
                  type="button"
                  onClick={() => setIsOpen(false)}
                  style={{ backgroundColor: "var(--cancel)", color: "white" }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
            <ToastContainer/>
        </div>
      )}
    </>
  );
}

export default Dashboard;