
import React, { useState } from 'react';
import './NotificationPopout.css';
import axios from 'axios';
import { Link } from '../utils/link';


function NotificationPopOut({messageId}) {
  const [noti ,setNoti]=useState([])
  const getNotifications =async()=>{
    try{

      const res =await axios.get(Link+'/notifications/json')
  
      console.log(res.data)
      setNoti(res.data)
        console.log("success")
      
    }catch(error){
      console.log("failed")
    } 
  }
  return (
    <>
      <div className="list">
        {/* Your blurred list content goes here */}
      </div>
      <div className="no-popout">
        {/* This div will contain the blurred list when the pop-out is displayed */}
      </div>
      <div className="show-popout">
        <div className="overlay">
          <span className="close">&times;</span>
          <h5>{messageId}</h5>
          <p>You have seen the sent notification.</p>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti saepe repudiandae non quas obcaecati porro nam aliquid, dolores doloribus iste!</p>
        </div>
      </div>
    </>
  );
}

export default NotificationPopOut;


