import React, { useState, useEffect } from "react";
import { getFromAPI } from "../utils/utils";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./leaveConfiguration.module.css";
import { MdOutlineSick } from "react-icons/md";
import * as FA from "react-icons/fa6";
import moment from "moment";
import axios from "axios";
import { Link } from "../utils/link";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function LeaveAnalysis() {
  const [showForm, setShowForm] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [leavesData, setleavesData] = useState([]);
  const [eventType, setEventType] = useState("");
  const [reason, setReason] = useState("");
  const [empId, setEmpId] = useState([]);
  
 
  // Assuming fetchData and setleavesData are defined elsewhere in your component
  // Make sure to adjust the dependencies array of useEffect based on your actual dependencies
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(Link+`/leave/json`, {
          withCredentials: true,
        });
        const saveData =response.data
        // Directly use response.data assuming it's an array
        const updatedData =saveData.map((leave) => ({
          ...leave,
          eventType: "Leave", // Add the eventType property here
        }));
        setleavesData(updatedData);
    
        const empIdResponse = await axios.get(Link+"/leave/apply/empId/api");
        setEmpId(empIdResponse.data.empId);
        console.log(empIdResponse.data.empId);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error gracefully, e.g., display a message to the user
      }
    };
    
    fetchData(); // Call the fetchData function
  }, []); // Add any dependencies here if needed
  

  const toggleForm = () => {
    setShowForm(!showForm);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!eventType || !startDate || !endDate || !reason) {
      console.error('Please fill in all fields');
      return;
    }
    const formData = new FormData();
    formData.append("leaveType", eventType);
    formData.append("startHr", startTime);
    formData.append("endHr", endTime);
    formData.append("startDate", startDate);
    formData.append("endDate", endDate);
    formData.append("reason", reason);
    formData.append("empID", empId);

    try {
      const response = await fetch(Link+"/leave/apply/api", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        // Handle success
        console.log("Leave applied successfully");
        // Clear form fields
        setEventType("");
        setStartTime("");
        setEndTime("");
        setStartDate("");
        setEndDate("");
        // Close the form
        setShowForm(false);
      } else {
        // Handle error
        console.error("Failed to apply leave");
      }
    } catch (error) {
      console.error("Error applying leave:", error);
    }
  };

  return (
    <>
      <div className={styles.leave}>
        <div className={styles.Container}>
        <label for="cars">Choose a car:</label>
<select id="cars" name="cars">
  <option value="volvo">Volvo</option>
  <option value="saab">Saab</option>
  <option value="fiat">Fiat</option>
  <option value="audi">Audi</option>
</select>
          <div className={`row ${styles.customRow}`}>
            <div className="col mt-2 mb-sm-2">
              <div
                className={`card  ${styles.topCard}`}
                style={{ backgroundColor: "#59A6FF" }}
              >
                <div className="card-body">
                  <div className={styles.iconCircle}>
                    <MdOutlineSick />
                  </div>
                  <h5 className="card-title text-center font-weight-bold">
                    12/12
                  </h5>
                  <h6 className="card-subtitle mb-2 text-center text-body-secondary">
                    Sick Leave
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-sm-3 mt-2 mb-sm-2">
              <div
                className={`card ${styles.topCard} `}
                style={{ backgroundColor: "#44DCBE" }}
              >
                <div className="card-body">
                  <div className={styles.iconCircle}>
                    <FA.FaBurst />
                  </div>
                  <h5 className="card-title text-center font-weight-bold">
                    12/12
                  </h5>
                  <h6 className="card-subtitle mb-2 text-center text-body-secondary">
                    Casual Leave
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-sm-3 mt-2 mb-sm-2">
              <div
                className={`card ${styles.topCard} `}
                style={{ backgroundColor: "#FF6D85" }}
              >
                <div className="card-body">
                  <div className={styles.iconCircle}>
                    <FA.FaHourglassStart />
                  </div>
                  <h5 className="card-title text-center font-weight-bold">
                    12/12
                  </h5>
                  <h6 className="card-subtitle mb-2 text-center text-body-secondary">
                    Half Days
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-sm-3 mt-2 mb-sm-2" onClick={toggleForm}>
              <div
                className={`card ${styles.topCard} `}
                style={{ backgroundColor: "#FFC065" }}
              >
                <div className="card-body">
                  <div className={styles.iconCircle}>
                    <FA.FaPlus />
                  </div>
                  <h5 className="card-title text-center font-weight-bold">
                    12/12
                  </h5>
                  <h6 className="card-subtitle mb-2 text-center text-body-secondary">
                    Add Leave
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <div className={`row ${styles.customRow}`}>
            {leavesData?.map((leave, index) => {
              let badgeClass = "";
              let statusText = "";
              switch (leave.status) {
                case -1:
                  badgeClass = styles.warningBadge;
                  statusText = "Applied";
                  break;
                case 0:
                  badgeClass = styles.dangerBadge;
                  statusText = "Rejected";
                  break;
                case 1:
                  badgeClass = styles.successBadge;
                  statusText = "Accepted";
                  break;
                default:
                  break;
              }

              return (
                <div key={index} className={`card ${styles.leaveCard}`}>
                  <h5
                    className={`card-header d-flex justify-content-between ${styles.header}`}
                  >
                    <span>{leave.leaveType}</span>
                    <span className={`badge ${badgeClass}`}>{statusText}</span>
                  </h5>
                  <div className="card-body" style={{ overflowX: 'auto' }}>
                    <h5 className={`card-title text-success ${styles.dates}`}>
                      Applied Date:
                      <span className={styles.dateText}>
                        {moment(leave.appliedOn, "HH:mm | DD-MM-YYYY").format(
                          " DD-MM-YYYY | HH:mm "
                        )}
                      </span>
                    </h5>
                    <h5 className={`card-title text-success ${styles.dates}`}>
                      Start Date:
                      <span className={styles.dateText}>
                        {moment(leave.startDate).format("DD-MM-YYYY")}
                      </span>
                    </h5>
                    <h5 className={`card-title text-success ${styles.dates}`}>
                      End Date:
                      <span className={styles.dateText}>
                        {moment(leave.endDate).format("DD-MM-YYYY")}
                      </span>
                    </h5>
                    {leave.reason && (
                      <p>Reason For Rejection: {leave.reason}</p>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className={showForm ? "modal open" : "modal"}>
        <div className={styles.modalContent}>
          <div className={styles.card}>
            <h2>Add Leave</h2>
            <form onSubmit={handleSubmit}>
              <h6>Add Leave</h6>
              <select
                id="eventType"
                name="eventType"
                value={eventType}
                onChange={(e) => setEventType(e.target.value)}
                required
              >
                <option value="">Select Leave Type</option>
                <option value="Half Leave">Half Leave</option>
                <option value="Sick Leave">Sick Leave</option>
                <option value="Casual Leave">Casual Leave</option>
              </select>
              {eventType === "Half Leave" && (
                <>
                  <h6>Start Time</h6>
                  <input
                    type="time"
                    id="startTime"
                    name="startTime"
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                    required
                  />
                  <h6>End Time</h6>
                  <input
                    type="time"
                    id="endTime"
                    name="endTime"
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                    required
                  />
                  <h6>Start Date</h6>
                  <input
                    type="date"
                    id="startDate"
                    name="startDate"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    required
                  />
                  <h6>End Date</h6>
                  <input
                    type="date"
                    id="endDate"
                    name="endDate"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    required
                  />
                </>
              )}

              {eventType !== "Half Leave" && (
                <>
                  <h6>Start Date</h6>
                  <input
                    type="date"
                    id="startDate"
                    name="startDate"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    required
                  />
                  <h6>End Date</h6>
                  <input
                    type="date"
                    id="endDate"
                    name="endDate"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    required
                  />
                </>
              )}
              <h6>Reason</h6>
              <textarea
                id="reason"
                name="reason"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                required
              />
              <div className="button-container">
                <button
                  onClick={handleSubmit}
                  style={{ backgroundColor: "var(--greenbtn)", color: "white" }}
                ><input type="hidden" name="employeeID" value={empId} />
                  Submit
                </button>
                <button
                  onClick={toggleForm}
                  style={{ backgroundColor: "var(--redbtn)", color: "white" }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default LeaveAnalysis;
