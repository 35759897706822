import React, { useEffect } from "react";
import styles from "./AdminProjectcard.module.css";
import { useAuth, useUserContext } from "../../Context/context";
import Loader from "../Loader/Loader";
import { useState } from "react";
import axios from "axios";
import Select from "react-select";
import "bootstrap/dist/css/bootstrap.min.css";
//import { link } from "../utils/link";
//import { postToAPI } from "../utils/utils";
import { useNavigate } from "react-router-dom";
import { Link } from "../utils/link";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaRegTrashAlt } from "react-icons/fa";
import { FaRegEdit } from "react-icons/fa";

function AdminProjectCard() {
  const [project, setProject] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [selectedDesignation, setSelectedDesignation] = useState(null);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [eventmodal, setEventModal] = useState(false);
  const [editedTeamID, setEditedTeamID] = useState("");
  const navigate = useNavigate();
  const [projectType, setProjectType] = useState("");
  
  const [loading, setLoading] = useState(false); // Add loading state
  const initialFormData = {
    name: "",
    startDate: "",
    endDate: "",
    contributor: "",
    projectLead: "",
    techLead: "",
    projectType: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const openEventModal = () => {
    setFormData(initialFormData); // Reset form data
    setSelectedEmployees([]); // Reset selected employees
    setSelectedDesignation(null); // Reset selected designation
    //setProjectType(""); // Reset project type
    setEventModal(true);
  };

  const handleEdit = async (id) => {
    try {
      const res = await axios.get(Link + `/admin/projects/view/json?id=${id}`, {
        withCredentials: true,
      });
      const projectData = res.data;
      // Map project type to the corresponding option
      const projectTypeOption = projectTypeOptions.find(
        (option) => option.value === projectData.proj_type
      );
      // Fetch designation data if not already fetched
      if (designations.length === 0) {
        await fetchDesignations();
      }

      // Find the project lead and tech lead names from the designation data
      const findEmployeeName = (id) => {
        for (const designation of designations) {
          const employee = designation.employees.find((emp) => emp.id === id);
          if (employee) {
            return employee.full_name;
          }
        }
        return "";
      };

      const projectLeadName = findEmployeeName(projectData.project_lead);
      const techLeadName = findEmployeeName(projectData.tech_lead);
      console.log("pro",projectLeadName,"lead",techLeadName);
      console.log(projectTypeOption);
      setFormData({
        eventName: projectData.name,
        startDate: projectData.start_dt,
        endDate: projectData.end_dt,
        projectLead: projectLeadName,
        techLead: techLeadName,
        projectType: projectTypeOption.label,
      });
      setSelectedEmployees(
        projectData.contri.map((contributorId) => {
          const employeeName = findEmployeeName(contributorId);
          return {
            value: contributorId,
            label: employeeName,
          };
        })
      );
      // setSelectedDesignation({
      //   value: projectData.designation_id,
      //   label: projectData.designation_name,
      // });
      // setProjectType(projectData.proj_type);
      //setProjectType(projectData.proj_type);
      setEditedTeamID(id);
      setEventModal(true);
    } catch (error) {
      console.error("Failed to fetch project details for editing", error);
    }
  };

  //project data
  const fetchProjects = async () => {
    try {
      const res = await axios.get(
        Link + "/admin/projects/view/json",
        {
          withCredentials: true,
        }
      );
      setProject(res.data);
    } catch (error) {
      console.log("Failed to fetch projects");
    }finally {
      setLoading(false);
    }
  };
  //designation data
  const fetchDesignations = async () => {
    try {
      const res = await axios.get(
        Link + "/admin/projects/designations/api",
        {
          withCredentials: true,
        }
      );
      setDesignations(res.data["project status"]);
    } catch (error) {
      console.log("Failed to fetch designations");
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchProjects();
    fetchDesignations();
  }, []);
  // Function to close the event modal and clear form data
  const closeEventModal = () => {
    setEventModal(false);
    setFormData(initialFormData);
    setSelectedEmployees([]);
    setSelectedDesignation(null);
    //setProjectType("");
    setEditedTeamID(""); // Reset editedTeamID
  };

  //project type
  const projectTypeOptions = [
    { value: "development", label: "Development" },
    { value: "testing", label: "Testing" },
    { value: "learning", label: "Learning" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDesignationChange = (selectedOption) => {
    setSelectedDesignation(selectedOption);
    //setSelectedEmployees([]);
  };

  //to change employee
  const handleEmployeeChange = (selectedOptions) => {
    const newEmployees = selectedOptions
      ? selectedOptions.map((option) => ({
          value: option.value,
          label: option.label,
          department: selectedDesignation.value,
        }))
      : [];

    const updatedEmployees = [
      ...selectedEmployees.filter(
        (employee) => employee.department !== selectedDesignation.value
      ),
      ...newEmployees,
    ];
    setSelectedEmployees(updatedEmployees);
  };

  //submitting project
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check if all fields are filled
    if (
      !formData.eventName ||
      !formData.startDate ||
      !formData.endDate ||
      !projectType 
    ) {
      toast.error("All fields are required");
      return;
    }
    if (editedTeamID && 
      !formData.projectLead ||
      !formData.teamLead
    ){
      toast.warn("Please Select Team Lead and Project Lead Again! ");
      return;
    }

    // Check if project lead and team lead are the same
    // if (formData.projectLead === formData.teamLead) {
    //   toast.error("Project lead and team lead cannot be the same");
    //   return;
    // }
    const findEmployeeIdByName = (name) => {
      for (const designation of designations) {
          const employee = designation.employees.find((emp) => emp.full_name === name);
          if (employee) {
              return employee.id;
          }
      }
      return "";
  };
    const projectData = new URLSearchParams();
    projectData.append("projectName", formData.eventName);
    projectData.append("startDate", formData.startDate);
    projectData.append("endDate", formData.endDate);
    projectData.append("projectType", projectType);

    const contributors = selectedEmployees.length > 0 ? selectedEmployees.map((employee) => employee.value) : [];
    if (contributors.length > 0) {
        projectData.append("contri", JSON.stringify(contributors));
    }
  //   if (formData.projectLead) {
  //     projectData.append("assignedBy", findEmployeeIdByName(formData.projectLead));
  // }
//   if (formData.teamLead) {
//     projectData.append("leader", findEmployeeIdByName(formData.teamLead));
// }
  
    projectData.append("assignedBy", formData.projectLead);
    projectData.append("leader", formData.teamLead);
    // Append project ID if editing
    if (editedTeamID) {
      projectData.append("projectId", editedTeamID);
    }
    const url = editedTeamID
      ? "/admin/projects/edit/api"
      : "/admin/projects/create/api";
      try {
        const response = await axios({
          method: editedTeamID ? "PUT" : "POST",
          url: Link + url,
          data: projectData,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          withCredentials: true, // Pass credentials with the request
        });

        const result = response.data;
        if (result.status) {
          toast.success(
            result.message || `${
              editedTeamID ? "Project updated" : "Project created"
            } successfully!`
          );
          closeEventModal();
          fetchProjects();
        } else {
          toast.error(
            result.message || `Failed to ${editedTeamID ? "update" : "create"} project`
          );
        }
      //closeEventModal();
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  //submitting project
  const handleEditSubmit = async (id) => {
    //e.preventDefault();
    // Check if all fields are filled
    if (
      !formData.eventName ||
      !formData.startDate ||
      !formData.endDate ||
      !projectType ||
      !formData.projectLead ||
      !formData.teamLead
    ) {
      toast.error("All fields are required");
      return;
    }

    // Check if project lead and team lead are the same
    if (formData.projectLead === formData.teamLead) {
      toast.error("Project lead and team lead cannot be the same");
      return;
    }
    const projectData = new URLSearchParams();
    projectData.append("projectName", formData.eventName);
    projectData.append("startDate", formData.startDate);
    projectData.append("endDate", formData.endDate);
    projectData.append("projectType", projectType);

    const contributors = selectedEmployees.map((employee) => employee.value);
    projectData.append("contri", JSON.stringify(contributors)); // Convert array to JSON string

    projectData.append("assignedBy", formData.projectLead);
    projectData.append("leader", formData.teamLead);

    try {
      const response = await fetch(Link + `/admin/projects/edit/api&id=${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: projectData.toString(),
      });

      const result = await response.json();
      if (result.status) {
        toast.success(result.message || "Project created successfully!");
        closeEventModal();
      } else {
        toast.error(result.message || "Failed to create project");
        closeEventModal();
      }
      //closeEventModal();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //function to remove member from form
  const removeMember = (memberToRemove) => {
    const updatedEmployees = selectedEmployees.filter(
      (member) => member.value !== memberToRemove.value
    );
    setSelectedEmployees(updatedEmployees);
  };

  const onClicking = (id) => {
    navigate("/projectdashboard/" + id);
    console.log("admin page :" + id);
  };

  //handle delete
  const handleDelete = async (projectId) => {
    try {
      const res = await axios.delete(
        Link + `/admin/projects/delete/api?id=${projectId}`,
        { withCredentials: true }
      );

      if (res.status === 200) {
        toast.success("Project deleted successfully");
        setProject(project.filter((p) => p.id !== projectId));
      } else {
        toast.error("Failed to delete project");
      }
    } catch (error) {
      toast.error("An error occurred while deleting the project");
    }
  };
  const handleBothAction = () => {
    if (editedTeamID) {
      handleEditSubmit();
    } else {
      handleSubmit();
    }
  };

  return (
    <>
      <div className={styles.project}>
        <div className={styles.flex}>
          <h2>Projects</h2>

          <button className={styles.button} onClick={openEventModal}>
            + Add Project
          </button>
        </div>
        {/* Project Card */}
        <div className="row">
          {loading ? (
              <Loader />
                
            ) : (project.map((projectItem) => {
            const parts = projectItem.en_date.split("-");
            const endDate = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
            const today = new Date();
            const diffInTime = endDate.getTime() - today.getTime();
            let diffInDays = Math.ceil(diffInTime / (1000 * 3600 * 24));

            // If deadline has passed, show "Deadline Pass" instead of negative days
            diffInDays = diffInDays > 0 ? diffInDays : "Deadline Pass";

            return (
              <div className="col me-1 ms-1 position-relative" key={projectItem.id}>
                <div
                  className={`card mb-2 mt-2 ${styles.gradientbg}`}
                  onClick={() => onClicking(projectItem.id)}
                >
                  <div className="card-body text-secondary overflow-auto">
                    <h5 className="card-title text-dark fw-bold fs-3 mb-2">
                      {projectItem.name}
                      <button
                        className={styles.editIcon}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEdit(projectItem.id);
                        }}
                      >
                        <FaRegEdit size={17} />
                      </button>
                      <button
                        className={styles.removeIcon}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(projectItem.id);
                        }}
                      >
                        <FaRegTrashAlt size={15} />
                      </button>
                    </h5>
                    <p className="card-text mb-2">
                      {/* <h5 className={`card-title text-success ${styles.dates}`}>
                        Start Date:
                        <span className={styles.dateText}>
                          {projectItem.st_date}
                        </span>
                      </h5>
                      <h5
                        className={`card-title text-success mb-3 ${styles.dates}`}
                      >
                        End Date:
                        <span className={styles.dateText}>
                          {projectItem.en_date}
                        </span>
                      </h5> */}
                      <h5
                        className={`card-title text-success fs-6 mb-3 ${styles.dates}`}
                      >
                        <span className={styles.dateText}>
                          {projectItem.st_date}-{projectItem.en_date}
                        </span>
                      </h5>
                      <h5
                        className={`card-title text-secondary fs-6 mb-2 ${styles.dates}`}
                      >
                        Project Lead:
                        {projectItem.project_lead}
                      </h5>
                      <h5
                        className={`card-title text-secondary fs-6 mb-2 ${styles.dates}`}
                      >
                        Team Lead:
                        {projectItem.tech_lead}
                      </h5>
                      <h5
                        className={`card-title text-secondary fs-6 mb-5 ${styles.dates}`}
                      >
                        Contributors:
                        {projectItem.contri}
                      </h5>
                    </p>
                    <div className="d-flex justify-content-between">
                      <div className="text-start text-secondary">
                        {projectItem.progress}%
                      </div>
                      <div className="text-end text-secondary">
                        {diffInDays}{" "}
                        {diffInDays !== "Deadline Pass" ? "Days Left" : ""}
                      </div>
                    </div>

                    <div className="progress mb-3">
                      <div
                        className={`progress-bar ${
                          projectItem.is_leader ? "bg-success" : "bg-primary"
                        }`}
                        role="progressbar"
                        style={{ width: `${projectItem?.progress}%`}}
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }))}
        </div>
      </div>

      <div className={eventmodal ? "modal open" : "modal"}>
        <div className={styles.modalContent}>
          <div className={styles.card}>
            <h2>{editedTeamID ? "Edit Project" : "Add Project"}</h2>
            {/* <form onSubmit={editedTeamID ? {handleEditSubmit} : {handleSubmit}}> */}
            <form onSubmit={handleSubmit}>
              <h6>Add Project</h6>
              <input
                type="text"
                id="eventName"
                name="eventName" // Change from "text" to "eventName"
                value={formData.eventName}
                onChange={handleChange}
                placeholder=" Enter Project"
                style={{ width: "100%" }}
              />
              <h6>Start Date</h6>
              <input
                type="date"
                id="startDate"
                name="startDate"
                value={formData.startDate}
                onChange={handleChange}
                style={{ width: "100%" }}
              />
              <h6>End Date</h6>
              <input
                type="date"
                id="endDate"
                name="endDate"
                value={formData.endDate}
                onChange={handleChange}
                style={{ width: "100%" }}
              />
              <h6>Project Type</h6>
              <Select
                options={projectTypeOptions}
                onChange={(option) => setProjectType(option.value)}
                placeholder="Select project type"
              />

              <h6>Select Department</h6>
              <Select
                options={designations.map((designation) => ({
                  value: designation.designation_id,
                  label: designation.designation_name,
                }))}
                onChange={handleDesignationChange}
                value={selectedDesignation}
                placeholder="Select a department"
              />

              {selectedDesignation && (
                <>
                  <h6>Select Members</h6>
                  <Select
                    options={designations
                      .find(
                        (designation) =>
                          designation.designation_id ===
                          selectedDesignation.value
                      )
                      .employees.map((employee) => ({
                        value: employee.id,
                        label: employee.full_name,
                      }))}
                    onChange={handleEmployeeChange}
                    value={selectedEmployees.filter(
                      (employee) =>
                        employee.department === selectedDesignation.value
                    )}
                    isMulti
                    placeholder="Select members"
                  />
                </>
              )}

              <div>
                <h6>Selected Members</h6>
                {selectedEmployees.map((member) => (
                  <div
                    key={member.value}
                    style={{
                      display: "inline-block",
                      margin: "5px",
                      padding: "5px",
                      border: "0.5px solid #ccc",
                      borderRadius: "5px",
                    }}
                  >
                    {member.label}
                    <button
                      type="button"
                      onClick={() => removeMember(member)}
                      style={{
                        marginLeft: "5px",
                        width: "15px",
                        border: "0.5px solid red",
                        color: "white",
                        backgroundColor: "red",
                        borderRadius: "50px",
                      }}
                    >
                      x
                    </button>
                  </div>
                ))}
              </div>

              <h6>Team Leader</h6>
              <select
                id="teamLead"
                name="teamLead"
                value={formData.teamLead}
                placeholder="Select Team Leader"
                onChange={handleChange}
              >
                <option value="" disabled>
                  Select Team Leader
                </option>
                {selectedEmployees.map((employee) => (
                  <option key={employee.value} value={employee.value}>
                    {employee.label}
                  </option>
                ))}
              </select>

              <h6>Project Leader</h6>
              <select
                id="projectLead"
                name="projectLead"
                value={formData.projectLead}
                placeholder="Select Project Lead"
                onChange={handleChange}
              >
                <option value="" disabled>
                  Select Project Leader
                </option>
                {selectedEmployees.map((employee) => (
                  <option key={employee.value} value={employee.value}>
                    {employee.label}
                  </option>
                ))}
              </select>

              <div className="button-container">
                <button
                  type="submit" // Change to type="submit"
                  style={{ backgroundColor: "var(--primary)", color: "white" }}
                >
                  Submit
                </button>
                <button
                  onClick={closeEventModal}
                  style={{ backgroundColor: "var(--cancel)", color: "white" }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
export default AdminProjectCard;
