import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./Adminattendance.module.css";
import Calendar from "./Calenderr"; // Assuming your Calendar component is named correctly
import axios from "axios";

import { Dropdown } from "react-bootstrap";
import { Link } from "../utils/link";

export default function AdminAttendance() {
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isEmployeeListOpen, setIsEmployeeListOpen] = useState(false);
  const [employeedata, setEmployeedata] = useState([]);
  const [leaveData, setLeaveData] = useState([]);
  const [attendanceData, setAttendanceData] = useState(null);
  console.log(employeedata);

  useEffect(() => {
    fetchEmployeeData();
  }, []);

  const fetchEmployeeData = async () => {
    try {
      const response = await axios.get(Link +'/admin/attendance', {
        withCredentials: true
      });
      setEmployeedata(response.data);
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const handleToggle = (isOpen) => {
    setIsEmployeeListOpen(isOpen);
  };

  const handleEmployeeSelect = (employee) => {
    setSelectedEmployee(employee);
    setIsEmployeeListOpen(false); // Close the dropdown after selecting an employee
  };

  return (
    <>
      <div className={styles.Calendar}>
        <div className={styles.flex}>
          <h2>Attendance</h2>
          <Dropdown onToggle={handleToggle}>
            <Dropdown.Toggle className={styles.button}>
              {selectedEmployee ? selectedEmployee.first_name : 'Employee List'}
            </Dropdown.Toggle>
            <Dropdown.Menu show={isEmployeeListOpen}>
              {employeedata.map((employee, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => handleEmployeeSelect(employee)}
                >
                  {employee.first_name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className={`${styles.rows} d-flex`}>
          <div className={`${styles.CalCard}`}>
            <Calendar id={selectedEmployee ? selectedEmployee._id : null} />
          </div>
          
        </div>
      </div>
    </>
  );
}
