import React, { useState, useEffect } from "react";
import { getFromAPI } from "../utils/utils";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./leave.module.css";
import { MdOutlineSick } from "react-icons/md";
import * as FA from "react-icons/fa6";
import moment from "moment";
import axios from "axios";
import { Link } from "../utils/link";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Leave() {
  const [showForm, setShowForm] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [leavesData, setleavesData] = useState([]);
  const [eventType, setEventType] = useState("");
  const [reason, setReason] = useState("");
  const [empId, setEmpId] = useState([]);
  const [leaveData, setLeaveData] = useState([]);
  const [leaveTypes, setLeaveTypes] = useState([]);
  
  // Assuming fetchData and setleavesData are defined elsewhere in your component
  // Make sure to adjust the dependencies array of useEffect based on your actual dependencies
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(Link + `/leave/json`, {
          withCredentials: true,
        });
        setLeaveData(response.data.leaveReport);
        
        // Directly use response.data assuming it's an array
        const updatedData = response.data.leaves.map((leave) => ({
          ...leave,
          eventType: "Leave", // Add the eventType property here
        }));
        setleavesData(updatedData);
        setLeaveTypes(response.data.leaveType);
        const empIdResponse = await axios.get(Link + "/leave/apply/empId/api",{withCredentials: true} );
        setEmpId(empIdResponse.data.empId);
        console.log("ID",empId.empId);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error gracefully, e.g., display a message to the user
      }
    };
    
    fetchData(); // Call the fetchData function
  }, []); // Add any dependencies here if needed
  

  const toggleForm = () => {
    setShowForm(!showForm);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!eventType || !startDate || !endDate || !reason) {
      console.error('Please fill in all fields');
      return;
    }
    const formData = new FormData();
    formData.append("leaveType", eventType);
    //formData.append("startHr", startTime);
    //formData.append("endHr", endTime);
    formData.append("startDate", startDate);
    formData.append("endDate", endDate);
    formData.append("reason", reason);
    formData.append("empID", empId);
console.log("form ",Object.fromEntries(formData.entries()));
try {
  const response = await axios.post(Link + "/leave/apply/api", formData, {
    withCredentials: true,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

      if (response.ok) {
        // Handle success
        console.log("Leave applied successfully");
        // Clear form fields
        setEventType("");
        //setStartTime("");
        //setEndTime("");
        setStartDate("");
        setEndDate("");
        // Close the form
        setShowForm(!showForm);
      } else {
        // Handle error
        console.error("Failed to apply leave");
      }
    } catch (error) {
      console.error("Error applying leave:", error);
    }
  };

  return (
    <>
      <div className={styles.leave}>
        <div className={styles.Container}>
          <h2>Leave</h2>

          <div className={`row ${styles.customRow}`}>
          {leaveData?.map((leave, index) => {
            return(
            <>
             <div className="col mt-2 mb-sm-2">
              <div
                className={`card  ${styles.topCard}`}
                style={{ backgroundColor: "var(--yellowcard)" }}
              >
                <div className="card-body">
                 
                  <h5 className="card-title text-center font-weight-bold">
                    {leave.limit} / {leave.limitcount}
                  </h5>
                  <h6 className="card-subtitle mb-2 text-center text-body-secondary">
                    {leave.Leavetypename}
                  </h6>
                </div>

              </div>
            </div>
            
            </>
          );    
})}
            <div className="col mt-2 mb-sm-2" onClick={toggleForm}>
              <div
                className={`card ${styles.topCard} `}
                style={{ backgroundColor: "#FFC065" }}
              >
                <div className="card-body">
                  {/* <div className={styles.iconCircle}>
                    <FA.FaPlus />
                  </div> */}
                  <h5 className="card-title text-center font-weight-bold">
                    +
                  </h5>
                  <h6 className="card-subtitle mb-2 text-center text-body-secondary">
                    Add Leave
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <div className={`row ${styles.customRow2}`}>
            {leavesData?.map((leave, index) => {
              let badgeClass = "";
              let statusText = "";
              switch (leave.status) {
                case -1:
                  badgeClass = styles.warningBadge;
                  statusText = "Applied";
                  break;
                case 0:
                  badgeClass = styles.dangerBadge;
                  statusText = "Rejected";
                  break;
                case 1:
                  badgeClass = styles.successBadge;
                  statusText = "Accepted";
                  break;
                default:
                  break;
              }

              return (
                <div key={index} className={`card ${styles.leaveCard}`}>
                  <h5
                    className={`card-header d-flex justify-content-between ${styles.header}`}
                  >
                    <span>{leave.leaveType}</span>
                    <span className={`badge ${badgeClass}`}>{statusText}</span>
                  </h5>
                  <div className="card-body overflow-auto " >
                    <h5 className={`card-title text-success ${styles.dates}`}>
                      Applied Date:
                      <span className={styles.dateText}>
                        {moment(leave.appliedOn, "HH:mm | DD-MM-YYYY").format(
                          " DD-MM-YYYY | HH:mm "
                        )}
                      </span>
                    </h5>
                    <h5 className={`card-title text-success ${styles.dates}`}>
                      Start Date:
                      <span className={styles.dateText}>
                        {moment(leave.startDate).format("DD-MM-YYYY")}
                      </span>
                    </h5>
                    <h5 className={`card-title text-success ${styles.dates}`}>
                      End Date:
                      <span className={styles.dateText}>
                        {moment(leave.endDate).format("DD-MM-YYYY")}
                      </span>
                    </h5>
                    {leave.reason && (
                      <p>Reason For Rejection: {leave.reason}</p>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className={showForm ? "modal open" : "modal"}>
        <div className={styles.modalContent}>
          <div className={styles.card}>
            <h2>Add Leave</h2>
            <form onSubmit={handleSubmit}>
              <h6>Add Leave</h6>
              <select
  id="eventType"
  name="eventType"
  value={eventType}
  onChange={(e) => setEventType(e.target.value)}
  required
>
  <option value="" disabled>Select Leave Type</option>
  {leaveTypes.map((type) => (
    <option key={type._id} value={type._id}>
      {type.leaveType}
    </option>
  ))}
</select>
{/* 
              {eventType === "Half Leave" && (
                <>
                  <h6>Start Time</h6>
                  <input
                    type="time"
                    id="startTime"
                    name="startTime"
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                    required
                  />
                  <h6>End Time</h6>
                  <input
                    type="time"
                    id="endTime"
                    name="endTime"
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                    required
                  />
                  <h6>Start Date</h6>
                  <input
                    type="date"
                    id="startDate"
                    name="startDate"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    required
                  />
                  <h6>End Date</h6>
                  <input
                    type="date"
                    id="endDate"
                    name="endDate"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    required
                  />
                </>
              )} */}

              {eventType  && (
                <>
                  <h6>Start Date</h6>
                  <input
                    type="date"
                    id="startDate"
                    name="startDate"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    required
                  />
                  <h6>End Date</h6>
                  <input
                    type="date"
                    id="endDate"
                    name="endDate"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    required
                  />
                </>
              )}
              <h6>Reason</h6>
              <textarea
                id="reason"
                name="reason"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                required
              />
              <div className="button-container">
                <button
                  onClick={handleSubmit}
                  style={{ backgroundColor: "var(--primary)", color: "white" }}
                ><input type="hidden" name="employeeID" value={empId} />
                  Submit
                </button>
                <button
                  onClick={toggleForm}
                  style={{ backgroundColor: "var(--cancel)", color: "white" }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Leave;
