// ChangeAvatarModal.js
import React, { useRef, useEffect, useState } from "react";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import "./ChangeAvatarModal.css";
import axios from "axios";
import { Link } from "../utils/link";
import { useAuth} from "../../Context/context";

function ChangeAvatarModal({
  isOpen,
  onClose,
  avatars,
  selectedAvatar,
  setSelectedAvatar,
}) {
  const sliderRef = useRef(null);

  const [accData, setAccData] = useState(null); // Initialize as null
  const [empId, setEmpId] = useState("");
  const { isAdmin } = useAuth();

  useEffect(() => {
    async function fetchData() {
      try {const url = isAdmin
        ? "/account/json/api"
        : "/account/json";
      const response = await axios.get(Link + url, {
        withCredentials: true,
      });
        setAccData(response.data); // Set state with response data       
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);
console.log(accData)
  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft -= 200; // Adjust the scroll amount as needed
    }
  };

  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft += 200; // Adjust the scroll amount as needed
    }
  };

  const handleSubmit = async () => {
    if (!selectedAvatar) {
      console.error("No avatar selected");
      return;
    }

    const formData = new FormData();
    formData.append("avatarID", selectedAvatar);
    formData.append("empID", accData.empID);

    try {
      const response = await axios.put(
        Link+"/account/updateAvatar",
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        console.log("Avatar updated successfully");
        onClose(); // Close the modal
      } else {
        console.error("Failed to update avatar");
      }
    } catch (error) {
      console.error("Error updating avatar:", error);
    }
  };

  return (
    <div className={isOpen ? "modal open" : "modal"}>
      <div className="modal-content">
        <h4>Change Avatar</h4>
        <div className="slider-container">
          <IoIosArrowBack className="arrow" onClick={handlePrev} />
          <div className="slider" ref={sliderRef}>
            {avatars.map((avatar, index) => (
              <img
                key={index}
                src={require(`../images/${avatar}.jpg`)}
                alt={`Avatar ${index}`}
                className={
                  selectedAvatar === avatar ? "avatar selected" : "avatar"
                }
                onClick={() => setSelectedAvatar(avatar)}
              />
            ))}
          </div>
          <IoIosArrowForward className="arrow" onClick={handleNext} />
        </div>
        <div className="button-container">
          <button
            onClick={handleSubmit}
            style={{ backgroundColor: "var(--primary)", color: "white" }}
          >
            <input type="hidden" name="employeeID" value={empId} />
            Submit
          </button>
          <button
            onClick={onClose}
            style={{ backgroundColor: "var(--cancel)", color: "white" }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChangeAvatarModal;
