import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { Link } from "../utils/link";
import { FaRegEdit } from "react-icons/fa";
import { Modal, Button } from "react-bootstrap";
import { FaRegTrashAlt } from "react-icons/fa";
import { useAuth } from "../../Context/context";
import { Toast } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import Event from "./Event.module.css";
const Calendar = () => {
  const [eventsData, setEventsData] = useState([]);
  const [clickedEventId, setClickedEventId] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [editTitle , setEditTitle]= useState("")
  const [editStart ,setEditStart] = useState("")
  const [editEnd ,setEditEnd]= useState("")
  const [selectedId , setSelectId]=useState("")
  const [editColor ,setEditColor]=useState("")
 console.log(editTitle ,"this is title ", editStart , "start "  , editColor ,"colororlor" , editEnd ,"thi is end")
  const {isAdmin} =useAuth()

  useEffect(() => {
   
    fetchData();
}, []);

const formatDateToInputValue = (dateStr) => {
  const date = new Date(dateStr);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};
    async function fetchData() {
      try {
        //const response = await getFromAPI("events/get_json");
        const response = isAdmin?  await axios.get(`${Link}/admin/events/get_json`):await axios.get(`${Link}/events/get_json`)
        setEventsData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
  }

  console.log(eventsData)
  const events = eventsData?.map((event, index) => ({
    id: event.id || index, // Use the event ID if it exists, or generate a unique key using the index
    title: event.title,
    start: event.start,
    end: event.end,
    backgroundColor: event.backgroundColor,
  }));


  const handleEdit = (event,eTitle , eStart ,eColor ,eEnd) => {
    console.log('Edit event:', event);
    //const formattedDateStr = formatDateString(editStart);
    //console.log(formattedDateStr,"start this")
    console.log(formatDateToInputValue(eStart) ,"this is date")
    // Handle edit logic here
    setEditTitle(eTitle)
    setEditColor(eColor)
    setEditStart(formatDateToInputValue(eStart))
    setEditEnd(formatDateToInputValue(eEnd))
    setShowEditModal(!showEditModal);
    setSelectId(event)
  };

  const editApi =async()=>{
     try {
      const res = await axios.post(Link+'/admin/events/update',{title:editTitle,start:editStart,end:editEnd,backgroundColor:editColor,id:selectedId} ,{withCredentials:true})
      console.log(res.data)
      setShowEditModal(!showEditModal)
      toast.success("Event Editted Successfully!")
      fetchData()
     } catch (error) {
      console.log(error)
      toast.error("Something Went Wrong, Please Try Again");
     }
  }

  const handleDelete = async(eId) => {
    console.log('Delete event:', eId);
   setSelectId(eId)
   setShowDeleteModal(!showDeleteModal);

  };

  const deleteApi = async()=>{
     try {
      const res = await axios.post(Link+"/admin/events/delete", {"id":selectedId},{withCredentials:true})
      console.log(res.data)
      toast.success("Event Deleted Successfully!")
    } catch (error) {
      console.log(error)
      toast.error("Something Went Wrong, Please Try Again");
    }
   setShowDeleteModal(!showDeleteModal);
   fetchData()
  }

  const handleEventClick = (clickInfo) => {
    setClickedEventId(clickInfo.event.id);
    setSelectedEvent(clickInfo.event);

  };

const renderEventContent = (eventInfo) => {
    const isClicked = clickedEventId === eventInfo.event.id;

    
    return (
      <div className={`event-container ${isClicked ? 'clicked' : ''}`}  style={{display:"flex" , justifyContent:"space-between"}}>
        <div className="event-title">
          <b>{eventInfo.timeText}</b> <i>{eventInfo.event.title}</i>
        </div>

        {isAdmin?  
        isClicked?
          <div className="event-icons">
            <FaRegEdit 
              className="event-icon edit-icon"
              style={{marginRight:"5px" ,fontSize:"15px"}}
              onClick={() => handleEdit(eventInfo.event.id,eventInfo.event.title , eventInfo.event.start ,eventInfo.event.backgroundColor ,eventInfo.event.end)}

            />
           <FaRegTrashAlt 
              className="event-icon delete-icon"
              style={{marginRight:"5px"}}
              onClick={() => handleDelete(eventInfo.event.id)}
            />
          </div>
        :"" :"" }
    
      </div>
    );
  };

  return (
    <>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={events}
        eventContent={renderEventContent}
        eventClick={handleEventClick}
        selectable={true}
        editable={true}
      />

<div className={showDeleteModal ? "modal open" : "modal"}>
        <div className={Event.modalContent}>
          <div className={Event.card}>
            <h5>you want to delete this event?</h5>
             
              <div className="button-container">
                <button
                  type="button" // Change to type="submit"
                  style={{ backgroundColor: "var(--primary)", color: "white" }}
                  onClick={deleteApi}
                >
                  Delete
                </button>
                <button
                type="button"
                  onClick={handleDelete}
                  style={{ backgroundColor: "var(--cancel)", color: "white" }}
                >
                  Cancel
                </button>
              </div>
          
          </div>
    
        </div>
      </div>

      <div className={showEditModal ? "modal open" : "modal"}>
        <div className={Event.modalContent}>
          <div className={Event.card}>
            <h2>Add Event</h2>
            <form >
              <h6>Add Event</h6>
              <input
                type="text"
                id="eventName"
                name="eventName" // Change from "text" to "eventName"
                value={editTitle}
                onChange={(e)=>setEditTitle(e.target.value)}
                placeholder=" Enter Event"
                
              />
              <h6>Start Date</h6>
              <input
                type="date"
                id="startDate"
                name="startDate"
               value={editStart}
               onChange={(e)=>setEditStart(e.target.value)}
              />
              <h6>End Date</h6>
              <input
                type="date"
                id="endDate"
                name="endDate"
                value={editEnd}
                onChange={(e)=>setEditEnd(e.target.value)}
              />
              <h6>Select Color</h6>
              <select
                id="color"
                name="color"
                // value={formData.color}
               // onChange={handleChange}
               value={editColor}
              >
                {/* <option>{editColor}</option> */}
                <option value="red">Red</option>
                <option value="blue">Blue</option>
                <option value="green">Green</option>
                <option value="yellow">Yellow</option>
                <option value="purple">Purple</option>
              </select>
              <div className="button-container">
                <button
                  type="button" // Change to type="submit"
                  style={{ backgroundColor: "var(--primary)", color: "white" }}
                  onClick={editApi}
                >
                  Submit
                </button>
                <button
                type="button"
                  onClick={handleEdit}
                  style={{ backgroundColor: "var(--cancel)", color: "white" }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
    
        </div>
      </div>
      <ToastContainer />
    </>
  );
};


export default Calendar;
