import React, { useState, useEffect } from "react";
import styles from "./leaveConfiguration.module.css";
import axios from "axios";
import { Link } from "../utils/link";

function LeaveConfiguration() {
  const [showForm, setShowForm] = useState(false);
  const [showForm2, setShowForm2] = useState(false);
  const [data, setData] = useState({ designations: [], leaveTypeData: [] });
  const [leaveTypeData, setLeaveTypeData] = useState([]);
  const [formData, setFormData] = useState({
    designation: "",
    leaveType: "",
    limit: 0,
    leaveDays: 0, // Set initial leaveDays to 0
    duration: 0,
    carryFwd: 0,
  });

  const toggleForm = () => {
    setShowForm(!showForm);
  };
  const toggleForm2 = () => {
    setShowForm2(!showForm2);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`${Link}/admin/leave/config/json`);
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  const handleInputChange = async (e) => {
    const { name, value, type, checked } = e.target;
    const updatedValue = type === "checkbox" ? (checked ? 1 : 0) : value;
    setFormData({
      ...formData,
      [name]: updatedValue,
    });
    if (name === "designation") {
      try {
        const response = await axios.get(`${Link}/admin/leave/config/json`, {
          params: { id: value },
        });
        setLeaveTypeData(response.data.leaveTypeData || []);
      } catch (error) {
        console.error("Error fetching leave type data:", error);
      }
    }
  };

  const handleIncrement = (leaveTypeId) => {
    setLeaveTypeData((prevLeaveTypeData) =>
      prevLeaveTypeData.map((leaveType) =>
        leaveType._id === leaveTypeId
          ? { ...leaveType, leaveDays: leaveType.leaveDays + 1 }
          : leaveType
      )
    );
  };

  const handleDecrement = (leaveTypeId) => {
    setLeaveTypeData((prevLeaveTypeData) =>
      prevLeaveTypeData.map((leaveType) =>
        leaveType._id === leaveTypeId && leaveType.leaveDays > 0
          ? { ...leaveType, leaveDays: leaveType.leaveDays - 1 }
          : leaveType
      )
    );
  };

  const handleSubmitLeaveType = async (leaveTypeId, leaveDays) => {
    try {
      const response = await axios.post(
        `${Link}/admin/leave/update_leave_config`,
        {
          leaveID: leaveTypeId,
          count: leaveDays,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status) {
        console.log("Leave days updated successfully:", response.data);
      } else {
        console.error("Error updating leave days:", response.data);
      }
    } catch (error) {
      console.error("Error submitting leave days:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${Link}/admin/leave/config/api`,
        {
          designation: formData.designation,
          leaveType: formData.leaveType,
          limit: formData.limit,
          leaveDays: formData.leaveDays,
          duration: formData.duration,
          carryFwd: formData.carryFwd,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.data.status) {
        console.log("Leave type created successfully:", response.data);
        setFormData({
          designation: "",
          leaveType: "",
          limit: 0,
          leaveDays: 0,
          duration: 0,
          carryFwd: 0,
        });
        setShowForm2(false);
        setShowForm(true);
      } else {
        console.error("Error creating leave type:", response.data);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleCancel = () => {
    setShowForm2(false);
    setShowForm(true);
  };

  return (
    <>
      <div className={styles.flex}>
        <h2>Add leave Configuration:</h2>
        <button className={styles.button} onClick={toggleForm}>
          + Add Leave
        </button>
      </div>
      <div className={showForm ? "modal open" : "modal"}>
        <div className={styles.modalContent}>
          <div className={styles.card}>
            <div className={styles.flexx}>
              <h3>Leave Management</h3>
              <button
                style={{ marginRight: "10px" }}
                className={styles.buuuttonn}
                onClick={toggleForm2}
              >
                +
              </button>
            </div>
            <form>
              <h6>Designation :</h6>
              <select
                className={styles.inputField}
                name="designation"
                value={formData.designation}
                onChange={handleInputChange}
              >
                <option value="">Select Designation</option>
                {data.designations &&
                  data.designations.length > 0 &&
                  data.designations.map((designation) => (
                    <option key={designation._id} value={designation._id}>
                      {designation.name}
                    </option>
                  ))}
              </select>
              {leaveTypeData && leaveTypeData.length > 0 && (
                <>
                  <h6>Leave Type Data</h6>
                  {leaveTypeData.map((leaveType) => (
                    <div key={leaveType._id} className={styles.leaveTypeContainer}>
                      <label>{leaveType.leaveType}</label>
                      <div className={styles.inputForNumber}>
                      <input
                        type="number"
                        name="leaveDays"
                        value={leaveType.leaveDays}
                        onChange={handleInputChange}
                        disabled={!formData.limit}
                      />
                      <button
                        type="button"
                        onClick={() => handleDecrement(leaveType._id)}
                      >
                        -
                      </button>
                      <button
                        type="button"
                        onClick={() => handleIncrement(leaveType._id)}
                      >
                        +
                      </button>
                      <button
                        type="button"
                        onClick={() =>
                          handleSubmitLeaveType(
                            leaveType._id,
                            leaveType.leaveDays
                          )
                        }
                      >
                        Submit
                      </button>
                    </div>
                    </div>
                  ))}
                </>
              )}
              <div className="button-container">
                {/* <button
                  style={{ backgroundColor: "var(--primary)", color: "white" }}
                >
                  <input type="hidden" name="employeeID" />
                  Submit
                </button> */}
                <button
                  type="button"
                  onClick={toggleForm}
                  style={{ backgroundColor: "var(--cancel)", color: "white" }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className={showForm2 ? "modal open" : "modal"}>
        <div className={styles.modalContent}>
          <div className={styles.card}>
            <h3>Add New Leave Type</h3>
            <form onSubmit={handleSubmit}>
              <h6>Designation :</h6>
              <select
                style={{ width: "100% " }}
                className={styles.inputField}
                name="designation"
                value={formData.designation}
                onChange={handleInputChange}
              >
                <option value="">Select Designation</option>
                {data.designations.map((designation) => (
                  <option key={designation._id} value={designation._id}>
                    {designation.name}
                  </option>
                ))}
              </select>
              <h6>Leave Type :</h6>
              <input
                type="text"
                name="leaveType"
                value={formData.leaveType}
                onChange={handleInputChange}
              />
              <div>
                <input
                  type="checkbox"
                  name="limit"
                  checked={formData.limit === 1}
                  onChange={handleInputChange}
                />
                <label className={styles.label1}>Set Limit</label>
              </div>
              <h6>Number of Days :</h6>
              <input
                type="number"
                name="leaveDays"
                value={formData.leaveDays}
                onChange={handleInputChange}
                disabled={formData.limit !== 1}
              />
              <div>
                <input
                  type="radio"
                  name="duration"
                  value="0"
                 
                  onChange={handleInputChange}
                />
                <label className={styles.label1}>Monthly</label>
                <input
                  type="radio"
                  name="duration"
                  value="1"
        
                  onChange={handleInputChange}
                />
                <label className={styles.label1}>Yearly</label>
                <div>
                  <input
                    type="checkbox"
                    name="carryFwd"
                    checked={formData.carryFwd === 1}
                    onChange={handleInputChange}
                  />
                  <label className={styles.label1}>
                    Carry Forward the Unused Leaves
                  </label>
                </div>
              </div>
              <div className="button-container">
                <button
                  type="submit"
                  style={{ backgroundColor: "var(--primary)", color: "white" }}
                >
                  Submit
                </button>
                <button
                  type="button"
                  onClick={handleCancel}
                  style={{ backgroundColor: "var(--cancel)", color: "white" }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default LeaveConfiguration;
