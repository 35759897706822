import React, { useMemo, useEffect, useState } from "react";
import styles from "./Notification.module.css";
import * as FaIcons from "react-icons/fa";
import NotificationPopOut from "./NotificationPopOut";
import axios, { Axios } from "axios";
import { Link } from "../utils/link";
import { useAuth, useUserContext } from "../../Context/context.js";

const UserNotification = () => {
  const [seenPopout, setSeenPopOut] = useState(false);
  //calls the usercontext 
 
  //const {isAdmin ,isUser}=useAuth()

  //state which handle notification list shown 
  const [noti, setNoti] = useState([]);
  //console.log(noti)
  const empID = noti.empID
  //state which store id on selecting particular notification
  const [selectedId, setSelectedId] = useState([]);
// state which handle the form input data 

  //console.log("this is notification data"+noti)
  //console.log(isAdmin)
  // console.log(formData);

  //this renders the notification list which is shown on the screen
  useEffect(() => {
    getNotifications();
    // console.log({selectedId})
  }, []);

  // this handlees the pop out msg on clicking on the notification
  const showSeenPopOut = (id,dateTime,notification,subject) => {
    updateSeenStatus(empID,id)
    setSeenPopOut(!seenPopout);
    setSelectedId({id,dateTime,notification,subject});
    getNotifications()
  
 
  };

  const updateSeenStatus =async(empID,id)=>{
    try {
      const res =await axios.post(Link+"/notifications/mark_noti_as_read",{empID,id},{withCredentials:true})
     console.log(res.data)
     getNotifications()
    } catch (error) {
      console.log(error)
    }
  }


  // this fetchs the api of all notification list 
  const getNotifications = async () => {
    try {
      const res = await axios.get(Link + "/notifications/json",{withCredentials:true});

      console.log("this is get data:"+res.data);
      setNoti(res.data);
     // console.log("this is selected data"+selectedId)
     // console.log("success");
    } catch (error) {
      console.log("failed");
    }
  };

  const memoizedNotifications = useMemo(() => {
    return     noti.notifications?.map((items, index) => (
      <div
        key={items.id}
        className={styles.notification}
        onClick={() => showSeenPopOut(items.id,items.dateTime,items.notification,items.subject)}
      >
        <div >

        <span className="avatar">
          <FaIcons.FaRegUserCircle />
        </span>

        <div className={styles.senderInfo}>
          <div className={styles.senderName}>Admin</div>
        </div>
        {!items.readById.includes(empID)?<strong>
          <div className={styles.notificationHeader}>{items.subject}</div>
          <div className={styles.notificationTime}>{items.dateTime}</div></strong> :<>
  <div className={styles.notificationHeader}>{items.subject}</div>
  <div className={styles.notificationTime}>{items.dateTime}</div></>
        }
     
        
        
        </div>
     
   
      </div>
      
    ))
 
  },[noti.notifications])




  return (
   
    <div className={styles.container}>
      <div className={styles.flex}>
        {/* header section  */}
        <h2>Notifications</h2>

      
      </div>

      {/* for notification seen pop out window  */}
       <div className={seenPopout?"modal open":"modal"} >
          {/* <NotificationPopOut messageId={selectedId} /> */}
          <div className={styles.modalcontent}>
          <h4>{selectedId.subject}</h4>  
          <p style={{ fontSize: '14px' ,overflow: "hidden" }}>{selectedId.notification}</p>
          
            <div className="button-container">
           
              <span className={styles.displayDate}>{selectedId.dateTime}</span>
              <button
                onClick={showSeenPopOut}
                style={{ backgroundColor: "var(--redbtn)", color: "white" }}
              >
                Cancel
              </button>
            </div>
          
        </div>
        </div>
   
       
      

    

        {/* this is code which maps all the notification on card */}
      <div className={styles.notificationList}>
       {memoizedNotifications}
      </div>
   
    </div>
  );
};

export default UserNotification;
