import React, {useEffect, useState } from 'react';
import { PieChart, Pie, Tooltip, Cell } from 'recharts';
import axios from 'axios';
import { Link } from "../utils/link";



const COLORS = ['#FA5A7E', '#3CD757', '#5F417F']; // Red for urgent, orange for medium, green for low

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
    
  );
};

const PriorityPieChart = ({ projectId }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.post(Link + "/projects/details/dash/calculate_priority", {
          project_id: projectId,
        }, {
          withCredentials: true,
        });
        setData(res.data.data);
      } catch (error) {
        console.error("Failed to fetch priority data", error);
      }
    };

    fetchData();
  }, [projectId]);


  return (
    <div>
      {data.length > 0 ? (
        <PieChart width={320} height={345}>
          <Pie
            data={data.map((value, index) => ({ name: `Priority ${index + 1}`, value }))}
            cx={150}
            cy={150}
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={150}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip formatter={(value, name) => `${((value / 100) * 100).toFixed(2)}%`} />
        </PieChart>
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};


export default PriorityPieChart;
