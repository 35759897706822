// Employee.js
import React, { useState, useRef, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./Employee.module.css";
import { CiEdit } from "react-icons/ci";
import { IoAddCircleSharp } from "react-icons/io5";
import axios from "axios";
import { Link } from "../utils/link";
import * as FaIcons from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import f1 from "../images/f1.jpg";
import Select from "react-select";

export default function Employee() {
  // const [data, setData] = useState({});
  const [showAddForm, setShowAddForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showFrs, setShowFrs] = useState(false);
  const video = useRef(null);
  const [selectedDesignation, setSelectedDesignation] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [stream, setStream] = useState(null);
  const [designations, setDesignations] = useState([]);
  const [editt, setEditt] = useState();
  const [projectStatus, setProjectStatus] = useState([]);
  var userID = document.getElementById("userID");
  const [frsRecording, setFrsRecording] = useState(false);
  const [frsRecordingStoped, setFrsRecordingStoped] = useState(false);
  const [frsRecordingSaved, setFrsRecordingSaved] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [city, setcity] = useState("");
  const [email, setemail] = useState("");
  const [shift, setshift] = useState("");
  const [address, setaddress] = useState("");
  const[phone,setPhone] = useState("");
  const [dob,setDob] = useState("");
  const[joining_date , setjoingd] = useState("");
  const [desig,setDesig] = useState("")
  const [editDesgi,setEditDesi] = useState([])
  const [gender, setGender] = useState("")
  console.log(editDesgi ,"this")

  const [userForm, setUserForm] = useState({
    first_name: "",
    last_name: "",
    username: "",
    dob: "",
    joining_date: "",
    address: "",
    desig: "",
    shift: "",
    email: "",
    phone: "",
    city: "",
    gender: "",
    avatar: f1,
  });

  const [editData, setEditData] = useState({
    fname: "",
    lname: "",
    dob: "",
    joining_date: "",
    gender: "",
    desig: "",
    shift: "",
    add: "",
    city: "",
    email: "",
    phone: "",
    userID: "",
  });

  const [formData, setformData] = useState([]);
  //console.log("formdaata is " + formData);
  //console.log("user form value"+userForm)

  useEffect(() => {
    fetchData();
    fetchDesignations();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(Link + "/admin/users/view/json", {
        withCredentials: true,
      });
      const user = response.data[0];
      const isAdminValue = user.isAdmin;
      console.log(user.isAdmin)
      console.log("API Response:", response);
      console.log(response.data.isAdmin)
      setIsAdmin(isAdminValue);
      // Ensure 'holidays' property exists in the response
      console.log("response.data",JSON.stringify(response.data))
      setformData(response.data);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const startRec = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      clearMediaRecorderStream();
      if (video) {
        video.srcObject = stream;
      }

      const recorder = new MediaRecorder(stream);
      recorder.ondataavailable = handleDataAvailable;
      recorder.onstart = () => {
        console.log("Recording started...");
      };
      recorder.onstop = () => {
        console.log("Recording stopped.");
      };
      setStream(stream);
      setMediaRecorder(recorder);

      recorder.start();
      const userid = selectedId;
      const res = await axios.post(
        Link + "/admin/users/save_userID",
        { userid },
        { withCredentials: true }
      );
      console.log(res.data);
      console.log("MediaRecorder initialized and started:", recorder);
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  const handleDataAvailable = (event) => {
    if (event.data.size > 0) {
      recordedChunks.push(event.data);
    }
  };

  const stopRec = () => {
    try {
      if (mediaRecorder && mediaRecorder.state === "recording") {
        console.log("Stopping the recording...");
        mediaRecorder.stop();
        console.log(mediaRecorder);
        if (video && video.srcObject) {
          const tracks = video.srcObject.getTracks();
          tracks.forEach((track) => track.stop());
          video.srcObject = null;
        }

        console.log("Recording stopped successfully.");
      } else {
        console.error(
          "MediaRecorder is not initialized or not in recording state."
        );
      }
    } catch (error) {
      console.error("Error stopping recording:", error);
    }
  };

  //designation data
  const fetchDesignations = async () => {
    try {
      const res = await axios.get(Link + "/admin/projects/designations/api", {
        withCredentials: true,
      });
      setDesignations(res.data["project status"]);
      console.log("employee data", res.data["project status"]);     } 
      catch (error) {
      console.log("Failed to fetch designations");
    }
  };

  const handleEdit = async () => {
    try {
      const id = selectedId;
      const designationMap = {};
      designations.forEach(designation => {
        designationMap[designation.designation_id] = designation.designation_name;
      });
      console.log(designationMap) 
      const designationId = designationMap[desig];
           // userID: id, // Include the userID
      // Construct the updatedUserData object from the editData state
      const formData = new FormData();
      // const designationId = Object.keys(designationMap).find(key => designationMap[key] === desig);
      formData.append("userID" , id);
      formData.append("desig", designationId);
      console.log("Designation ID:", designationMap);
      formData.append("fname" , fname);
      formData.append("lname",lname);
      formData.append("add",address);
      formData.append("shift",shift)
      formData.append("city",city)
      formData.append("joining_date", joining_date);
      formData.append("dob",dob);
      formData.append("gender",gender)
      // formData.append("desig",designation_name)
      formData.append("email" , email);
      formData.append("phone" , phone);
      formData.append("isAdmin", isAdmin);
      console.log("Form data:", Object.fromEntries(formData.entries()));
      // const res = await axios.put(
      //   Link + "/admin/users/edit/api",
      //   formData,
      //   {
      //     withCredentials: true,
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   }
      // );
      // // setEditt(res.data);
      // console.log(res.data);
      console.log("user updated successfully");
    } catch (error) {
      console.log("error updating user", error);
    }
  };

  function clearMediaRecorderStream() {
    if (mediaRecorder) {
      mediaRecorder.onstop = null;
      mediaRecorder.ondataavailable = null;
    }

    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      setStream(null);
    }

    if (video.current) {
      video.current.srcObject = null;
    }

    setRecordedChunks([]);
    setMediaRecorder(null);
    console.log("Media stream cleared");
  }

  async function saveRec() {
    console.log("send");

    const blob = new Blob(recordedChunks, { type: "video/webm" });
    const formData = new FormData();
    formData.append("video", blob);
    const res = await axios.post(Link + "/admin/users/save_video", formData, {
      withCredentials: true,
    });
    console.log(res.data);
    console.log("Video uploaded successfully");
  }

  const handleCheck =(event)=>{
    setIsAdmin(event.target.checked);
  }

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setGender(e.target.value);
    setUserForm({
      ...userForm,
      [name]: type === "checkbox" ? checked : value,
    });
    if (type === "radio") {
      setEditData({
        ...editData,
        [name]: value,
      });
    } else {
      setEditData({
        ...editData,
        [name]: value,
      });
    }

    // console.log("after change"+userForm)
  };

  const showedit = (id, fname, lname, address, shift, city,email,phone,dateOb,joining_date,designation,gender) => {
   
    setFname(fname);
    setLname(lname);
    setaddress(address);
    setshift(shift);
    setcity(city);
    setemail(email);
    setPhone(phone);
    setDob(dateOb);
    setjoingd(joining_date);
    setEditDesi(designation);
    setGender(gender)
    setSelectedId(id);
    setShowEditForm(!showEditForm);
  };
  console.log(fname, lname, address, shift, city, selectedId,email,phone,joining_date,dob,desig);
  //console.log("sel:", selectedId);
  useEffect(() => {
    if (showFrs) {
      // Access user's webcam
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          if (video.current) {
            video.current.srcObject = stream;

            const recorder = new MediaRecorder(stream);
            recorder.ondataavailable = handleDataAvailable;
            recorder.onstart = () => {
              console.log("Recording started...");
            };
            recorder.onstop = () => {
              console.log("Recording stopped.");
            };

            setMediaRecorder(recorder);
          }
        })
        .catch((error) => {
          console.error("Error accessing webcam:", error);
        });
    } else {
      // Stop the video stream when the pop-out is closed
      if (video.current && video.current.srcObject) {
        const stream = video.current.srcObject;
        const tracks = stream.getTracks();

        tracks.forEach((track) => track.stop());
        video.current.srcObject = null;
        setMediaRecorder(null); // Clear the media recorder
      }
    }
  }, [showFrs]);

  //const [holidayData , setHolidayData] = useState([])
  const showCam = () => {
    setShowFrs(!showFrs);
  };

  const showadd = () => {
    setShowAddForm(!showAddForm);
  };


  const handleDeleteUser = async (ID) => {
    try {
      const res = await axios.get(Link + `/admin/users/activeInactive?id=${ID}`, {
        withCredentials: true,
      });
      //  console.log("user deleted")
      toast.success("User deleted successfully");
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(formData);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if(!formData.first_name || !formData.last_name || !formData.dob || !formData.email || !formData.joining_date || !formData.phone|| !formData.address|| !formData.gender|| !formData.designation_id|| !formData.city || !formData.username || !formData.shift     ){
    //   toast.error('All fields are required');
    // return;
    // }
    // const trimmedCity = userForm.city.trim();
    // const trimmedShift = userForm.shift.trim();
    // if (!/^[A-Za-z]{3,}$/.test(userForm.first_name)) {
    //   toast.error(
    //     "First name should contain only alphabets and have a minimum length of 3 characters."
    //   );
    //   return;
    // }
    // if (!/^[A-Za-z]{3,}$/.test(userForm.last_name)) {
    //   toast.error(
    //     "last name should contain only alphabets and have a minimum length of 3 characters."
    //   );
    //   return;
    // }
    // if (!/^[a-zA-Z0-9]+$/.test(userForm.username)) {
    //   toast.error("Username should contain only alphanumeric characters.");
    //   return;
    // }
    // if (!/\S+@\S+\.\S+/.test(userForm.email)) {
    //   toast.error("Invalid email address.");
    //   return;
    // }

    // if (trimmedCity === "") {
    //   toast.error("City field cannot be empty.");
    //   return;
    // }

    // // Validation for shift
    // if (trimmedShift === "") {
    //   toast.error("Shift field cannot be empty.");
    //   return;
    // }
    // if (!/^\d{10}$/.test(userForm.phone)) {
    //   toast.error("Phone number should contain exactly 10 digits.");
    //   return;
    // }

    try {
      const form = new FormData();
      form.append("fname", userForm.first_name);
      form.append("lname", userForm.last_name);
      form.append("dob", userForm.dob);
      form.append("email", userForm.email);
      form.append("joining_date", userForm.joining_date);
      form.append("phone", userForm.phone);
      form.append("add", userForm.address);
      form.append("gender", userForm.gender);
      form.append("desig", userForm.desig);
      form.append("city", userForm.city);
      form.append("username", userForm.username);
      form.append("shift", userForm.shift);
      console.log("Form data:", Object.fromEntries(form.entries()));

      // const response = await axios.post(
      //   `${Link}/admin/users/create_user/api`,
      //   form,
      //   {
      //     withCredentials: true,
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   }
      // );
      //console.log("User created successfully:", response.data);
      toast.success("User created successfully");
      // Reset form fields
      setUserForm({ 
        first_name: "",
        last_name: "",
        username: "",
        dob: "",
        joining_date: "",
        address: "",
        desig: "",
        shift: "",
        email: "",
        phone: "",
        city: "",
        gender: "",
        avatar: f1,}
      )
      //setShowAddForm(false);
    } catch (error) {
      console.error("Error creating user:", error);
      toast.error("Error creating user");
      setUserForm({ 
        first_name: "",
        last_name: "",
        username: "",
        dob: "",
        joining_date: "",
        address: "",
        desig: "",
        shift: "",
        email: "",
        phone: "",
        city: "",
        gender: "",
        avatar: f1,}
      )
    }
  };
  console.log("Designations:", designations);

  return (
    <>
      <div>
        {/* <Employee projectStatus={projectStatus} /> */}
        <div className={styles.container}>
          <div
            style={{
              display: "flex",
              width: "94%",
              justifyContent: "space-between",
            }}
          >
            {/* Create user form  */}
            <div
              className={showAddForm ? `${styles.modal} ${styles.open}` : styles.modal}
            >
              <div className={styles.modalcontent}>
                <h4>User Details</h4>
                <form>
                  {/* Full name and last name column */}
                  <div className={styles.nameColumn}>
                    <div className={styles.nameContainer}>
                      <h6>First Name</h6>
                      <input
                        type="text"
                        id="first_name"
                      
                        name="first_name"
                        onChange={handleChange}
                        pattern="[A-Za-z]{4,}"
                      />
                    </div>
                    <div className={styles.nameContainer}>
                      <h6>Last Name</h6>
                      <input
                        type="text"
                        name="last_name"
                        
                        id="last_name"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  {/* Username and admin role column */}
                  <div className={styles.nameColumn}>
                    <div className={styles.nameContainer}>
                      <h6>User Name</h6>
                      <input
                        type="text"
                        id="username"
                        
                        name="username"
                        onChange={handleChange}
                      />
                      <input
                        type="hidden"
                        id="avatar"
                       
                        name="avatar"
                      />
                    </div>
                    <div className={styles.checkBoxContainer}>
                      <input type="checkbox" onChange={handleChange} />
                      <h6>Admin Role</h6>
                    </div>
                  </div>

                  {/* Birthday and joining date column */}
                  <div className={styles.nameColumn}>
                    <div className={styles.dateContainer}>
                      <h6>Birthday</h6>
                      <input
                        type="date"
                        id="dob"
                        name="dob"
                        onChange={handleChange}
                      />
                    </div>
                    <div className={styles.dateContainer}>
                      <h6>Joining Date</h6>
                      <input
                        type="date"
                        name="joining_date"
                     
                        id="joining_date"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div>
                    <h6>Address</h6>
                    <textarea
                      id="description"
                      name="address"
                      
                      rows="2"
                      cols="50"
                      onChange={handleChange}
                    ></textarea>
                  </div>

                  {/* Designation and shift column */}
                  <div className={styles.nameColumn}>
                    <div className={styles.nameContainer}>
                      <label className={styles.label1} htmlFor="desig">
                        Designation:
                      </label>
                      <select id="desig" name="desig"  onChange={handleChange} style={{marginTop:"15px"}} >              
                        {designations?.map((designIndex)=>{
                          return( <>   
                          <option key={designIndex} value={designIndex.designation_id} >
                            {designIndex.designation_name}
                          </option>  
                          </>)
                        })}
                      </select>
                     
                    </div>
                    <div className={styles.nameContainer}>
                      <h6>Shift</h6>
                      <input
                        type="text"
                        name="shift"
                        
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  {/* Email and phone column */}
                  <div className={styles.nameColumn}>
                    <div className={styles.nameContainer}>
                      <h6>Email</h6>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        
                        onChange={handleChange}
                      />
                    </div>
                    <div className={styles.nameContainer}>
                      <h6>Phone</h6>
                      <input
                        type="number"
                        name="phone"
                        id="phone"
                    
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  {/* City and gender columns */}
                  <div className={styles.nameColumn}>
                    <div className={styles.nameContainer}>
                      <h6>City</h6>
                      <input
                        id="city"
                     
                        type="text"
                        name="city"
                        onChange={handleChange}
                      />
                    </div>
                    <div className={styles.radioContainer}>
                      <h6>Select Gender</h6>
                      <div className={styles.genderRadioColumn}>
                        <div className={styles.RadioField}>
                          <label>
                            <input
                              type="radio"
                              value="Male"
                              id="male"
                              name="gender"
                              onChange={handleChange}
                            />
                            Male
                          </label>
                        </div>
                        <div className={styles.RadioField}>
                          <label>
                            <input
                              type="radio"
                              value="Female"
                              id="female"
                              name="gender"
                              onChange={handleChange}
                            />
                            Female
                          </label>
                        </div>
                        <div className={styles.RadioField}>
                          <label>
                            <input
                              type="radio"
                              value="Other"
                              id="other"
                              name="gender"
                              onChange={handleChange}
                            />
                            Other
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={styles.editButtonContainer}>
                    <button
                      type="button"
                      style={{
                        backgroundColor: "var(--primary)",
                        color: "white",
                        width: "20%",
                        "margin-right": "20px",
                      }}
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      onClick={
                        //e.preventDefault();

                        showadd
                      } //toggleEditForm();}
                      style={{
                        backgroundColor: "var(--cancel)",
                        color: "white",
                        width: "20%",
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <h2>Employee</h2>
            <button onClick={showadd} className={styles.button}>
              + Add User
            </button>
          </div>

          {/* edit user form  */}
          <div className={showEditForm ? `${styles.modal} ${styles.open}` : styles.modal}>
            <div className={styles.modalcontent}>
              <h4>User Details</h4>
              <form>
                <div className={styles.FrsEdit}>
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();

                      showCam();
                    }}
                  >
                    <FaIcons.FaCameraRetro />
                    Register/Edit the Face Recognition Video
                  </button>
                </div>

                {/* Full name and last name column */}
                <div className={styles.nameColumn}>
                  <div className={styles.nameContainer}>
                    <h6>First Name</h6>
                    <input
                      type="text"
                      name="fname"
                      value={fname}
                      onChange={(e)=>setFname(e.target.value)}
                    />
                  </div>
                  <div className={styles.nameContainer}>
                    <h6>Last Name</h6>
                    <input
                      type="text"
                      name="lname"
                      value={lname}
                      onChange={(e)=>setLname(e.target.value)}
                    />
                  </div>
                </div>

                {/* Username and admin role column */}
                <div className={styles.nameColumn}>
                  <div className={styles.nameContainer}>
                    {/* <h6>User Name</h6>
                    <input
                      type="text"
                      name="username"
                      value={userForm.username}
                      onChange={handleChange}
                    /> */}
                  </div>
                  <div className={styles.checkBoxContainer}>
  <input
    type="checkbox"
    onChange={handleCheck}
    checked={isAdmin} // Assuming admin is a boolean variable representing the admin role
  />
  <h6>Admin Role</h6>
</div>

                </div>

                {/* Birthday and joining date column */}
                <div className={styles.nameColumn}>
                  <div className={styles.dateContainer}>
                    <h6>Birthday</h6>
                    <input
                      type="date"
                      name="dob"
                      value={dob}
                      onChange={(e)=>setDob(e.target.value)}
                    />
                  </div>
                  <div className={styles.dateContainer}>
                    <h6>Joining Date</h6>
                    <input
                      type="date"
                      name="joining_date"
                      value={joining_date}
                      onChange={(e)=>setjoingd(e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  <h6>Address</h6>
                  <textarea
                    id="add"
                    name="add"
                    value={address}
                    rows="2"
                    cols="50"
                    onChange={(e)=>setaddress(e.target.value)}
                    ></textarea>
                </div>

                {/* Designation and shift column */}
                <div className={styles.nameColumn}>
                  <div className={styles.nameContainer}>
                    <h6>Designation</h6>
                    <select id="designation" name="designation" value={editDesgi} onChange={(e)=>setEditDesi(e.target.value)}>
                       
                        {designations?.map((designIndex)=>{
                          return( <>   
                            
                          <option key={designIndex} value={designIndex.designation_id} >
                            {designIndex.designation_name}
                          </option>  
                          </>)
                        })}
                      </select>
                  </div>
                  <div className={styles.nameContainer}>
                    <h6>Shift</h6>
                    <input
                      type="text"
                      value={shift}
                      name="shift"
                      onChange={(e)=>setshift(e.target.value)}
                    />
                  </div>
                </div>

                {/* Email and phone column */}
                <div className={styles.nameColumn}>
                  <div className={styles.nameContainer}>
                    <h6>Email</h6>
                    <input
                      type="email"
                      value={email}
                      name="email"
                      onChange={(e)=>setemail(e.target.value)}
                    />
                  </div>
                  <div className={styles.nameContainer}>
                    <h6>Phone</h6>
                    <input
                      type="number"
                      value={phone}
                      name="phone"
                      onChange={(e)=>setPhone(e.target.value)}
                    />
                  </div>
                </div>

                {/* City and gender columns */}
                <div className={styles.nameColumn}>
                  <div className={styles.nameContainer}>
                    <h6>City</h6>
                    <input
                      type="text"
                      value={city}
                      name="city"
                      onChange={(e)=>setcity(e.target.value)}
                    />
                  </div>
                  <div className={styles.radioContainer}>
                    <h6>Select Gender</h6>
                    <div className={styles.genderRadioColumn}>
                      <div className={styles.RadioField}>
                        <label>
                          <input
                            type="radio"
                            value="Male"
                            name="gender"
                            onChange={handleChange}
                          />
                          Male
                        </label>
                      </div>
                      <div className={styles.RadioField}>
                        <label>
                          <input
                            type="radio"
                            value="Female"
                            name="gender"
                            onChange={handleChange}
                          />
                          Female
                        </label>
                      </div>
                      <div className={styles.RadioField}>
                        <label>
                          <input
                            type="radio"
                            value="Other"
                            name="gender"
                            onChange={handleChange}
                          />
                          Other
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.editButtonContainer}>
                  <button
                    type="button"
                    style={{
                      backgroundColor: "var(--primary)",
                      color: "white",
                      width: "20%",
                      "margin-right": "20px",
                    }}
                    onClick={handleEdit}
                    >
                    Submit
                  </button>
                  <button
                    type="button"
                    onClick={(e) => {
                      //e.preventDefault();

                      showedit(); //toggleEditForm();
                    }}
                    style={{
                      backgroundColor: "var(--cancel)",
                      color: "white",
                      width: "20%",
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>

          {showFrs && (
            <div className={styles.popout}>
              <div className={styles.popoutcontent}>
                <button
                  type="button"
                  id="startButton"
                  onClick={() => {
                    setFrsRecording(true);
                    setFrsRecordingStoped(false);
                    startRec();
                  }}
                  class="btn btn-primary mt-1 mb-1"
                >
                  Start Recording
                </button>
                <button
                  type="button"
                  id="stopButton"
                  onClick={() => {
                    setFrsRecording(false);
                    setFrsRecordingStoped(true);
                    stopRec();
                  }}
                  class="btn btn-danger mt-1 mb-1"
                >
                  Stop Recording
                </button>
                <button
                  type="button"
                  id="sendButton"
                  onClick={() => saveRec()}
                  class="btn btn-secondary mt-1 mb-1"
                >
                  Submit Video
                </button>

                <video
                  ref={video}
                  autoPlay
                  playsInline
                  style={{ width: "100%" }}
                ></video>
                <button type="button" onClick={showCam}>
                  close
                </button>
              </div>
            </div>
          )}
          <div
            class="card mb-2 mt-2 border-light shadow mb-2 mt-2"
            style={{ overflowX: "auto", marginRight: "16px" }}
          >
            <div class="card-body">
              <div className={styles.head}>List</div>

              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Contact Number</th>
                    <th>Designation</th>
                    <th>Joining Date</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {formData?.map((item, index) => (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{item.email}</td>
                      <td>{item.phone}</td>
                      <td>{item.desig}</td>
                      <td>{item.joining_dt}</td>
                      <td>{item.active ? "Active" : "inActive"}</td>
                      <td>
                        <button className={styles.edit}>
                          <FaIcons.FaRegEdit
                            size={20}
                            onClick={() =>
                              showedit(
                                item.id,
                                item.fname,
                                item.lname,
                                item.address,
                                item.shift,
                                item.city,
                                item.email,
                                item.phone,
                                
                                item.dob,
                                item.joining_dt,
                                item.desig,
                                  item.gender
                              )
                            }
                          />
                        </button>
                        <button
                          className={styles.delete}
                          onClick={() => handleDeleteUser(item.id)}
                        >
                          <FaIcons.FaUserSlash />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <ToastContainer />
        </div>
      </div>
    </>
  );
}
