import React, { useState, useEffect } from "react";
import axios from "axios";
import "./ChangePasswordModal.css";
import { useAuth} from "../../Context/context";
import { Link } from "../utils/link";

function ChangePasswordModal({ isOpen, onClose }) {
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    reenterPassword: ""
  });
  const [accData, setAccData] = useState(null); // Initialize as null
  const { isAdmin } = useAuth();
  useEffect(() => {
    async function fetchData() {
      try {
        const url = isAdmin
        ? "/account/json/api"
        : "/account/json";
      const response = await axios.get(Link + url, {
        withCredentials: true,
      });
        setAccData(response.data); // Set state with response data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.newPassword !== formData.reenterPassword) {
      console.error("New passwords do not match");
      return;
    }

    try {
      const response = await axios.post(
        Link + "/account/update_pwd",
        {
          empID: accData.empID,
          old_pwd: formData.currentPassword,
          new_pwd1: formData.newPassword,
          new_pwd2: formData.reenterPassword
        },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json"
          }
        }
      );

      if (response.status === 200) {
        console.log("Password updated successfully");
        onClose(); // Close the modal
      } else {
        console.error("Failed to update password");
      }
    } catch (error) {
      console.error("Error updating password:", error);
    }
  };

  return (
    <div className={isOpen ? "modal open" : "modal"}>
      <div className="modal-content">
        <h4>Change Password</h4>
        <form onSubmit={handleSubmit}>
          <h6>Current Password</h6>
          <input
            type="password"
            name="currentPassword"
            placeholder="Current Password"
            value={formData.currentPassword}
            onChange={handleChange}
          />
          <h6>New Password</h6>
          <input
            type="password"
            name="newPassword"
            placeholder="New Password"
            value={formData.newPassword}
            onChange={handleChange}
          />
          <h6>Re-enter New Password</h6>
          <input
            type="password"
            name="reenterPassword"
            placeholder="Re-enter New Password"
            value={formData.reenterPassword}
            onChange={handleChange}
          />
          <div className="button-container">
            <button
              type="submit"
              style={{ backgroundColor: "var(--primary)", color: "white" }}
            >
              Submit
            </button>
            <button
              onClick={onClose}
              style={{ backgroundColor: "var(--cancel)", color: "white" }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ChangePasswordModal;
