import React, { useEffect, useState } from "react";
import styles from "./Logs.module.css";
import CircleIcon from "@mui/icons-material/Circle";
import axios from "axios";
import { useAuth } from "../../Context/context";
import { Link } from "../utils/link";

function Logs({ id }) {
  console.log("id",id);
  const [logData, setLogData] = useState({ proj_name: "", projects_logs: [] });
const { isAdmin }= useAuth();
  useEffect(() => {
    const getLog = async () => {
      try { 
        const url = isAdmin ? `/admin/projects/details/logs/json?id=${id}`:`/projects/logs/json?id=${id}`;
        const res = await axios.get(
          Link + url,
          {
            withCredentials: true,
          }
        );
        console.log("log:", res.data); 
        setLogData(res.data);
      } catch (error) {
        console.log("failed");
      }
    };
    getLog();
  }, [id]);

  return (
    <div className={styles.logs}>
      <h2>{logData.proj_name}</h2>
      <div className="card bg-dark mb-2 mt-2">
        <div className="card-body text-secondary overflow-auto">
          <h5 className="card-title mb-2 mt-2 text-light fw-medium fs-4 mb-2">
            <CircleIcon className="text-danger" />
            <CircleIcon className="text-warning" />
            <CircleIcon className="text-success" />
          </h5>
          <div className={`card bg-dark mb-2 mt-2 ${styles.gradientbg}`}>
            <div className="card-body text-secondary overflow-auto">
              <ul className="list-unstyled">
                {logData.logs?.map((logItem, index) => (
                  <li key={index} className="d-flex align-items-start mb-2">
                    <span className="text-success">$~</span>
                    <span className="text-success">{logItem["date-time"]}</span>
                    <span className="text-light">{logItem.log}</span>
                     
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Logs;
