import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import styles from "./Adminattendance.module.css";
import { Modal, Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { Link } from "../utils/link";

const Calendarr = ({ id }) => {
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [isEmployeeListOpen, setIsEmployeeListOpen] = useState(false);
  const [attendanceData, setAttendanceData] = useState([]);
  const [totalPresentDays, setTotalPresentDays] = useState(0);
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(moment().month() + 1);
  const [currentYear, setCurrentYear] = useState(moment().year());
  //console.log(selectedMonth)
  // console.log(attendanceData);
  // console.log(id);
  // console.log(selectedMonth);
  // console.log(currentYear);

  useEffect(() => {
    // const interval = setInterval(()=>{
    //   handleEmployeeSelect()
    // },3000)
    handleEmployeeSelect();
  }, [id]);

  const handleEmployeeSelect = async (employee) => {
    setSelectedEmployee(employee);
    setIsEmployeeListOpen(false); // Close the dropdown when an employee is selected
    try {
      const response = await axios.post(
        Link + `/admin/attendance/get_attendance`,
        {
          id: `${id}`,
          month: selectedMonth,
          year: currentYear,
          // Assuming the backend expects an 'employeeId' field
        },
        { withCredentials: true }
      );
      setAttendanceData(response.data.attendance);
      setTotalPresentDays(response.data.total_present_days);
      setLeaveTypes(response.data.report);
      console.log(response.data);
    } catch (error) {
      console.log("Error fetching attendance data:", error);
    }
  };
  const handleDateClick = (arg) => {
    // Extract month from the clicked date

    const calendarMonth = moment(arg.start).month() + 1 + 1;
    const calendarYear = moment(arg.start).year();
    setSelectedMonth(calendarMonth);
    setCurrentYear(calendarYear);
  };

  return (
    <>
    <div className="row">
      <div className="col-sm-8">
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={attendanceData}
        datesSet={handleDateClick}
        selectable={true}
        editable={true}
        height="auto"
      />
      </div>
      <div className="col-sm-4">
      {/* <div className={styles.container1}> */}
        <div className={`${styles.cardsContainer} col`}>
          <div className="card mb-2 mt-1 border-light shadow-sm  bg-white rounded">
            <div className="card-body col">
              <h5 style={{ color: "var(--greenbtn)" }}>Total Presents:</h5>
              <div className="row">
                <div className="col-sm-12 mt-2 mb-2">{totalPresentDays}</div>
              </div>
            </div>
          </div>
          <div className={`${styles.cardsContainer} col`}>
            {leaveTypes.map((leaveType, index) => (
              <div key={index} className="card mb-2 mt-2 border-light shadow-sm">
                <div className="card-body">
                  <h5 style={{ color: leaveType.color }}>
                    {leaveType.Leavetypename}
                    {leaveType.monthly && " (Monthly)"}
                    {leaveType.yearly && " (Yearly)"}
                  </h5>
                  <div className="row">
                    <div className="col-sm-12 mt-2 mb-2">
                      {/* You can add additional content for the leave type here */}
                      <p>
                        {leaveType.limit !== null ? leaveType.limit : 0} /{" "}
                        {leaveType.limitcount}{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default Calendarr;