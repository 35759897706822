import React, { useState, useRef, useEffect } from "react";
import user from "../images/profile.jpg";
import { MdModeEdit } from "react-icons/md";
import styles from "./Account2.module.css";
import ChangePasswordModal from "./ChangePasswordModal";
import ChangeAvatarModal from "./ChangeAvatarModal";
import SetReminderModal from "./SetReminderModal";
import f1 from "../images/f1.jpg";
import f2 from "../images/f2.jpg";
import f3 from "../images/f3.jpg";
import m1 from "../images/m1.jpg";
import m2 from "../images/m2.jpg";
import m3 from "../images/m3.jpg";
import m4 from "../images/m4.jpg";
import m5 from "../images/m5.jpg";
import { getFromAPI } from "../utils/utils";
import axios from "axios";
import { Link } from "../utils/link";
import { useAuth} from "../../Context/context";
const avatarImages = {
  f1: f1,
  f2: f2,
  f3: f3,
  m1: m1,
  m2: m2,
  m3: m3,
  m4: m4,
  m5: m5,
};

function Account2() {
  const [accData, setAccData] = useState(null); // Initialize as null
  const { isAdmin } = useAuth();
  useEffect(() => {
    async function fetchData() {
      try {
        const url = isAdmin
          ? "/account/json/api"
          : "/account/json";
        const response = await axios.get(Link + url, {
          withCredentials: true,
        });
        setAccData(response.data.account_details); // Set state with response data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [showChangeAvatarModal, setShowChangeAvatarModal] = useState(false);
  const sliderRef = useRef(null);
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const avatars = ["f1", "f2", "f3", "m1", "m2", "m3", "m4", "m5"];

  const [showSetReminderModal, setShowSetReminderModal] = useState(false);

  const openSetReminderModal = () => {
    setShowSetReminderModal(true);
  };

  const closeSetReminderModal = () => {
    setShowSetReminderModal(false);
  };
  const openChangeAvatarModal = () => {
    setShowChangeAvatarModal(true);
  };

  const closeChangeAvatarModal = () => {
    setShowChangeAvatarModal(false);
  };

  const openChangePasswordModal = () => {
    setShowChangePasswordModal(true);
  };

  const closeChangePasswordModal = () => {
    setShowChangePasswordModal(false);
  };

  return (
    <>
      <div className={styles.dashboard}>
        <div className={styles.header}>
          <h2>Account</h2>
        </div>
        {accData && (
          <div className={styles.container} key={accData.id}>
            <div className={styles.profile}>
              <img
                src={avatarImages[accData.avatarID]}
                className={styles.image}
                alt="Avatar"
              />
              <div className={styles.details}>
                <div className={styles.header}>
                  {accData.fname} {accData.lname}
                </div>
                <div className={styles.designation}>{accData.desig}</div>
              </div>
            </div>
            <div className={styles.buttons}>
              <button
                className={styles.button}
                onClick={openChangePasswordModal}
              >
                <MdModeEdit />
                Password
              </button>
              <button className={styles.button} onClick={openChangeAvatarModal}>
                <MdModeEdit />
                Avatar
              </button>
              <button className={styles.button} onClick={openSetReminderModal}>
                <MdModeEdit />
                Set Reminder
              </button>
            </div>
            <hr />
            <div className={styles.row}>
              <div className={styles.col}>
                <div className={styles.h6}>First Name</div>
                <div className={styles.value}>{accData.fname}</div>
              </div>
              <div className={styles.col}>
                <div className={styles.h6}>Last Name</div>
                <div className={styles.value}>{accData.lname}</div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>
                <div className={styles.h6}>Email Address</div>
                <div className={styles.value}>{accData.email}</div>
              </div>
              <div className={styles.col}>
                <div className={styles.h6}>Phone Number</div>
                <div className={styles.value}>{accData.phone}</div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>
                <div className={styles.h6}>Joining Date</div>
                <div className={styles.value}>{accData.joining_date}</div>
              </div>
              <div className={styles.col}>
                <div className={styles.h6}>Shift</div>
                <div className={styles.value}>{accData.shift}</div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>
                <div className={styles.h6}>City</div>
                <div className={styles.value}>{accData.city}</div>
              </div>
              <div className={styles.col}>
                <div className={styles.h6}>Gender</div>
                <div className={styles.value}>{accData.gender}</div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>
                <div className={styles.h6}>Address</div>
                <div className={styles.value}>{accData.address}</div>
              </div>
            </div>
          </div>
        )}

        <ChangePasswordModal
          isOpen={showChangePasswordModal}
          onClose={closeChangePasswordModal}
        />
        <ChangeAvatarModal
          isOpen={showChangeAvatarModal}
          onClose={closeChangeAvatarModal}
          avatars={avatars}
          selectedAvatar={selectedAvatar}
          setSelectedAvatar={setSelectedAvatar}
        />
        <SetReminderModal
          isOpen={showSetReminderModal}
          onClose={closeSetReminderModal}
        />
      </div>
    </>
  );
}
export default Account2;
