// Loader.js
import React from 'react';
import styles from './Loader.module.css';

const Loader = () => (
  <div className={styles.container}>
    <div className={styles.loadingWave}>
      <div className={styles.loadingBar}></div>
      <div className={styles.loadingBar}></div>
      <div className={styles.loadingBar}></div>
      <div className={styles.loadingBar}></div>
    </div>
  </div>
);

export default Loader;
